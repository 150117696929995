import { useEffect, useState } from "react";
import {
  ReturnInvoicesDataInterface,
  ReturnInvoicesDetailsDataInterface,
} from "../../../../redux/interfaces/returning.interface";
import ContentCard from "../../../../components/misc/ContentCard";
import { dateToString } from "../../../../utils/dateAndTime";
import FormButtons from "../../../../components/misc/FormButtons";

function SelectProducts({
  handleCurrentStep,
  returnInvoicesDetailsData,
  handleSetSelectedProducts,
}: {
  handleCurrentStep: (callBack: (value: number) => number) => void;
  returnInvoicesDetailsData: ReturnInvoicesDetailsDataInterface | null;
  handleSetSelectedProducts: (products: ReturnInvoicesDataInterface[]) => void;
}) {
  const [selectedProducts, setSelectedProducts] = useState<
    ReturnInvoicesDataInterface[]
  >([]);

  useEffect(() => {
    if (
      returnInvoicesDetailsData?.facturi &&
      returnInvoicesDetailsData?.facturi.length > 0
    ) {
      const formatInvoices =
        returnInvoicesDetailsData?.facturi
          .flatMap((factura) => factura.pozitii)
          .filter((pozitie) => Number(pozitie.cantitateRetur) > 0) || [];

      if (formatInvoices.length > 0) {
        setSelectedProducts(formatInvoices);
      }
    }
  }, [returnInvoicesDetailsData]);

  const handleSelectProduct = (
    product: ReturnInvoicesDataInterface,
    factura: string,
  ) => {
    setSelectedProducts((prev) => {
      const isProductSelected = prev.some(
        (selectedProduct) => selectedProduct.cod_mat === product.cod_mat,
      );

      if (isProductSelected) {
        return prev.filter(
          (selectedProduct) => selectedProduct.cod_mat !== product.cod_mat,
        );
      }

      return [...prev, { ...product, factura }];
    });
  };

  const handleGoBack = () => {
    handleCurrentStep((prev) => prev - 1);
  };

  const handleGoNext = () => {
    handleSetSelectedProducts(selectedProducts);
    handleCurrentStep((prev) => prev + 1);
  };

  return (
    <div>
      {returnInvoicesDetailsData?.facturi.map((factura, index) => (
        <div key={index}>
          <h3 className="mb-4">Factura: {factura.factura}</h3>
          <div className="d-flex flex-wrap">
            {factura.pozitii.map((pozitie, index) => {
              const isSelected = selectedProducts.some(
                (selectedProduct) =>
                  selectedProduct.idPozitiePicking === pozitie.idPozitiePicking,
              );
              return (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    flexBasis: "50%",
                    flexGrow: 1,
                  }}>
                  <ContentCard
                    className={`w-100 border cursor-pointer ${
                      isSelected
                        ? "text-primary border-success"
                        : "border-white"
                    }`}
                    onClick={() =>
                      handleSelectProduct(pozitie, factura.factura)
                    }>
                    <div>
                      <h4>{pozitie.bNume}</h4>
                      <div className="d-flex justify-content-between">
                        <p>Cod: {pozitie.cod_mat}</p>
                        <p>Lot: {pozitie.lot}</p>
                      </div>
                      <div className="d-flex justify-content-between">
                        <p>Data expirare: {dateToString(pozitie.data_exp)}</p>
                        <p>Cantitate: {pozitie.cantitate}</p>
                      </div>
                    </div>
                  </ContentCard>
                </div>
              );
            })}
          </div>
        </div>
      ))}
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Inainte"
        handleGoBack={handleGoBack}
        handleGoNext={handleGoNext}
        nextButtonDisabled={selectedProducts.length === 0}
      />
    </div>
  );
}

export default SelectProducts;
