import React from "react";
import { TableHeaderCategoryConfig } from "../tableContext/TableContext";
import FilterTable from "./FilterTable";
import { TableSearch } from "./TableSearch";

function TableHeaderCategories({
  tableHeaderCategories,
  showActionButtons = true,
  showSearchForCategories = true,
  handleCategoryPress,
}: {
  tableHeaderCategories: TableHeaderCategoryConfig[];
  showActionButtons?: boolean;
  showSearchForCategories?: boolean;
  handleCategoryPress?: (name: string, event?: any) => void;
}) {
  return (
    <>
      {showSearchForCategories && (
        <>
          <tr className="fw-bolder text-muted bg-light">
            {tableHeaderCategories.map(
              (
                {
                  value,
                  minW,
                  maxW,
                  withSearch,
                  withFilter,
                  withoutSearchIcon,
                  withoutSearchClear,
                },
                idx,
                categories,
              ) => {
                return (
                  <th
                    key={value}
                    style={{ minWidth: minW ?? 130, maxWidth: maxW }}
                    className={`${
                      idx === 0
                        ? "ps-3 rounded-start"
                        : idx === categories.length - 1
                        ? "pe-3 rounded-end"
                        : ""
                    }`}>
                    {withSearch && (
                      <TableSearch
                        value={value}
                        withoutSearchIcon={withoutSearchIcon}
                        withoutSearchClear={withoutSearchClear}
                      />
                    )}
                    {withFilter && (
                      <FilterTable
                        value={value}
                        withFilter={withFilter}
                        minW={minW}
                      />
                    )}
                  </th>
                );
              },
            )}
            {showActionButtons && (
              <th className="min-w-150px text-end rounded-end"></th>
            )}
          </tr>
        </>
      )}
      <tr style={{ height: 10 }} />
      <tr className="fw-bolder text-muted bg-light">
        {tableHeaderCategories.map(
          ({ label, value, minW, maxW }, idx, categories) => {
            return (
              <th
                key={value}
                style={{ minWidth: minW ?? 130, maxWidth: maxW }}
                className={`${
                  idx === 0
                    ? "ps-3 rounded-start"
                    : idx === categories.length - 1
                    ? "pe-3 rounded-end"
                    : ""
                }`}
                onClick={
                  handleCategoryPress && (() => handleCategoryPress(value))
                }>
                {label ?? value}
              </th>
            );
          },
        )}
        {showActionButtons && (
          <th className="min-w-150px text-end rounded-end"></th>
        )}
      </tr>
    </>
  );
}

export default TableHeaderCategories;
