import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../components/CustomButton";
import ContentCard from "../../components/misc/ContentCard";
import Dropdown, { DropdownValue } from "../../components/misc/Dropdown";
import TextInput from "../../components/misc/TextInput";
import { getNomenclature, postStartReceipt } from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { removeCreateReception } from "../../redux/reducer/receipt.reducer";
import { receptionRoutes } from "../../router/routesConstants";
import Text from "../../styleguide/Text";

import AddIcon from "@mui/icons-material/Add";

function CreateReception() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    nomenclature: { zone, provider },
    createReception: { isLoadingCreateReception, receptionCreated },
    user: { userId },
  } = useAppSelector((state) => ({
    nomenclature: state.receipt.nomenclature,
    createReception: state.receipt.createReception,
    user: state.user,
  }));

  const [zoneData, setZoneData] = useState<DropdownValue[]>([]);
  const [providerData, setProviderData] = useState<DropdownValue[]>([]);

  const [zoneValue, setZoneValue] = useState<DropdownValue | null>(null);
  const [providersValue, setProvidersValue] = useState<DropdownValue | null>(
    null,
  );

  const [invoices, setInvoices] = useState<DropdownValue[]>([]);
  const [newInvoice, setNewInvoice] = useState<string | null | number>("");

  const addInvoice = () => {
    if (newInvoice) {
      setInvoices((prev) => [
        ...prev,
        { id: uuidv4().toString(), label: newInvoice },
      ]);
      setNewInvoice("");
    }
  };

  useEffect(() => {
    dispatch(getNomenclature());
  }, [dispatch]);

  useEffect(() => {
    if (receptionCreated) {
      setZoneValue(null);
      setProvidersValue(null);
      dispatch(removeCreateReception());
      navigate(`/${receptionRoutes.reception}`);
    }
  }, [navigate, dispatch, receptionCreated]);

  useEffect(() => {
    if (zone && zone.length > 0) {
      const formatZone = zone.map((item) => ({
        id: item.idZonaReceptie,
        label: item.denumireZona,
      }));
      setZoneData(formatZone);
    }
    if (provider && provider.length > 0) {
      const formatProviders = provider.map((item) => ({
        id: item.idFurnizor,
        label: item.numeFurnizor,
      }));
      setProviderData(formatProviders);
    }
  }, [zone, provider]);

  const handleConfirmReception = () => {
    if (userId && zoneValue?.id && providersValue?.id) {
      const startReceipt = {
        userId,
        idFurnizor: providersValue.id,
        idZonaReceptie: zoneValue.id,
        invoices,
      };
      dispatch(postStartReceipt(startReceipt));
    }
  };

  const isDisabled =
    isLoadingCreateReception ||
    !zoneValue?.id ||
    !providersValue?.id ||
    invoices.length === 0;

  return (
    <ContentCard CardHeader={() => <></>} cardTitle="Creeaza receptie">
      <Box display={"flex"}>
        <Dropdown
          className="mb-4 me-2"
          title="Selecteaza zona"
          data={zoneData}
          singleValue={zoneValue}
          setSingleValue={setZoneValue}
        />
        <Dropdown
          className="mb-4 ms-2"
          title="Selecteaza furnizor"
          data={providerData}
          singleValue={providersValue}
          setSingleValue={setProvidersValue}
        />
      </Box>
      <Dropdown
        className="mb-4"
        title="Facturi adaugate"
        data={invoices}
        multipleValues={invoices}
        setMultipleValues={setInvoices}
      />
      <TextInput
        className="mb-4"
        label="Adauga factura"
        name="AddInvoice"
        value={newInvoice}
        onChange={setNewInvoice}
        endComponent={
          newInvoice && (
            <CustomButton
              variant="contained"
              color="primary"
              onClick={addInvoice}>
              <AddIcon color="inherit" />
            </CustomButton>
          )
        }
      />
      <CustomButton
        variant="contained"
        loading={isLoadingCreateReception}
        disabled={isDisabled}
        withConfirmationModal={{
          modalTitle: "Confirmati creearea receptiei?",
          modalRightButtonOnClick: handleConfirmReception,
          modalLeftButtonVariant: "outlined",
        }}>
        <Text>Creeaza receptie</Text>
      </CustomButton>
    </ContentCard>
  );
}

export default CreateReception;
