import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import authReducer from "../reducer/auth.reducer";
import appReducer from "../reducer/app.reducer";
import userReducer from "../reducer/user.reducer";
import receiptReducer from "../reducer/receipt.reducer";
import generalReducer from "../reducer/general.reducer";
import globalComponentsReducer from "../reducer/globalComponents.reducer";
import inventoryReducer from "../reducer/inventory.reducer";
import putawayReducer from "../reducer/putaway.reducer";
import pickingReducer from "../reducer/picking.reducer";
import raportsReducer from "../reducer/raports.reducer";
import expeditionReducer from "../reducer/expedition.reducer";
import adminReducer from "../reducer/admin.reducer";
import returningReducer from "../reducer/returning.reducer";

const isDebuggerMode = process.env.NODE_ENV !== "production";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    appConfig: appReducer,
    user: userReducer,
    general: generalReducer,
    globalComponents: globalComponentsReducer,
    receipt: receiptReducer,
    inventory: inventoryReducer,
    putaway: putawayReducer,
    picking: pickingReducer,
    raports: raportsReducer,
    expedition: expeditionReducer,
    admin: adminReducer,
    returning: returningReducer,
  },
  devTools: isDebuggerMode,
  middleware: (getDefaultMiddleware) => {
    if (isDebuggerMode) {
      return getDefaultMiddleware().concat(logger);
    }
    return getDefaultMiddleware();
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
