import { useEffect, useState } from "react";
import useQueryParams from "../../hooks/useQueryParams";
import useTableCtx from "./tableContext/useTableCtx";

function useTableRouteParams() {
  const { urlParams, handleSetQuerryParams } = useQueryParams();

  const { handleTableRouteParams, tablePagination, tableFilter, tableSearch } =
    useTableCtx()!;

  const [urlParamsReady, setUrlParamsReady] = useState(false);

  useEffect(() => {
    if (tablePagination) {
      // setSearchParams with previous url params

      handleSetQuerryParams({
        ...urlParams,
        ...tableSearch,
        ...tableFilter,
        page: tablePagination.page.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleSetQuerryParams, tablePagination, tableFilter]);

  useEffect(() => {
    if (!urlParamsReady && urlParams) {
      handleTableRouteParams(urlParams);
      setUrlParamsReady(true);
      return;
    }
  }, [urlParams, urlParamsReady, handleTableRouteParams]);

  return;
}

export default useTableRouteParams;
