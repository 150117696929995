import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getPutawayTasks = createAsyncThunk(
  "/putaway/showPutawayTasksWeb",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      productName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, status, productName } = params;
      const { data: responseData } = await apiRequest(
        `/showTasksWebPutAway/?page=${page}&per_page=${perPage}&status=${status}&searchName=${productName}`,
        "GET",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/putaway/showPutawayTasksWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getRefillTasks = createAsyncThunk(
  "/putaway/showTasksWebRefill",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      productName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, status, productName } = params;
      const { data: responseData } = await apiRequest(
        `/showTasksWebRefill/?page=${page}&per_page=${perPage}&status=${status}&searchName=${productName}`,
        "GET",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/putaway/showTasksWebRefill", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getRearrangementTasks = createAsyncThunk(
  "/putaway/showTasksWebRearrangement",
  async (
    params: {
      page: number;
      perPage: number;
      status: string;
      productName: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, status, productName } = params;
      const { data: responseData } = await apiRequest(
        `/showTasksWebRearrangement/?page=${page}&per_page=${perPage}&status=${
          status ?? ""
        }&searchName=${productName ?? ""}`,
        "GET",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/putaway/showTasksWebRearrangement", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
