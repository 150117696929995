import { useEffect, useState, useRef } from "react";
import ContentCard from "../../../components/misc/ContentCard";
import TextInput from "../../../components/misc/TextInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import CustomButton from "../../../components/CustomButton";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce } from "../../../hooks/useDebounce";
import Text from "../../../styleguide/Text";
import SvgIcon from "../../../helpers/SvgIcon";
import { getListaCutiiComanda } from "../../../redux/api/picking.api";
import withScanCommandBoxCode from "./components/withScanCommandBoxCode";

function ScanCommandPackage({
  handleShowBoxCodeModal,
}: {
  handleShowBoxCodeModal?: (cellScannedValue?: string | number | null) => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const {
    picking: {
      commandPackageList: { data: commandPackageListData },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const { id: commandId } = useParams();

  const inputRef = useRef<HTMLInputElement>(null);

  const [scannedCode, setScannedCode] = useState<string | number | null>(null);

  const [packagesScanned, setPackagesScanned] = useState<string[]>([]);

  const debouncedScannedValue = useDebounce(scannedCode, 300);

  useEffect(() => {
    if (commandId) {
      dispatch(getListaCutiiComanda(commandId));
    }
  }, [dispatch, commandId]);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (!!debouncedScannedValue && !!scannedCode) {
        setScannedCode(null);
      }
    }, 300);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedScannedValue, scannedCode]);

  useEffect(() => {
    if (debouncedScannedValue) {
      const valueFormatted = debouncedScannedValue.toString().split("*")[0];
      const valueUpperCase = valueFormatted.toUpperCase().trim();
      setPackagesScanned((prev) => {
        if (prev.includes(valueUpperCase)) {
          return prev;
        }
        return [...prev, valueUpperCase];
      });
      setScannedCode("");
    }
  }, [debouncedScannedValue]);

  const onChangeScanCode = (value: string | number | null) => {
    setScannedCode(value);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleScanPress = () => {
    handleShowBoxCodeModal && handleShowBoxCodeModal(commandId);
  };

  const handleRemovePackage = (
    packageToRemove: string,
    packageIndex: number,
  ) => {
    setPackagesScanned((prev) =>
      prev.filter(
        (packageScanned, index) =>
          packageScanned !== packageToRemove && packageIndex !== index,
      ),
    );
    inputRef.current?.focus();
  };

  const disableScanButton =
    !commandId ||
    packagesScanned.length === 0 ||
    packagesScanned.length !== commandPackageListData.length ||
    !packagesScanned.every((packageScanned) =>
      commandPackageListData.includes(packageScanned),
    );

  return (
    <ContentCard>
      <TextInput
        label="Scaneaza colet pentru comanda"
        id="scannInput"
        name="scannInput"
        value={scannedCode}
        onChange={onChangeScanCode}
        autoFocus
        externalInputRef={inputRef}
      />

      {!!commandId && (
        <div>
          <Text variant="h1" className="my-7 text-center">
            Cod comanda {commandId}
          </Text>
        </div>
      )}
      <div className="d-flex">
        <div className="w-100">
          {packagesScanned.map((packageScanned, index) => {
            const isPackageScanned =
              commandPackageListData.includes(packageScanned);
            return (
              <ContentCard
                key={`${index}-${packageScanned}`}
                cardBodyClassName={`d-flex justify-content-between align-items-center text-${
                  isPackageScanned ? "success" : "danger"
                }`}
                cardBodyStyle={{ minHeight: 50 }}>
                {packageScanned}
                <CustomButton
                  variant="contained"
                  size="small"
                  color="error"
                  onClick={() => handleRemovePackage(packageScanned, index)}>
                  <SvgIcon type="DELETE" />
                </CustomButton>
              </ContentCard>
            );
          })}
        </div>
        <div className="w-100">
          {commandPackageListData.map((packageScanned, index) => (
            <ContentCard
              key={`${index}-${packageScanned}`}
              cardBodyClassName="d-flex justify-content-between align-items-center"
              cardBodyStyle={{ minHeight: 50 }}>
              {packageScanned}
            </ContentCard>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-between">
        <CustomButton variant="outlined" onClick={handleGoBack}>
          Anuleaza
        </CustomButton>
        <div>
          <CustomButton
            variant="contained"
            className="me-3"
            disabled={disableScanButton}
            onClick={handleScanPress}>
            Scanati
          </CustomButton>
        </div>
      </div>
    </ContentCard>
  );
}

export default withScanCommandBoxCode(ScanCommandPackage);
