import { useMemo } from "react";
import { useAppSelector } from "../redux/hooks";
import {
  adminRoutes,
  expeditionRoutes,
  inventoryRoutes,
  pickingRoutes,
  putawayRoutes,
  receptionRoutes,
} from "./routesConstants";

import userRoles from "../constants/userRoles";

interface DashboardCategories {
  color: string;
  title: string;
  subtitle?: string;
  subtitleValue?: string;
  categories: {
    id: string;
    title: string;
    path: string;
    subtitle?: string;
    value?: string | number;
    isIncreasing?: boolean;
  }[];
  navigationLinks?: {
    link: string;
    label: string;
  }[];
}

function useAppMenuRoutes() {
  const {
    user: { userRoutesAccess },
  } = useAppSelector((state) => ({
    user: state.user,
  }));

  const receptionMenuRoutes = [
    {
      id: userRoles.RECEPTION,
      title: "Centralizator Receptii",
      path: `/${receptionRoutes.reception}?stadiu=2`,
    },
    {
      id: userRoles.TRANSFER_RECEIVED,
      title: "Transfer marfa receptionata in depozit",
      path: `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}`,
    },
    {
      id: userRoles.MONITOR_QUARANTINE,
      title: "Produse aflate in carantina",
      path: `/${receptionRoutes.reception}/${receptionRoutes.quarantineMonitor}`,
    },
    {
      id: userRoles.TRANSFER_TASKS,
      title: "Monitorizare task-uri de transfer marfa",
      path: `/${receptionRoutes.reception}/${receptionRoutes.transferTasks}`,
    },
    {
      id: userRoles.RETURNS,
      title: "Retururi",
      path: `/${receptionRoutes.returns}`,
    },
    {
      id: userRoles.RAPORTS,
      title: "Rapoarte",
      path: `/${receptionRoutes.reception}/${receptionRoutes.raports}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const putawayMenuRoutes = [
    {
      id: userRoles.PUTAWAY_TASKS,
      title: "Task-uri putaway",
      path: `/${putawayRoutes.putaway}`,
    },
    {
      id: userRoles.PUTAWAY_REFILL_TASKS,
      title: "Task-uri umplere marfa",
      path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRefill}`,
    },
    {
      id: userRoles.PUTAWAY_REARRANGEMENT_TASKS,
      title: "Task-uri rearanjare marfa",
      path: `/${putawayRoutes.putaway}/${putawayRoutes.putawayRearrangement}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const pickingMenuRoutes = [
    {
      id: userRoles.DELIVERY_ARRANGEMENT,
      title: "Dispozitie livrare",
      path: `/${pickingRoutes.picking}/${pickingRoutes.deliveryArrangement}`,
    },
    {
      id: userRoles.VERIFY_COMMAND,
      title: "Verificare comanda",
      path: `/${pickingRoutes.picking}/${pickingRoutes.verifyCommand}`,
    },
    {
      id: userRoles.PRINT_COMMAND_INVOICES,
      title: "Printeaza comanda",
      path: `/${pickingRoutes.picking}/${pickingRoutes.printCommandInvoice}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const expeditionMenuRoutes = [
    {
      id: userRoles.SCHEDULE_WORKING_POINTS,
      title: "Program puncte de lucru",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.scheduleWorkingPoints}`,
    },
    {
      id: userRoles.SCHEDULE_WORKING_ROUTE,
      title: "Program rute de transport",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.scheduleWorkingRoute}`,
    },
    {
      id: userRoles.NODES,
      title: "Noduri",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.nodes}`,
    },
    {
      id: userRoles.INCOMPLETE_INVOICES,
      title: "Facturi fara destinatar",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.incompleteInvoices}`,
    },
    {
      id: userRoles.SCAN_PLASTIC_BOXES,
      title: "Scanare cutii plastic",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.scanPlasticBoxes}`,
    },
    {
      id: userRoles.MONITORING_PLASTIC_BOXES,
      title: "Monitorizare cutii plastic",
      path: `/${expeditionRoutes.expedition}/${expeditionRoutes.monitoringPlasticBoxes}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const stocksMenuRoutes = [
    {
      id: userRoles.INVENTORY_RECEIVED_PRODUCTS,
      title: "Intrari produse",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.receivedProducts}`,
    },
    {
      id: userRoles.STOCKS,
      title: "Stocuri",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.depositStocks}`,
    },
    {
      id: userRoles.NOMENCLATURE,
      title: "Nomenclator",
      path: `/${inventoryRoutes.inventory}/${inventoryRoutes.nomenclature}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const adminMenuRoutes = [
    {
      id: userRoles.PREVIEW_USERS,
      title: "Utilizatori",
      path: `/${adminRoutes.users}`,
    },
    {
      id: userRoles.CREATE_USER,
      title: "Creeaza utilizator",
      path: `/${adminRoutes.users}/${adminRoutes.createUser}`,
    },
  ].filter((route) => userRoutesAccess?.includes(route.id));

  const categories: DashboardCategories[] = useMemo(
    () =>
      [
        {
          color: "primary",
          title: "Receptie",
          categories: receptionMenuRoutes,
        },
        {
          color: "primary",
          title: "Putaway",
          categories: putawayMenuRoutes,
        },
        {
          color: "primary",
          title: "Picking",
          categories: pickingMenuRoutes,
        },
        {
          color: "primary",
          title: "Expeditie",
          categories: expeditionMenuRoutes,
        },
        {
          color: "primary",
          title: "Stocuri",
          categories: stocksMenuRoutes,
        },
        {
          color: "primary",
          title: "Administrare",
          categories: adminMenuRoutes,
        },
      ].filter((category) => category.categories.length > 0),
    [
      receptionMenuRoutes,
      putawayMenuRoutes,
      pickingMenuRoutes,
      expeditionMenuRoutes,
      stocksMenuRoutes,
      adminMenuRoutes,
    ],
  );

  return categories;
}

export default useAppMenuRoutes;
