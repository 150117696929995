import { memo } from "react";
import { KTSVG } from "../../../../../helpers/KTSVG";

import { TableItemId } from "../../../../../components/tables/TableWidget";
import CustomButton from "../../../../../components/CustomButton";

import { TableItemType } from "../../../../../components/tables/tableContext/TableContext";
import { expeditionRoutes } from "../../../../../router/routesConstants";

function ExpeditionScheduleWorkingPointsTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const goToOrganizeWorkingPoint = () => {
    return `/${expeditionRoutes.expedition}/${expeditionRoutes.workingPointsOrganize}?id=${itemId}&routeId=${crudData.rutaId}`;
  };

  return (
    <td className="text-start" style={{ width: 250 }}>
      <CustomButton
        variant="contained"
        tooltipTitle="Organizare"
        className="mx-1 mb-1"
        navigate={goToOrganizeWorkingPoint()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-1"
          fill="white"
        />
      </CustomButton>
    </td>
  );
}

export default memo(ExpeditionScheduleWorkingPointsTableActionButtons);
