import { createSlice } from "@reduxjs/toolkit";
import {
  getDepositStocks,
  getReceivedProducts,
  getSelectProductWms,
  postGetProducts,
} from "../api/inventory.api";
import {
  InventoryDepositStocksDataInterface,
  InventoryProductsDataInterface,
  InventoryReceivedProductDataInterface,
  SelectProductWmsInterface,
} from "../interfaces/inventory.interface";

const initialInventoryState: {
  receivedProducts: InventoryReceivedProductDataInterface;
  depositStocks: InventoryDepositStocksDataInterface;
  products: InventoryProductsDataInterface;
  selectProductWms: SelectProductWmsInterface;
} = {
  receivedProducts: {
    data: {
      nrPagini: 1,
      nrTotalProduse: 1,
      produse: [],
    },
    isLoadingReceivedProducts: false,
    isErrorReceivedProducts: false,
    errorMessageReceivedProducts: "",
  },
  depositStocks: {
    data: {
      nrPagini: 1,
      nrTotalStoc: 1,
      stocuri: [],
    },
    isLoadingDepositStocks: false,
    isErrorDepositStocks: false,
    errorMessageDepositStocks: "",
  },
  products: {
    data: {
      nrTotal: 1,
      produseWms: [],
    },
    isLoadingProducts: false,
    isErrorProducts: false,
    errorMessageProducts: "",
  },
  selectProductWms: {
    data: null,
    isLoadingSelectProductWms: false,
    isErrorSelecProductWms: false,
    errorMessageSelectProductWms: "",
  },
};

const inventoryReducer = createSlice({
  name: "inventory",
  initialState: initialInventoryState,
  reducers: {
    clearDepositStocks: (state) => {
      return {
        ...state,
        depositStocks: {
          ...initialInventoryState.depositStocks,
        },
      };
    },
    clearSelectProductWms: (state) => {
      return {
        ...state,
        selectProductWms: {
          ...initialInventoryState.selectProductWms,
        },
      };
    },
    setProductsBlockedStatus: (state, action) => {
      const newProducts = state.products.data.produseWms.map((product) => {
        if (product.idProdus === action.payload) {
          return {
            ...product,
            blocat: product.blocat ? 0 : 1,
          };
        }
        return product;
      });

      return {
        ...state,
        products: {
          ...state.products,
          data: {
            ...state.products.data,
            produseWms: newProducts,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    // start  received products
    builder.addCase(getReceivedProducts.pending, (state) => {
      return {
        ...state,
        receivedProducts: {
          ...initialInventoryState.receivedProducts,
          isLoadingReceivedProducts: true,
        },
      };
    });
    builder.addCase(getReceivedProducts.rejected, (state, action: any) => {
      return {
        ...state,
        receivedProducts: {
          ...state.receivedProducts,
          isLoadingReceivedProducts: false,
          errorMessageReceivedProducts: action.payload.message,
          isErrorReceivedProducts: true,
        },
      };
    });
    builder.addCase(getReceivedProducts.fulfilled, (state, action) => {
      return {
        ...state,
        receivedProducts: {
          ...state.receivedProducts,
          isLoadingReceivedProducts: false,
          data: action.payload,
        },
      };
    });
    // end received products
    // start deposit stocks
    builder.addCase(getDepositStocks.pending, (state) => {
      return {
        ...state,
        depositStocks: {
          ...initialInventoryState.depositStocks,
          isLoadingDepositStocks: true,
        },
      };
    });
    builder.addCase(getDepositStocks.rejected, (state, action: any) => {
      return {
        ...state,
        depositStocks: {
          ...state.depositStocks,
          isLoadingDepositStocks: false,
          errorMessageDepositStocks: action.payload.message,
          isErrorDepositStocks: true,
        },
      };
    });
    builder.addCase(getDepositStocks.fulfilled, (state, action) => {
      return {
        ...state,
        depositStocks: {
          ...state.depositStocks,
          isLoadingDepositStocks: false,
          data: action.payload,
        },
      };
    });
    // end deposit stocks
    // start get products
    builder.addCase(postGetProducts.pending, (state) => {
      return {
        ...state,
        products: {
          ...initialInventoryState.products,
          isLoadingProducts: true,
        },
      };
    });
    builder.addCase(postGetProducts.rejected, (state, action: any) => {
      return {
        ...state,
        products: {
          ...state.products,
          isLoadingProducts: false,
          errorMessageProducts: action.payload.message,
          isErrorProducts: true,
        },
      };
    });
    builder.addCase(postGetProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...state.products,
          isLoadingProducts: false,
          data: action.payload,
        },
      };
    });
    // end get products
    // start select product wms
    builder.addCase(getSelectProductWms.pending, (state) => {
      return {
        ...state,
        selectProductWms: {
          ...initialInventoryState.selectProductWms,
          isLoadingSelectProductWms: true,
        },
      };
    });
    builder.addCase(getSelectProductWms.rejected, (state, action: any) => {
      return {
        ...state,
        selectProductWms: {
          ...state.selectProductWms,
          isLoadingSelectProductWms: false,
          errorMessageSelectProductWms: action.payload.message,
          isErrorSelecProductWms: true,
        },
      };
    });
    builder.addCase(getSelectProductWms.fulfilled, (state, action) => {
      return {
        ...state,
        selectProductWms: {
          ...state.selectProductWms,
          isLoadingSelectProductWms: false,
          data: action.payload,
        },
      };
    });
    // end select product wms
  },
});

export const {
  clearDepositStocks,
  clearSelectProductWms,
  setProductsBlockedStatus,
} = inventoryReducer.actions;

export default inventoryReducer.reducer;
