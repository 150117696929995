import { useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import Text from "../../../../styleguide/Text";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";

function MonitorQuarantineActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
}) {
  const navigate = useNavigate();

  const editProduct = () => {
    navigate(
      `/${receptionRoutes.reception}/${receptionRoutes.quarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}&page=1`,
    );
  };

  return (
    <td className="text-end">
      <Button
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        onClick={editProduct}
        style={{
          width: "150px",
        }}>
        <Text variant="body2" className="indicator-label" isBold>
          Vezi Carantina
        </Text>
      </Button>
    </td>
  );
}

export default MonitorQuarantineActionButtons;
