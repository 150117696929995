import { createSlice } from "@reduxjs/toolkit";
import {
  getPutawayTasks,
  getRearrangementTasks,
  getRefillTasks,
} from "../api/putaway.api";
import {
  PutawayRearrangementTasksInterface,
  PutawayRefillTasksInterface,
  PutawayTasksInterface,
} from "../interfaces/putaway.interface";

export const defaultStatePutaway: {
  globalErrorMessage: string;
  putawayTasks: PutawayTasksInterface;
  putawayRefillTasks: PutawayRefillTasksInterface;
  putawayRearrangementTasks: PutawayRearrangementTasksInterface;
} = {
  globalErrorMessage: "",
  putawayTasks: {
    data: {
      nrTotalTaskuriPutAway: 1,
      nrPagini: 1,
      taskuriPutAway: [],
    },
    isLoadingPutawayTasks: true,
    isErrorPutawayTasks: false,
    errorMessagePutawayTasks: "",
  },
  putawayRefillTasks: {
    data: {
      list: [],
      total: 1,
    },
    isLoadingPutawayRefillTasks: true,
    isErrorPutawayRefillTasks: false,
    errorMessagePutawayRefillTasks: "",
  },
  putawayRearrangementTasks: {
    data: {
      list: [],
      total: 1,
    },
    isLoadingPutawayRearrangementTasks: true,
    isErrorPutawayRearrangementTasks: false,
    errorMessagePutawayRearrangementTasks: "",
  },
};

const putaway = createSlice({
  name: "putaway",
  initialState: defaultStatePutaway,
  reducers: {},
  extraReducers: (builder) => {
    // putaway  tasks start
    builder.addCase(getPutawayTasks.pending, (state) => {
      return {
        ...state,
        putawayTasks: {
          ...defaultStatePutaway.putawayTasks,
          isLoadingPutawayTasks: true,
        },
      };
    });
    builder.addCase(getPutawayTasks.rejected, (state, action: any) => {
      return {
        ...state,
        putawayTasks: {
          ...state.putawayTasks,
          isLoadingPutawayTasks: false,
          errorMessagePutawayTasks: action.payload.message,
          isErrorPutawayTasks: true,
        },
      };
    });
    builder.addCase(getPutawayTasks.fulfilled, (state, action) => {
      return {
        ...state,
        putawayTasks: {
          ...state.putawayTasks,
          isLoadingPutawayTasks: false,
          data: action.payload,
        },
      };
    });
    // putaway  tasks end

    // putaway refill tasks start
    builder.addCase(getRefillTasks.pending, (state) => {
      return {
        ...state,
        putawayRefillTasks: {
          ...defaultStatePutaway.putawayRefillTasks,
          isLoadingPutawayRefillTasks: true,
        },
      };
    });
    builder.addCase(getRefillTasks.rejected, (state, action: any) => {
      return {
        ...state,
        putawayRefillTasks: {
          ...state.putawayRefillTasks,
          isLoadingPutawayRefillTasks: false,
          errorMessagePutawayRefillTasks: action.payload.message,
          isErrorPutawayRefillTasks: true,
        },
      };
    });
    builder.addCase(getRefillTasks.fulfilled, (state, action) => {
      return {
        ...state,
        putawayRefillTasks: {
          ...state.putawayRefillTasks,
          isLoadingPutawayRefillTasks: false,
          data: action.payload,
        },
      };
    });
    // putaway refill tasks end

    // putaway rearrangement tasks start
    builder.addCase(getRearrangementTasks.pending, (state) => {
      return {
        ...state,
        putawayRearrangementTasks: {
          ...defaultStatePutaway.putawayRearrangementTasks,
          isLoadingPutawayRearrangementTasks: true,
        },
      };
    });
    builder.addCase(getRearrangementTasks.rejected, (state, action: any) => {
      return {
        ...state,
        putawayRearrangementTasks: {
          ...state.putawayRearrangementTasks,
          isLoadingPutawayRearrangementTasks: false,
          errorMessagePutawayRearrangementTasks: action.payload.message,
          isErrorPutawayRearrangementTasks: true,
        },
      };
    });
    builder.addCase(getRearrangementTasks.fulfilled, (state, action) => {
      return {
        ...state,
        putawayRearrangementTasks: {
          ...state.putawayRearrangementTasks,
          isLoadingPutawayRearrangementTasks: false,
          data: action.payload,
        },
      };
    });
    // putaway rearrangement tasks end
  },
});

export default putaway.reducer;
