import { LoadingButton } from "@mui/lab";
import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ContentCard from "../../components/misc/ContentCard";
import DatePicker from "../../components/misc/DatePicker";
import ModalList, {
  ModalListItem,
} from "../../components/misc/ModalList/ModalList";
import TextInput from "../../components/misc/TextInput";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import { fetchProducts } from "../../redux/api/general.api";
import {
  getNomenclature,
  getProductDetailsById,
  postProductUpdate,
} from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ProductsDataInterface } from "../../redux/interfaces/general.interface";
import { clearProductDetails } from "../../redux/reducer/receipt.reducer";
import Text from "../../styleguide/Text";
import { dateForDatabase } from "../../utils/dateAndTime";

const defaultDate = new Date(
  new Date().setFullYear(new Date().getFullYear() + 1),
);
interface initialValuesInterface {
  serialNumber: string | null;

  boxWidth: number | null;
  boxLength: number | null;
  boxHeight: number | null;
  boxWeigth: number | null;
  boxQuantity: number | null;

  packWidth: number | null;
  packLength: number | null;
  packHeight: number | null;
  packWeigth: number | null;
  packQuantity: number | null;

  invalidPackQuantity: number | null;
  cantitateDeteriorata?: number | null;

  expirationDate: Date;
  lot: string | null;

  [key: string]: any;
}

const initialValues: initialValuesInterface = {
  serialNumber: null,

  boxWidth: null,
  boxLength: null,
  boxHeight: null,
  boxWeigth: null,
  boxQuantity: null,

  packWidth: null,
  packLength: null,
  packHeight: null,
  packWeigth: null,
  packQuantity: null,

  invalidPackQuantity: null,
  cantitateDeteriorata: null,

  // comp
  expirationDate: defaultDate,
  lot: null,
};

const productSchema = Yup.object({
  packQuantity: Yup.number()
    .nullable()
    .required("Cantitatea trebuie specificata")
    .min(1, "Doar numere pozitive mai mari decat 0"),

  expirationDate: Yup.date()
    .nullable()
    .transform((curr, orig) => (orig === "" ? null : curr))
    .required("Mandatory field message"),
  lot: Yup.string().nullable().required("Inaltimea trebuie specificata"),
});

function EditProduct() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeParams } = useRoutesHelper();
  const productId = routeParams?.idProdus;

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [oldProductData, setOldProductData] =
    useState<initialValuesInterface>(initialValues);
  const [product, setProduct] = useState<ProductsDataInterface | null>(null);

  const {
    receipt: {
      productDetails: { data: productDetailsData, isLoadingProduct },
    },
    general: { products },

    user: { userId },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
    user: state.user,
    general: state.general,
  }));

  const selectedProductFormat: ModalListItem | undefined = useMemo(() => {
    if (product) {
      return {
        id: product.idProdusErp,
        label: `${product.numeProdus} - ${product.codProdus}`,
      };
    }
  }, [product]);

  const initialProductsList: ModalListItem[] = useMemo(
    () =>
      products.productsList.map(
        ({ numeProdus, codProdus, idProdusErp, isPaired }) => {
          return {
            label: `${numeProdus} - ${codProdus}`, // required
            id: idProdusErp, // required
            backgroundColor: isPaired ? "warning" : "info",
          };
        },
      ),
    [products.productsList],
  );

  useEffect(() => {
    if (productId) {
      dispatch(getNomenclature());
      dispatch(getProductDetailsById(productId));
    }
  }, [dispatch, productId]);

  useEffect(() => {
    return () => {
      dispatch(clearProductDetails());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productDetailsData) {
      const getExpirationDateObject = () => {
        if (productDetailsData?.expirationDate) {
          return new Date(productDetailsData.expirationDate);
        } else {
          return defaultDate;
        }
      };

      if (productDetailsData?.id && productDetailsData?.name) {
        setProduct({
          idProdusErp: productDetailsData?.id,
          numeProdus: productDetailsData?.name,
          codProdus: productDetailsData?.code,
        });
      }
      setFormikValues((prev) => ({
        ...prev,
        boxQuantity: productDetailsData?.boxQuantity ?? prev.boxQuantity,
        packQuantity: productDetailsData?.packQuantity ?? prev.packQuantity,
        serialNumber: productDetailsData?.serialNumber ?? prev.serialNumber,
        lot: productDetailsData?.lot ?? prev.lot,
        cantitateDeteriorata:
          productDetailsData?.cantitateDeteriorata ?? prev.cantitateDeteriorata,
        expirationDate: getExpirationDateObject(),
      }));
      setOldProductData(productDetailsData);
    }
  }, [productDetailsData]);

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    const handleSuccess = () => {
      navigate(-1);
    };

    const response = {
      newProductData: {
        ...oldProductData,
        ...product,
        ...value,
        expirationDate: dateForDatabase(value.expirationDate),
        userId,
        productId,
      },
      oldProductData,
    };

    dispatch(
      postProductUpdate({
        productData: response,
        callBack: handleSuccess,
      }),
    );
  };

  const modalActions = ({ search }: { search: string }) => {
    dispatch(fetchProducts({ search: search }));
  };

  const handleProductSelected = (value: any) => {
    const productDetails = products.productsList.find(
      (item) => item.idProdusErp === value?.id,
    );
    if (productDetails) {
      setProduct(productDetails);
    }
  };

  return (
    <ContentCard
      cardTitle="Editeaza detalii produs"
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}
      isLoading={isLoadingProduct}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className="form w-100"
            onSubmit={handleSubmit}
            noValidate
            id="kt_login_signin_form">
            <ModalList
              label="Nume produs"
              items={initialProductsList}
              modalActions={modalActions}
              selectedItem={selectedProductFormat}
              setSelectedItem={handleProductSelected}
              searchPlaceholder={"Cauta produs"}
              modalTitle={"Cauta produs"}
            />
            <TextInput
              name="serialNumber"
              label="Serial Number"
              error={errors["serialNumber"]}
              touched={touched["serialNumber"]}
              inputProps={getFieldProps("serialNumber")}
            />
            <TextInput
              name="lot"
              label="Lot"
              error={errors["lot"]}
              touched={touched["lot"]}
              inputProps={getFieldProps("lot")}
            />
            <DatePicker
              label="Data expirare"
              isRequired
              withVerticalSpacer
              value={values["expirationDate"]}
              disabled={isSubmitting}
              handleChangeDate={(value) =>
                setFieldValue("expirationDate", value)
              }
            />
            <TextInput
              name="cantitateDeteriorata"
              label="Cantitate deteriorata"
              error={errors["cantitateDeteriorata"]}
              touched={touched["cantitateDeteriorata"]}
              inputProps={getFieldProps("cantitateDeteriorata")}
              type="number"
            />
            <TextInput
              name="packQuantity"
              label="Cantitate"
              error={errors["packQuantity"]}
              touched={touched["packQuantity"]}
              inputProps={getFieldProps("packQuantity")}
              type="number"
            />
            {values["boxQuantity"] && (
              <TextInput
                name="boxQuantity"
                label="Cantitate cutii"
                error={errors["boxQuantity"]}
                touched={touched["boxQuantity"]}
                inputProps={getFieldProps("boxQuantity")}
              />
            )}
            <div className="text-center">
              <LoadingButton
                type="submit"
                fullWidth
                className="btn btn-lg btn-primary w-100 mb-5"
                disabled={!isValid || isSubmitting || !product}>
                <Text variant="body1" className="indicator-label" isBold>
                  Salveaza
                </Text>
              </LoadingButton>
            </div>
          </form>
        )}
      </Formik>
    </ContentCard>
  );
}

export default EditProduct;
