import TextInput from "../../../components/misc/TextInput";
import ModalWrapper from "../../../components/Modal/ModalWrapper";

function QuarantineSolutionsModal({
  crudData,
  showModal,
  toggleModal,
}: {
  crudData: any;
  showModal: boolean;
  toggleModal: (showModal?: boolean) => void;
}) {
  return (
    <ModalWrapper
      title={"Solutionari"}
      show={showModal}
      handleClose={() => toggleModal()}>
      {crudData.solutionari.map(
        (
          {
            motiv,
            solutionare,
            cantitate,
          }: { motiv: string; solutionare: string; cantitate: string },
          idx: number,
        ) => {
          return (
            <div key={`${motiv}-${motiv}-${idx}`}>
              <TextInput
                disabled
                label={`${motiv} (${cantitate} produse)`}
                name={`${motiv}+${solutionare}+${idx}`}
                value={solutionare}
                type="textarea"
              />
            </div>
          );
        },
      )}
    </ModalWrapper>
  );
}

export default QuarantineSolutionsModal;
