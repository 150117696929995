import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";
import { ReturnInvoicesDataInterface } from "../interfaces/returning.interface";

export const getReturningTasks = createAsyncThunk(
  "/returning/getReturningTasks",
  async (
    params: {
      page: number;
      perPage: number;
      status?: string;
      deliveryPoint?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage, status, deliveryPoint } = params;
      const { data: responseData } = await apiRequest(
        `/veziReceptieRetur/?page=${page}&per_page=${perPage}&status=${status}&deliveryPoint=${deliveryPoint}`,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/returning/getReturningTasks", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReturnInvoices = createAsyncThunk(
  "/returning/getReturnInvoices",
  async (invoice: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data: responseData } = await apiRequest(
        `/cautaFacturiRetur/${invoice}`,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/returning/getReturnInvoices", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreateReturnTask = createAsyncThunk(
  "/returning/postCreateReturnTask",
  async (
    transferData: {
      invoices: {
        pctDeLivr: string;
        act: string;
        fiscal_id: number;
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data: responseData } = await apiRequest(
        "/creazaReceptieRetur",
        "POST",
        transferData,
      );
      return responseData;
    } catch (err: any) {
      console.warn("/returning/postCreateReturnTask", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReturnInvoicesDetails = createAsyncThunk(
  "/returning/getReturnInvoicesDetails",
  async (returnId: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/detaliiFacturi/${returnId}`);
      return data;
    } catch (err: any) {
      console.warn("/returning/getReturnInvoicesDetails", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postCreatePdfRetur = createAsyncThunk(
  "/returning/postCreatePdfRetur",
  async (
    transferData: {
      returnToDelivery: boolean;
      packageLeftTheWerehouse: boolean;
      reason: {
        name: string;
        label: string;
        value?: string;
      };
      selectedProducts: ReturnInvoicesDataInterface[];
      returnId: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/pdfRetur", "POST", transferData);
      return data;
    } catch (err: any) {
      console.warn("/returning/postCreatePdfRetur", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getReturnDifferences = createAsyncThunk(
  "/returning/getReturnDifferences",
  async (idReceptieRetur: string | number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(
        `/verificaDiferenteRetur/${idReceptieRetur}`,
      );
      return data;
    } catch (err: any) {
      console.warn("/returning/getReturnDifferences", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
