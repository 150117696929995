import { dateToString } from "../../../../utils/dateAndTime";

export const previewQuarantineTableHeader = [
  {
    value: "numeProdus",
    label: "Nume produs",
    minW: 150,
    withSearch: true,
  },
  {
    value: "codProdusDepo",
    label: "Cod produs",
    minW: 125,
    withSearch: true,
  },
  {
    value: "lotWms",
    label: "Lot",
    minW: 160,
    withSearch: true,
  },
  {
    value: "Data expirare",
    minW: 160,
  },
  {
    value: "Cantitate",
    minW: 150,
  },
  {
    value: "facturi",
    label: "Facturi",
    minW: 125,
    withSearch: true,
  },
  {
    value: "neconformitati",
    label: "",
    minW: 125,
  },
];

const getNonconformingBadgeColor = (item: {
  idType: number;
  type: string;
  cantitate: number;
  status: number;
  reasons: any[];
}) => {
  // sent to quarantine
  if (item.status === 2) {
    return "light-success";
  }

  // not completed
  if (item.status === 0) {
    return "light-danger";
  }

  return "light-info";
};

export const getPreviewQuarantineTableData: any = (quarantineList: any) =>
  quarantineList?.map((quarantineItem: any) => {
    return {
      id: quarantineItem.idCarantina,
      crudData: quarantineItem,
      data: [
        {
          title: quarantineItem.numeProdus,
        },
        {
          title: quarantineItem.codProdusDepo,
        },
        {
          title: [
            {
              text: `Faptic: ${quarantineItem.lotWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${quarantineItem.lotDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${dateToString(quarantineItem.dexpWms)}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${dateToString(quarantineItem.dexpDepo)}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${quarantineItem.cantWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${quarantineItem.cantDepo ?? "-"}`,
              textClassName: "text-muted",
            },
            {
              text: `Nesolutionate: ${
                Math.abs(quarantineItem.diferenta) -
                Math.abs(quarantineItem.cantitateSolutionata)
              }`,
              textClassName: "text-danger",
            },
          ],
        },
        {
          badges: quarantineItem.facturi?.map((factura: any) => ({
            badgeText: factura.codFactura,
            badgeColor: "light-info",
            style: { minWidth: "125px" },
          })),
        },
        {
          badges: quarantineItem.neconformitati?.map((item: any) => ({
            badgeText: item.type,
            badgeColor: getNonconformingBadgeColor(item),
            style: { minWidth: "125px" },
          })),
        },
      ],
    };
  });
