import { TableHeaderCategoryConfig } from "../../../../../components/tables/tableContext/TableContext";
import { ScheduleWorkingRoutesListInterface } from "../../../../../redux/interfaces/expedition.interface";

export const expeditionScheduleWorkingRoutesTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      value: "Id ruta",
      minW: 125,
    },
    {
      value: "Denumire",
      minW: 125,
    },
    {
      value: "Cod",
      minW: 125,
    },
    {
      value: "Status",
      minW: 125,
    },
  ];

export const getExpeditionScheduleWorkingRoutesTableData: any = (
  data: ScheduleWorkingRoutesListInterface[],
) =>
  data?.map((item: any) => {
    return {
      id: item.rutaId,
      crudData: item,
      data: [
        {
          title: item.rutaId,
        },
        {
          title: item.denumire,
        },
        {
          title: item.cod,
        },
        {
          badges: [
            {
              badgeText: item.validat ? "Validat" : "Nevalidat",
              badgeColor: item.validat ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
