import { createSlice } from "@reduxjs/toolkit";
import {
  getDeliveryArrangement,
  getDeliveryInvoices,
  getListaCutiiComanda,
  getPackingBoxes,
  getPrintAWBPackingBoxes,
  getPrintCommandInvoice,
  getVerifyCommandProducts,
  postValidateAWBVerification,
  postValidateVerification,
} from "../api/picking.api";
import {
  VerifyCommandProductsInterface,
  PackingBoxesInterface,
  PrintCommandInvoiceInterface,
  CommandPackageListInterface,
  DeliveryArrangementInterface,
  ValidateVerificationInterface,
  PrintAwbPackingBoxesInterface,
  ValidateAwbVerificationInterface,
  DeliveryInvoicesInterface,
  VerifyCommandProductsListInterface,
  PackingBoxesListInterface,
} from "../interfaces/picking.interface";

const initiaPickingState: {
  verifyCommand: {
    verificationZone: string;
    step: number;
  };
  verifyCommandProducts: VerifyCommandProductsInterface;
  packingBoxes: PackingBoxesInterface;
  printAwbPackingBoxes: PrintAwbPackingBoxesInterface;
  printCommandInvoice: PrintCommandInvoiceInterface;
  commandPackageList: CommandPackageListInterface;
  deliveryArrangement: DeliveryArrangementInterface;
  validateVerification: ValidateVerificationInterface;
  validateAwbVerification: ValidateAwbVerificationInterface;
  deliveryInvoices: DeliveryInvoicesInterface;
} = {
  verifyCommand: {
    verificationZone: "",
    step: 0,
  },
  verifyCommandProducts: {
    data: {
      status: null,
      cumparator: null,
      pctDeLivr: null,
      userName: null,
      list: [],
      facturi: [],
      ruta: null,
    },
    isLoadingVerifyCommandProducts: false,
    isErrorVerifyCommandProducts: false,
    errorMessageVerifyCommandProducts: "",
  },
  packingBoxes: {
    data: [],
    isLoadingPackingBoxes: true,
    isErrorPackingBoxes: false,
    errorMessagePackingBoxes: "",
  },
  printAwbPackingBoxes: {
    data: [],
    isLoadingPrintAwbPackingBoxes: true,
    isErrorPrintAwbPackingBoxes: false,
    errorMessagePrintAwbPackingBoxes: "",
  },
  printCommandInvoice: {
    isLoadingPrintCommandInvoice: false,
    isErrorPrintCommandInvoice: false,
    errorMessagePrintCommandInvoice: "",
  },
  commandPackageList: {
    data: [],
    isLoadingCommandPackageList: false,
    isErrorCommandPackageList: false,
    errorMessageCommandPackageList: "",
  },
  deliveryArrangement: {
    data: {
      nrPagini: 1,
      nrTotal: 1,
      orderPicking: [],
    },
    isLoadingDeliveryArrangement: false,
    isErrorDeliveryArrangement: false,
    errorMessageDeliveryArrangement: "",
  },
  validateVerification: {
    isSuccessValidateVerification: false,
    isLoadingValidateVerification: false,
    isErrorValidateVerification: false,
  },
  validateAwbVerification: {
    isSuccessValidateAwbVerification: false,
    isLoadingValidateAwbVerification: false,
    isErrorValidateAwbVerification: false,
  },
  deliveryInvoices: {
    data: [],
    isLoadingDeliveryInvoices: false,
    isErrorDeliveryInvoices: false,
    errorMessageDeliveryInvoices: "",
  },
};

const pickingReducer = createSlice({
  name: "picking",
  initialState: initiaPickingState,
  reducers: {
    setVerifyCommand(
      state,
      action: {
        payload: {
          verificationZone?: string;
          step?: number;
        };
        type: string;
      },
    ) {
      return {
        ...state,
        verifyCommand: {
          ...state.verifyCommand,
          ...action.payload,
        },
      };
    },
    setPackingBoxes(
      state,
      action: { payload: PackingBoxesListInterface[]; type: string },
    ) {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          data: action.payload,
        },
      };
    },
    resetVerifyCommandProducts: (state) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...initiaPickingState.verifyCommandProducts,
        },
      };
    },
    resetPrintAwbPackingBoxes: (state) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...initiaPickingState.printAwbPackingBoxes,
        },
      };
    },
    setVerifyCommandProductsList: (state, action) => {
      const newList: VerifyCommandProductsListInterface[] = action.payload;
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          data: {
            ...state.verifyCommandProducts.data,
            list: newList,
          },
        },
      };
    },
  },
  extraReducers: (builder) => {
    // get  verify command products start
    builder.addCase(getVerifyCommandProducts.pending, (state) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...initiaPickingState.verifyCommandProducts,
          isLoadingVerifyCommandProducts: true,
        },
      };
    });
    builder.addCase(getVerifyCommandProducts.rejected, (state, action: any) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          isLoadingVerifyCommandProducts: false,
          errorMessageVerifyCommandProducts: action.payload.message,
          isErrorVerifyCommandProducts: true,
        },
      };
    });
    builder.addCase(getVerifyCommandProducts.fulfilled, (state, action) => {
      return {
        ...state,
        verifyCommandProducts: {
          ...state.verifyCommandProducts,
          isLoadingVerifyCommandProducts: false,
          data: action.payload,
        },
      };
    });
    //  get verify command products end
    // get packing boxes start
    builder.addCase(getPackingBoxes.pending, (state) => {
      return {
        ...state,
        packingBoxes: {
          ...initiaPickingState.packingBoxes,
          isLoadingPackingBoxes: true,
        },
      };
    });
    builder.addCase(getPackingBoxes.rejected, (state, action: any) => {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          isLoadingPackingBoxes: false,
          errorMessagePackingBoxes: action.payload.message,
          isErrorPackingBoxes: true,
        },
      };
    });
    builder.addCase(getPackingBoxes.fulfilled, (state, action) => {
      return {
        ...state,
        packingBoxes: {
          ...state.packingBoxes,
          isLoadingPackingBoxes: false,
          data: action.payload,
        },
      };
    });
    // get packing boxes end
    // get verify awb packing boxes start
    builder.addCase(getPrintAWBPackingBoxes.pending, (state) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...initiaPickingState.printAwbPackingBoxes,
          isLoadingPrintAwbPackingBoxes: true,
        },
      };
    });
    builder.addCase(getPrintAWBPackingBoxes.rejected, (state, action: any) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...state.printAwbPackingBoxes,
          isLoadingPrintAwbPackingBoxes: false,
          errorMessagePrintAwbPackingBoxes: action.payload.message,
          isErrorPrintAwbPackingBoxes: true,
        },
      };
    });
    builder.addCase(getPrintAWBPackingBoxes.fulfilled, (state, action) => {
      return {
        ...state,
        printAwbPackingBoxes: {
          ...state.printAwbPackingBoxes,
          isLoadingPrintAwbPackingBoxes: false,
          data: action.payload,
        },
      };
    });
    // get verify awb packing boxes end
    // get print command start
    builder.addCase(getPrintCommandInvoice.pending, (state) => {
      return {
        ...state,
        printCommandInvoice: {
          ...initiaPickingState.printCommandInvoice,
          isLoadingPrintCommandInvoice: true,
        },
      };
    });
    builder.addCase(getPrintCommandInvoice.rejected, (state, action: any) => {
      return {
        ...state,
        printCommandInvoice: {
          ...state.printCommandInvoice,
          isLoadingPrintCommandInvoice: false,
          errorMessagePrintCommandInvoice: action.payload.message,
          isErrorPrintCommandInvoice: true,
        },
      };
    });
    builder.addCase(getPrintCommandInvoice.fulfilled, (state) => {
      return {
        ...state,
        printCommandInvoice: {
          ...state.printCommandInvoice,
          isLoadingPrintCommandInvoice: false,
        },
      };
    });
    // get print command end
    // get command package list start
    builder.addCase(getListaCutiiComanda.pending, (state) => {
      return {
        ...state,
        commandPackageList: {
          ...initiaPickingState.commandPackageList,
          isLoadingCommandPackageList: true,
        },
      };
    });
    builder.addCase(getListaCutiiComanda.rejected, (state, action: any) => {
      return {
        ...state,
        commandPackageList: {
          ...state.commandPackageList,
          isLoadingCommandPackageList: false,
          errorMessageCommandPackageList: action.payload.message,
          isErrorCommandPackageList: true,
        },
      };
    });
    builder.addCase(getListaCutiiComanda.fulfilled, (state, action) => {
      return {
        ...state,
        commandPackageList: {
          ...state.commandPackageList,
          isLoadingCommandPackageList: false,
          data: action.payload,
        },
      };
    });
    // get command package list end
    // get delivery arrangement start
    builder.addCase(getDeliveryArrangement.pending, (state) => {
      return {
        ...state,
        deliveryArrangement: {
          ...initiaPickingState.deliveryArrangement,
          isLoadingDeliveryArrangement: true,
        },
      };
    });
    builder.addCase(getDeliveryArrangement.rejected, (state, action: any) => {
      return {
        ...state,
        deliveryArrangement: {
          ...state.deliveryArrangement,
          isLoadingDeliveryArrangement: false,
          errorMessageDeliveryArrangement: action.payload.message,
          isErrorDeliveryArrangement: true,
        },
      };
    });
    builder.addCase(getDeliveryArrangement.fulfilled, (state, action) => {
      return {
        ...state,
        deliveryArrangement: {
          ...state.deliveryArrangement,
          isLoadingDeliveryArrangement: false,
          data: action.payload,
        },
      };
    });
    // get delivery arrangement end

    // get post validate verification start
    builder.addCase(postValidateVerification.pending, (state) => {
      return {
        ...state,
        validateVerification: {
          isLoadingValidateVerification: true,
          isSuccessValidateVerification: false,
          isErrorValidateVerification: false,
        },
      };
    });
    builder.addCase(postValidateVerification.rejected, (state) => {
      return {
        ...state,
        validateVerification: {
          isLoadingValidateVerification: false,
          isSuccessValidateVerification: false,
          isErrorValidateVerification: true,
        },
      };
    });
    builder.addCase(postValidateVerification.fulfilled, (state) => {
      return {
        ...state,
        validateVerification: {
          isErrorValidateVerification: false,
          isLoadingValidateVerification: false,
          isSuccessValidateVerification: true,
        },
      };
    });
    // get post validate verification end
    // postValidateAWBVerification start
    builder.addCase(postValidateAWBVerification.pending, (state) => {
      return {
        ...state,
        validateAwbVerification: {
          isLoadingValidateAwbVerification: true,
          isSuccessValidateAwbVerification: false,
          isErrorValidateAwbVerification: false,
        },
      };
    });
    builder.addCase(postValidateAWBVerification.rejected, (state) => {
      return {
        ...state,
        validateAwbVerification: {
          isLoadingValidateAwbVerification: false,
          isSuccessValidateAwbVerification: false,
          isErrorValidateAwbVerification: true,
        },
      };
    });
    builder.addCase(postValidateAWBVerification.fulfilled, (state) => {
      return {
        ...state,
        validateAwbVerification: {
          isErrorValidateAwbVerification: false,
          isLoadingValidateAwbVerification: false,
          isSuccessValidateAwbVerification: true,
        },
      };
    });

    // postValidateAWBVerification end

    // getDeliveryInvoices start
    builder.addCase(getDeliveryInvoices.pending, (state) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isLoadingDeliveryInvoices: true,
        },
      };
    });
    builder.addCase(getDeliveryInvoices.rejected, (state, action: any) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isErrorDeliveryInvoices: true,
          errorMessageDeliveryInvoices: action.payload.message,
        },
      };
    });
    builder.addCase(getDeliveryInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        deliveryInvoices: {
          ...initiaPickingState.deliveryInvoices,
          isLoadingDeliveryInvoices: false,
          data: action.payload,
        },
      };
    });
    // getDeliveryInvoices end
  },
});

export const {
  resetVerifyCommandProducts,
  resetPrintAwbPackingBoxes,
  setVerifyCommandProductsList,
  setVerifyCommand,
  setPackingBoxes,
} = pickingReducer.actions;

export default pickingReducer.reducer;
