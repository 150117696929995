import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { dateToString, getDateAndTime } from "../../../../utils/dateAndTime";

const putawayTasksStatuses = [
  { value: 0, label: "In asteptare" },
  { value: 1, label: "Transfer depozit" },
  { value: 2, label: "Activ" },
  { value: 3, label: "Splituit" },
  { value: 4, label: "Asignat" },
  { value: 5, label: "Finalizat" },
  { value: 10, label: "Toate" },
];

const getPutawayTasksStatusLabel = (status: number) => {
  const getStatus = putawayTasksStatuses.find((item) => item.value === status);

  return getStatus ? getStatus.label : "";
};

export const putawayTasksTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id task",
    minW: 125,
  },
  {
    value: "Furnizor",
    minW: 125,
  },
  {
    label: "Nume produs",
    value: "productName",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Cod produs",
    minW: 125,
  },
  {
    value: "Lot",
    minW: 125,
  },
  {
    value: "Data expirare",
    minW: 125,
  },
  {
    value: "Cantitate",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    withFilter: putawayTasksStatuses,
    minW: 125,
  },
  {
    value: "Nume utilizator",
    minW: 125,
  },
  {
    value: "Ora aparitiei",
    minW: 125,
  },
  {
    value: "Ora preluarii",
    minW: 125,
  },
];

export const getPutawayTasksTableData: any = (data: any) =>
  data?.map((item: any) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          title: item.id,
        },
        {
          title: item.numeFurnizor,
        },
        {
          title: item.numeProdusErp,
        },
        {
          title: item.codProdusWMS,
        },
        {
          title: item.lotWMS,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(item.dexpWMS ?? "-")}`,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: item.cantitate,
        },
        {
          badges: [
            {
              badgeText: getPutawayTasksStatusLabel(item.status),
              badgeColor: "light-primary",
            },
          ],
        },
        {
          title: item.userName,
        },
        {
          badges: [
            {
              badgeText: `${getDateAndTime(item.addedAt ?? "-")}`,
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: `${getDateAndTime(item.startedAt ?? "-")}`,
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
