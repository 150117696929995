import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import CustomButton from "../../components/CustomButton";
import ContentCard from "../../components/misc/ContentCard";
import Dropdown, { DropdownValue } from "../../components/misc/Dropdown";
import TextInput from "../../components/misc/TextInput";
import {
  getReceptionInvoices,
  postUpdateReceptionInvoices,
} from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import Text from "../../styleguide/Text";

import AddIcon from "@mui/icons-material/Add";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import { EditReceptionInvoicesDataInterface } from "../../redux/interfaces/receipt.interface";

function EditReception() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const {
    editReceptionInvoices: { isLoadingEditReceptionInvoices, data },
  } = useAppSelector((state) => ({
    editReceptionInvoices: state.receipt.editReceptionInvoices,
  }));

  const { routeParams }: any = useRoutesHelper();
  const fluxId = routeParams?.idFlux;

  const [receptionData, setReceptionData] =
    useState<EditReceptionInvoicesDataInterface | null>(null);

  const [invoices, setInvoices] = useState<DropdownValue[]>([]);
  const [newInvoice, setNewInvoice] = useState<string | null | number>("");

  const addInvoice = () => {
    if (newInvoice) {
      setInvoices((prev) => [
        ...prev,
        { id: uuidv4().toString(), label: newInvoice },
      ]);
      setNewInvoice("");
    }
  };

  useEffect(() => {
    if (fluxId) {
      dispatch(getReceptionInvoices({ idFluxReceptie: fluxId }));
    }
  }, [dispatch, fluxId]);

  useEffect(() => {
    if (data) {
      setReceptionData(data);
      setInvoices(data.facturi);
    }
  }, [data]);

  const handleConfirmReception = () => {
    const successCallback = () => {
      navigate("/receptie?stadiu=1");
    };
    if (receptionData) {
      const receptionTransferData = {
        idStatusReceptie: receptionData.idStatusReceptie,
        invoices,
        callBack: successCallback,
      };
      dispatch(postUpdateReceptionInvoices(receptionTransferData));
    }
  };

  const isDisabled = invoices.length === 0;

  return (
    <ContentCard CardHeader={() => <></>} cardTitle="Editeaza receptia">
      <Box display={"flex"}>
        <TextInput
          className="mb-4 me-2"
          name="selectZone"
          label="Selecteaza zona"
          disabled
          value={receptionData?.denumireZona}
        />
        <TextInput
          className="mb-4 me-2"
          name="selectProvider"
          label="Selecteaza furnizor"
          disabled
          value={receptionData?.numeFurnizor}
        />
      </Box>
      <Dropdown
        className="mb-4"
        title="Facturi adaugate"
        data={invoices}
        multipleValues={invoices}
        setMultipleValues={setInvoices}
      />
      <TextInput
        className="mb-4"
        label="Adauga factura"
        name="AddInvoice"
        value={newInvoice}
        onChange={setNewInvoice}
        endComponent={
          newInvoice && (
            <CustomButton
              variant="contained"
              color="primary"
              onClick={addInvoice}>
              <AddIcon color="inherit" />
            </CustomButton>
          )
        }
      />
      <CustomButton
        variant="contained"
        loading={isLoadingEditReceptionInvoices}
        disabled={isDisabled}
        onClick={handleConfirmReception}>
        <Text>Editeaza receptie</Text>
      </CustomButton>
    </ContentCard>
  );
}

export default EditReception;
