import React from "react";
import ContentCard from "../../../components/misc/ContentCard";
import { TableWidget } from "../../../components/tables/TableWidget";
import { getScheduleWorkingPoints } from "../../../redux/api/expedition.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import PickingScheduleWorkingPointsTableActionButtons from "./components/TableActionsButtonsComponents/ExpeditionScheduleWorkingPointsTableActionButtons";
import {
  getExpeditionScheduleWorkingPointsTableData,
  expeditionScheduleWorkingPointsTableHeader,
} from "./components/tableConfigs/expeditionScheduleWorkingPointsTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

function ExpeditionScheduleWorkingPoints() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      scheduleWorkingPoints: {
        data: scheduleWorkingPointsData,
        isLoadingScheduleWorkingPoints,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getScheduleWorkingPoints({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          deliveryPoint: action.tableSearch?.deliveryPoint ?? "",
          route: action.tableSearch?.route ?? "",
        }),
      );
    }
  };

  return (
    <ContentCard>
      <TableWidget
        tableTitle={"Puncte de lucru"}
        tableHeaderData={expeditionScheduleWorkingPointsTableHeader}
        tableItemsData={getExpeditionScheduleWorkingPointsTableData(
          scheduleWorkingPointsData?.workingPoints,
        )}
        handleTableActions={handleTableActions}
        totalItems={scheduleWorkingPointsData.nrTotal}
        tableLoading={isLoadingScheduleWorkingPoints}
        ActionButtonsComponent={PickingScheduleWorkingPointsTableActionButtons}
        borderedRow
      />
    </ContentCard>
  );
}

export default ExpeditionScheduleWorkingPoints;
