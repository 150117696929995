import React, { useCallback } from "react";
import { useAppDispatch } from "../../../../../../redux/hooks";
import { VerifyCommandProductsListInterface } from "../../../../../../redux/interfaces/picking.interface";
import { setVerifyCommandProductsList } from "../../../../../../redux/reducer/picking.reducer";

import { QrCodeObjectInterface } from "../../../../../../helpers/qrCodeFormater";

import ProductCardComponent from "./ProductCardComponent";

function VerifyCommandTable({
  verifyCommandProductsList,
  verificationZone,
  qrCodeParams,
  handleFocusProductScannedInput,
}: {
  verifyCommandProductsList: VerifyCommandProductsListInterface[];
  verificationZone: string;
  qrCodeParams: QrCodeObjectInterface | null;
  handleFocusProductScannedInput: () => void;
}) {
  const dispatch = useAppDispatch();

  const handleValidateProduct = useCallback(
    (
      product: VerifyCommandProductsListInterface,
      withScanProductAutoFocus?: boolean,
    ) => {
      const newCommandProducts = verifyCommandProductsList.map(
        (commandProduct) => {
          if (commandProduct.idPozitiePicking === product.idPozitiePicking) {
            return {
              ...commandProduct,
              isValidate: !commandProduct.isValidate,
            };
          }
          return commandProduct;
        },
      );
      dispatch(setVerifyCommandProductsList(newCommandProducts));
      if (withScanProductAutoFocus) {
        handleFocusProductScannedInput();
      }
    },
    [dispatch, handleFocusProductScannedInput, verifyCommandProductsList],
  );

  return (
    <table className="table align-middle gs-0 gy-4 table-row-gray-100">
      <thead>
        <tr className="fw-bolder text-muted bg-light">
          <th className="ps-2 rounded-start">Valid</th>
          <th>Nume</th>
          <th>Cantitate</th>
          <th>Lot</th>
          <th>Data expirare</th>
          <th>Bax</th>
          <th>Celula</th>
          <th className="rounded-end"></th>
        </tr>
      </thead>
      <tbody>
        {verifyCommandProductsList?.map((product) => (
          <ProductCardComponent
            key={product.idPozitiePicking}
            commandCode={verificationZone}
            product={product}
            qrCodeParams={qrCodeParams}
            handleValidateProduct={handleValidateProduct}
          />
        ))}
      </tbody>
    </table>
  );
}

export default VerifyCommandTable;
