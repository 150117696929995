import { TableHeaderCategoryConfig } from "../../../../../components/tables/tableContext/TableContext";
import { ScheduleWorkingPointsListInterface } from "../../../../../redux/interfaces/expedition.interface";

export const expeditionScheduleWorkingPointsTableHeader: TableHeaderCategoryConfig[] =
  [
    {
      value: "Id task",
      minW: 125,
    },
    {
      label: "Ruta",
      value: "route",
      minW: 125,
      withSearch: true,
    },
    {
      value: "Adresa",
      minW: 125,
    },
    {
      value: "deliveryPoint",
      label: "Punct de livrare",
      withSearch: true,
      minW: 125,
    },
    {
      value: "Status",
      minW: 125,
    },
  ];

export const getExpeditionScheduleWorkingPointsTableData: any = (
  data: ScheduleWorkingPointsListInterface[],
) =>
  data?.map((item: any) => {
    return {
      id: item.plId,
      crudData: item,
      data: [
        {
          title: item.plId,
        },
        {
          title: item.ruta,
        },
        {
          title: `${item.judetPl} ${item.localitate}`,
          subtitle: item.adresa,
        },
        {
          title: item.pctDeLivr,
        },
        {
          badges: [
            {
              badgeText: item.validat ? "Validat" : "Nevalidat",
              badgeColor: item.validat ? "light-success" : "light-danger",
            },
          ],
        },
      ],
    };
  });
