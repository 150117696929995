import Text from "../../../../styleguide/Text";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";

import QuarantineSolutionResolveModalContent from "./QuarantineSolutionResolveModalContent";

function QuarantineSolutionResolveModal({
  crudData,
  showModal,
  toggleModal,
}: {
  crudData: any;
  showModal: boolean;
  toggleModal: (showModal?: boolean) => void;
}) {
  return (
    <ModalWrapper
      title={"Carantinare"}
      show={showModal}
      handleClose={() => toggleModal()}
      headerComponent={() => <ProblemsHeaderComponent crudData={crudData} />}>
      <QuarantineSolutionResolveModalContent crudData={crudData} />
    </ModalWrapper>
  );
}

const ProblemsHeaderComponent = ({ crudData }: any) => (
  <div>
    <Text variant="h2" component="h2" className="mb-6">
      Probleme gasite
    </Text>
    {crudData.neconformitati?.map((item: any, idx: number) => {
      return (
        <Text
          key={`problem-${idx}`}
          component="span"
          variant="body2"
          gutterBottom
          className={`badge badge-light-danger me-2`}
          style={{ minWidth: "125px" }}>
          {item.type}
        </Text>
      );
    })}
  </div>
);

export default QuarantineSolutionResolveModal;
