import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import { Box, Skeleton } from "@mui/material";
import Text from "../../styleguide/Text";
import { KTSVG } from "../../helpers/KTSVG";

function not(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: string[], b: string[]) {
  return a.filter((value) => b.indexOf(value) !== -1);
}

const CustomList = ({
  title,
  items,
  listCrudItems,
  listTitleParser,
  isLoading,
  checked,
  handleToggle,
  disabled,
}: {
  title: React.ReactNode;
  items: string[];
  listCrudItems?: any[] | null;
  listTitleParser?: (
    listItem: string,
    crudListItem?: any[],
  ) => string | JSX.Element | JSX.Element[] | React.ReactNode;
  checked: string[];
  isLoading?: boolean;
  handleToggle: (value: string) => void;
  disabled?: boolean;
}) => {
  const [searchList, setSearchList] = useState("");

  const searchedList = items.filter((item) => {
    return item.toLowerCase().includes(searchList.toLowerCase());
  });

  return (
    <Card>
      <CardHeader
        className="fw-bolder bg-light text-black w-100 p-4 text-center"
        sx={{ px: 2, py: 1 }}
        title={title}
      />
      <SearchComponent searchList={searchList} setSearchList={setSearchList} />

      {/* <Divider /> */}
      {isLoading ? (
        <Skeleton variant="rectangular" height={300} />
      ) : (
        <ItemsList
          items={searchedList}
          listCrudItems={listCrudItems}
          checked={checked}
          handleToggle={handleToggle}
          listTitleParser={listTitleParser}
          disabled={disabled}
        />
      )}
    </Card>
  );
};

const SearchComponent = ({ searchList, setSearchList }: any) => {
  return (
    <div className="fw-bolder text-muted bg-light w-100 p-3 rounded">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-2"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid bg-white py-2 ps-10"
          placeholder="Cauta..."
          value={searchList}
          onChange={(e) => setSearchList(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

const ItemsList = ({
  items,
  listCrudItems,
  checked,
  handleToggle,
  disabled,
  listTitleParser,
}: {
  items: string[];
  listCrudItems?: any[] | null;
  checked: string[];
  handleToggle: any;
  disabled?: boolean;
  listTitleParser?: (
    listItem: string,
    crudListItem?: any[],
  ) => string | JSX.Element | JSX.Element[] | React.ReactNode;
}) => {
  if (items.length === 0) {
    return (
      <Box paddingY={5} height="65vh">
        <Text textAlign={"center"}>Lista este goala</Text>
      </Box>
    );
  }

  const listTitle = (item: string) => {
    if (listTitleParser && listCrudItems) {
      return listTitleParser(item, listCrudItems);
    }
    return item;
  };
  return (
    <List
      sx={{
        width: "100%",
        height: "65vh",
        bgcolor: "background.paper",
        overflow: "auto",
      }}
      dense
      component="div"
      role="list">
      {items.map((value: string) => {
        const labelId = `transfer-invoice-item-${value}-label`;

        return (
          <ListItem
            key={value}
            role="listitem"
            button
            disabled={disabled}
            onClick={handleToggle(value)}>
            <ListItemIcon>
              <Checkbox
                checked={checked.indexOf(value) !== -1}
                tabIndex={-1}
                disableRipple
                disabled={disabled}
                inputProps={{
                  "aria-labelledby": labelId,
                }}
              />
            </ListItemIcon>
            <ListItemText id={labelId} primary={listTitle(value)} />
          </ListItem>
        );
      })}
      <ListItem />
    </List>
  );
};

export default function TransferList({
  leftListTitle = "",
  rightListTitle = "",
  leftListItems,
  rightListItems,
  setRightList,
  setLeftList,
  isLoading,
  disabled,
  listCrudItems,
  listTitleParser,
  alreadyCheckedItems = [],
  showLeftListTotal = true,
  showRightListTotal = true,
}: {
  leftListTitle?: string;
  rightListTitle?: string;
  leftListItems: string[];
  rightListItems: string[];
  setRightList: (value: string[]) => void;
  setLeftList: (value: string[]) => void;
  isLoading?: boolean;
  disabled?: boolean;
  listCrudItems?: any[] | null;
  listTitleParser?: (
    listItem: string,
    crudListItem?: any[],
  ) => string | JSX.Element | JSX.Element[] | React.ReactNode;
  alreadyCheckedItems?: string[];
  showLeftListTotal?: boolean;
  showRightListTotal?: boolean;
}) {
  const [checked, setChecked] = useState<string[]>([]);

  const leftChecked = intersection(checked, leftListItems);
  const rightChecked = intersection(checked, rightListItems);

  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleCheckedRight = () => {
    setRightList(rightListItems.concat(leftChecked));
    setLeftList(not(leftListItems, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setLeftList(leftListItems.concat(rightChecked));
    setRightList(not(rightListItems, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  useEffect(() => {
    if (alreadyCheckedItems?.length > 0) {
      setChecked(alreadyCheckedItems);
    }
  }, [alreadyCheckedItems]);

  return (
    <Box className="h-100 d-flex justify-content-between align-items-center">
      <Box className="w-100">
        <CustomList
          title={`${leftListTitle} 
          ${showLeftListTotal ? `(${leftListItems.length})` : ""}`}
          items={leftListItems}
          listCrudItems={listCrudItems}
          listTitleParser={listTitleParser}
          isLoading={isLoading}
          checked={checked}
          handleToggle={handleToggle}
          disabled={disabled}
        />
      </Box>
      <Box className="mx-6">
        <Box>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedRight}
            disabled={leftChecked.length === 0 || disabled}
            aria-label="move selected right">
            &gt;
          </Button>
          <Button
            sx={{ my: 0.5 }}
            variant="outlined"
            size="small"
            onClick={handleCheckedLeft}
            disabled={rightChecked.length === 0 || disabled}
            aria-label="move selected left">
            &lt;
          </Button>
        </Box>
      </Box>
      <Box className="w-100">
        <CustomList
          title={`${rightListTitle} 
            ${showRightListTotal ? `(${rightListItems.length})` : ""}`}
          items={rightListItems}
          listCrudItems={listCrudItems}
          listTitleParser={listTitleParser}
          isLoading={isLoading}
          checked={checked}
          handleToggle={handleToggle}
          disabled={disabled}
        />
      </Box>
    </Box>
  );
}
