import React, { useState } from "react";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import CustomButton from "../../../../components/CustomButton";
import Text from "../../../../styleguide/Text";
import TextInput from "../../../../components/misc/TextInput";
import DatePicker from "../../../../components/misc/DatePicker";
import { dateForDatabase } from "../../../../utils/dateAndTime";
import ConfirmationModal from "../../../../components/misc/ConfirmationModal";

function EditProductModal({
  productData,
  isEditModalOpen,
  setIsEditModalOpen,
  handleSubmitEditProduct,
}: {
  productData: any;
  isEditModalOpen: boolean;
  setIsEditModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleSubmitEditProduct: (value: {
    idActivCel: number;
    codProdus: string;
    lot: string;
    dataExpirare: string;
    cantitate: number;
  }) => void;
}) {
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [codProdus, setCodProdus] = useState(productData?.codProdus);
  const [lot, setLot] = useState(productData?.lot);
  const [dataExpirare, setDataExpirare] = useState(productData?.dataExpirare);
  const [cantitate, setCantitate] = useState(productData?.cantitate);
  const [errorMessage, setErrorMessage] = useState("");

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
  };

  const handleSubmit = () => {
    if (!codProdus || !lot || !dataExpirare || !cantitate) {
      setErrorMessage("Toate campurile sunt obligatorii");
      return;
    }
    handleSubmitEditProduct({
      idActivCel: productData?.idActivCel,
      codProdus: codProdus,
      lot: lot,
      dataExpirare: dateForDatabase(dataExpirare),
      cantitate: cantitate,
    });
    setShowConfirmationModal(false);
  };

  return (
    <>
      <ConfirmationModal
        show={showConfirmationModal}
        handleClose={handleCloseConfirmationModal}
        title={"Doriti sa editati produsul?"}
        leftButtonOnClick={handleCloseConfirmationModal}
        leftButtonVariant="outlined"
        rightButtonOnClick={handleSubmit}
        isSmallModal={true}
      />

      <ModalWrapper
        title={`Editare produs:  ${productData?.numeProdusErp}`}
        show={isEditModalOpen}
        handleClose={() => setIsEditModalOpen(false)}>
        <TextInput
          type="text"
          label={"Cod produs"}
          name={"codProdus"}
          value={codProdus}
          onChange={(value) => setCodProdus(value)}
        />
        <TextInput
          type="text"
          label={"Lot"}
          name={"lot"}
          value={lot}
          onChange={(value) => setLot(value)}
        />
        <DatePicker
          label="Data expirare"
          isRequired
          withVerticalSpacer
          value={dataExpirare}
          handleChangeDate={(value) => setDataExpirare(value)}
        />
        <TextInput
          type="number"
          label={"Cantitate"}
          name={"cantitate"}
          value={cantitate}
          onChange={(value) => setCantitate(value)}
        />
        {errorMessage && (
          <Text variant="body1" color="red" isBold>
            *{errorMessage}
          </Text>
        )}
        <CustomButton
          type="submit"
          variant="contained"
          fullWidth
          className="btn btn-lg btn-primary w-100 mt-5"
          onClick={() => setShowConfirmationModal(true)}>
          <Text variant="body1" className="indicator-label" isBold>
            Salveaza
          </Text>
        </CustomButton>
      </ModalWrapper>
    </>
  );
}

export default EditProductModal;
