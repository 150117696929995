import { useState, useMemo, useCallback, useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import CategoryHeader from "../../../../components/misc/CategoryHeader";
import SelectableItem, {
  OnSelectableItemClick,
  getNewItemsWithCategory,
} from "../../../../components/misc/SelectableItem/SelectableItem";
import TextInput from "../../../../components/misc/TextInput";
import Text from "../../../../styleguide/Text";
import Colors from "../../../../theme/Colors";
import Checkbox from "../../../../components/misc/Checkbox";
import QuarantineSolutionResolveAviz from "../QuarantineSolutionResolveAviz";
import { useAppDispatch } from "../../../../redux/hooks";
import { insertQuarantineSolution } from "../../../../redux/api/receipt.api";
import { fetchProductDetails } from "../../../../redux/api/general.api";
import { setGlobalToast } from "../../../../redux/reducer/globalComponents.reducer";
import {
  NrcdNonconformingItemInterface,
  QuarantineListInterface,
} from "../../../../redux/interfaces/receipt.interface";

function QuarantineSolutionResolveModalContent({
  crudData,
}: {
  crudData: QuarantineListInterface;
}) {
  const dispatch = useAppDispatch();

  const [problems, setProblems] = useState<NrcdNonconformingItemInterface[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState(false);
  const [checkQuantityLimit, setCheckQuantityLimit] = useState(false);
  const [sendToStorage, setSendToStorage] = useState(false);
  const [availableDeposits, setAvailableDeposits] = useState<any[]>([]);

  const totalQuantity = useMemo(
    () =>
      problems.reduce((acc, curr) => {
        const cantitate = curr.cantitate || 0;
        return acc + cantitate;
      }, 0) + crudData?.cantitateSolutionata,
    [problems, crudData?.cantitateSolutionata],
  );

  const quantityDifferencePositive = useMemo(
    () => Math.abs(crudData.diferenta),
    [crudData.diferenta],
  );

  useEffect(() => {
    if (crudData.neconformitati.length > 0) {
      setProblems([...crudData.neconformitati]);
    }
  }, [crudData.neconformitati]);

  useEffect(() => {
    if (crudData.depozit?.length > 0) {
      setAvailableDeposits(crudData.depozit);
    }
  }, [crudData.depozit]);

  useEffect(() => {
    const withFetchProduct = crudData.neconformitati.some(
      (item: NrcdNonconformingItemInterface) => {
        return item.reasons.some((reason) => {
          return reason.value === 10;
        });
      },
    );
    if (crudData?.codProdusWms && withFetchProduct) {
      dispatch(
        fetchProductDetails({
          productCode: crudData?.codProdusWms,
        }),
      );
    }
  }, [dispatch, crudData.codProdusWms, crudData.neconformitati]);

  const onClick = ({ items, name }: OnSelectableItemClick) => {
    if (name && items) {
      const newProblemsItems = getNewItemsWithCategory(name, problems, items);
      setProblems(newProblemsItems);
    }
  };

  const onQuantityChange = (value: number | string | null, type: string) => {
    const newProblems = problems.map((problem) => {
      if (problem.type === type) {
        return {
          ...problem,
          cantitate: value ? parseInt(value.toString()) : null,
        };
      }
      return problem;
    });
    setProblems(newProblems);
  };

  const onPropertyChange = useCallback(
    (key: string, value: number | string | null | Date, type: string) => {
      setProblems((prev) =>
        prev.map((problem) => {
          if (problem.type === type) {
            return {
              ...problem,
              [key]: value,
            };
          }
          return problem;
        }),
      );
    },
    [],
  );

  const handleSplitQuantity = (value: any, depId: any) => {
    const newSplitQuantitys = availableDeposits.map((depo) => {
      if (depo.idDepo === depId) {
        return { ...depo, quantity: value };
      }
      return depo;
    });

    setAvailableDeposits(newSplitQuantitys);
  };

  const withNoQuantityLimit = () => {
    const noQuantityLimitType = problems.some(
      (problem) => problem.idType === 3,
    );

    return noQuantityLimitType;
  };

  const handleSuccess = () => {
    setIsLoading(false);
  };

  const onSubmit = async () => {
    const problemsWithSelectedReason = problems.map((problem) => {
      const reason = problem?.reasons?.find((reason) => reason?.checked)?.value;
      return {
        ...problem,
        reason,
      };
    });

    const withProductFormCheckError = problems.some((item) => {
      return item.reasons.some((reason) => {
        if (reason.value === 10 && reason.checked) {
          return (
            !item.newLot ||
            !item.newBBD ||
            !item.newProductId ||
            !item.newProductApId ||
            !item.cantitate
          );
        }
      });
    });

    if (withProductFormCheckError) {
      dispatch(
        setGlobalToast({
          open: true,
          vertical: "bottom",
          horizontal: "center",
          message: "Trebuie completate toate campurile pentru produsul nou!",
          severity: "error",
        }),
      );
      return;
    }

    const transferObject: any = {
      ...crudData,
      neconformitati: problemsWithSelectedReason,
      storage: sendToStorage,
      depozit: availableDeposits,
    };
    setIsLoading(true);
    dispatch(
      insertQuarantineSolution({
        data: transferObject,
        callBack: handleSuccess,
      }),
    );
  };

  const isSubmitDisabled = useMemo(
    () => !checkQuantityLimit && totalQuantity > quantityDifferencePositive,
    [checkQuantityLimit, totalQuantity, quantityDifferencePositive],
  );

  return (
    <>
      {problems.map(
        ({ reasons, type, cantitate, solutionare, newLot, newBBD }) => {
          const isEditingQuarantineProduct = reasons.some(
            (reason) => reason.value === 10 && reason.checked,
          );

          return (
            <div key={type}>
              <CategoryHeader title={type} />
              {reasons.map(({ label, value, checked }) => {
                return (
                  <div key={value}>
                    <SelectableItem
                      name={type}
                      value={value}
                      onClick={onClick}
                      checked={checked}
                      items={reasons}
                      type="radio"
                      title={label}
                      icon={{
                        name: "/media/icons/duotune/maps/map004.svg",
                      }}
                    />
                  </div>
                );
              })}

              {isEditingQuarantineProduct && (
                <QuarantineSolutionResolveAviz
                  crudData={crudData}
                  type={type}
                  onPropertyChange={onPropertyChange}
                  newLot={newLot}
                  newBBD={newBBD}
                />
              )}
              <TextInput
                id={`quantity-${type}`}
                label="Cantitate"
                type="number"
                inputProps={{ inputProps: { min: 0, pattern: "[0-9]*" } }}
                name={`quantity-${type}`}
                value={cantitate}
                onChange={(value) => onQuantityChange(value, type)}
                onlyPositiveNumbers
              />
              <TextInput
                id={`solution-${type}`}
                label="Solutionare"
                name={`solution-${type}`}
                type="textarea"
                value={solutionare}
                onChange={(value) =>
                  onPropertyChange("solutionare", value, type)
                }
              />
            </div>
          );
        },
      )}
      {withNoQuantityLimit() && (
        <Checkbox
          label="Adauga cantitate peste limita"
          name={"checkQuantityLimit"}
          labelAlign="left"
          onChange={() => setCheckQuantityLimit((prev) => !prev)}
          checked={checkQuantityLimit}
          textVariant="h4"
          className="my-6"
        />
      )}

      <Checkbox
        label="Trimite in storage"
        name={"sendToStorage"}
        labelAlign="left"
        onChange={() => setSendToStorage((prev) => !prev)}
        checked={sendToStorage}
        textVariant="h4"
        className="my-6"
      />
      {sendToStorage && (
        <>
          {availableDeposits?.map((dep) => {
            return (
              <TextInput
                key={dep.idDepo}
                type="number"
                label={`Depozit ${dep.codDep}`}
                name={dep.codDep}
                value={dep.quantity ?? ""}
                onChange={(value) => handleSplitQuantity(value, dep.idDepo)}
              />
            );
          })}
        </>
      )}

      <Text variant="h3" color={isSubmitDisabled ? Colors.red : Colors.black}>
        {`Cantitate totala: ${totalQuantity} / ${quantityDifferencePositive}`}
      </Text>
      <LoadingButton
        type="submit"
        fullWidth
        className="btn btn-lg btn-primary w-100 mt-5"
        disabled={isSubmitDisabled}
        loading={isLoading}
        onClick={onSubmit}>
        <Text variant="body1" className="indicator-label" isBold>
          Salveaza
        </Text>
      </LoadingButton>
    </>
  );
}

export default QuarantineSolutionResolveModalContent;
