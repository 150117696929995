import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import { useEffect, useState } from "react";

import ContentCard from "../../../components/misc/ContentCard";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/misc/TextInput";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useRoutesHelper from "../../../hooks/useRoutesHelper";

import {
  fetchUserDetails,
  postUserDetails,
} from "../../../redux/api/admin.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

interface initialValuesInterface {
  userName: string;
  firstName: string;
  lastName: string;
  password: string;
  verifyPassword: string;
}

const initialValues: initialValuesInterface = {
  userName: "",
  firstName: "",
  lastName: "",
  password: "",
  verifyPassword: "",
};

const productSchema = Yup.object({
  userName: Yup.string().required("Campul este obligatoriu"),
  firstName: Yup.string().required("Campul este obligatoriu"),
  lastName: Yup.string().required("Campul este obligatoriu"),
  password: Yup.string(),
  verifyPassword: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Parolele nu se potrivesc",
  ),
});

function EditUser() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { routeParams } = useRoutesHelper();

  const userId = routeParams.userId;

  const {
    admin: {
      user: { data: userDetailsData, isLoadingUser },
    },
  } = useAppSelector((state) => ({
    admin: state.admin,
  }));

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (userId) {
      dispatch(fetchUserDetails(userId));
    }
  }, [dispatch, userId]);

  useEffect(() => {
    if (userDetailsData) {
      setFormikValues((value) => ({
        ...value,
        userName: userDetailsData?.userName || null,
        firstName: userDetailsData?.nume || null,
        lastName: userDetailsData?.prenume || null,
      }));
    }
  }, [userDetailsData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postUserDetails({
        userId,
        userDetails: value,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postUserDetails) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postUserDetails) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  return (
    <ContentCard cardTitle="Editeaza utilizator" isLoading={isLoadingUser}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          getFieldProps,
          handleSubmit,
          isSubmitting,
        }) => (
          <form
            className="form w-100"
            onSubmit={handleSubmit}
            noValidate
            id="kt_login_signin_form">
            <TextInput
              label="Username"
              name="userName"
              error={errors["userName"]}
              touched={touched["userName"]}
              inputProps={getFieldProps("userName")}
            />
            <TextInput
              label="Nume"
              name="lastName"
              error={errors["lastName"]}
              touched={touched["lastName"]}
              inputProps={getFieldProps("lastName")}
            />
            <TextInput
              label="Prenume"
              name="firstName"
              error={errors["firstName"]}
              touched={touched["firstName"]}
              inputProps={getFieldProps("firstName")}
            />
            <TextInput
              label="Parola"
              name="password"
              type="password"
              error={errors["password"]}
              touched={touched["password"]}
              inputProps={getFieldProps("password")}
            />
            <TextInput
              label="Verifica Parola"
              name="verifyPassword"
              type="password"
              error={errors["verifyPassword"]}
              touched={touched["verifyPassword"]}
              inputProps={getFieldProps("verifyPassword")}
            />

            <div className="d-flex justify-content-between">
              <CustomButton
                type="submit"
                variant="outlined"
                onClick={handleGoBack}>
                Anuleaza
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                loading={isSubmitting || isLoading}
                disabled={!isValid || isSubmitting}>
                Salveaza
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </ContentCard>
  );
}

export default EditUser;
