import { useNavigate } from "react-router-dom";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import CustomButton from "../../../../components/CustomButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { getDeleteProduct } from "../../../../redux/api/receipt.api";

function ReceivedProductsTableAction({
  itemId,
  status,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  status: number;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const finalizedReceptions = status >= 5;

  const editProduct = () => {
    navigate(
      `/${receptionRoutes.reception}/${receptionRoutes.receivedProductsEdit}?idProdus=${itemId}`,
    );
  };

  const deleteProduct = () => {
    dispatch(
      getDeleteProduct({
        idProdusReceptie: itemId,
      }),
    );
  };

  return (
    <td className="text-end">
      {!finalizedReceptions && (
        <CustomButton
          className="me-1  mb-2"
          variant="contained"
          color="primary"
          fullWidth
          onClick={editProduct}>
          Editare
        </CustomButton>
      )}
      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        withConfirmationModal={{
          modalTitle: "Stergere produs",
          modalSubtitle: "Esti sigur ca vrei sa stergi produsul?",
          modalLeftButtonColor: "inherit",
          modalRightButtonColor: "error",
          modalRightButtonOnClick: deleteProduct,
        }}>
        Stergere
      </CustomButton>
    </td>
  );
}

export default ReceivedProductsTableAction;
