import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ReturnProductDataInterface } from "../../../../redux/interfaces/returning.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const returnsDifferencesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Nume produs",
    value: "numeProdus",
    minW: 125,
    withSearch: true,
  },
  {
    value: "codProdusDepo",
    label: "Cod produs",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Lot",
    value: "lotWms",
    minW: 125,
    withSearch: true,
  },
  {
    value: "Data expirare",
    minW: 160,
  },
  {
    value: "Cantitate",
    minW: 125,
  },
  {
    label: "Diferenta",
    value: "diferenta",
    minW: 125,
  },
];

export const getReturnDifferencesTableData = (
  data: ReturnProductDataInterface[],
): any =>
  data.map((item) => {
    return {
      id: item.idProdusRetur,
      crudData: item,
      data: [
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdusDepo,
        },
        {
          title: [
            {
              text: `Faptic: ${item.lotWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${item.lotDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${dateToString(item.dexpWms)}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${dateToString(item.dexpDepo)}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${item.cantWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${item.cantDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: item.diferenta,
        },
      ],
    };
  });
