import { useState } from "react";
import Text from "../../../../styleguide/Text";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import DepositStocksTransferModal from "./DepositStocksTransferModal";
import CustomButton from "../../../../components/CustomButton";
import EditProductModal from "./EditProductModal";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  postDeleteDepositStockProduct,
  postEditDepositStockProduct,
} from "../../../../redux/api/inventory.api";
import usePermissions from "../../../../router/usePermissions";

interface DepositStocksActionButtonsProps {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}

function DepositStocksActionButtons({
  crudData,
  tableContext,
}: DepositStocksActionButtonsProps) {
  const dispatch = useAppDispatch();
  const { isAuthorized } = usePermissions();

  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);

  const tableFilterData = {
    page: tableContext.tablePagination.page,
    perPage: tableContext.tablePagination.rowsPerPage,
    alias: tableContext.tableSearch?.alias,
    alley: tableContext.tableSearch?.alley,
    building: tableContext.tableSearch?.building,
    cell: tableContext.tableSearch?.cell,
    column: tableContext.tableSearch?.column,
    island: tableContext.tableSearch?.island,
    productCode: tableContext.tableSearch?.productCode,
    productName: tableContext.tableSearch?.productName,
    row: tableContext.tableSearch?.row,
    shelf: tableContext.tableSearch?.shelf,
  };

  const handleDeleteProduct = () => {
    dispatch(
      postDeleteDepositStockProduct({
        idActivCel: crudData.idActivCel,
        filters: tableFilterData,
      }),
    );
  };

  const handleSubmitEditProduct = (productNewValues: {
    idActivCel: number;
    codProdus: string;
    lot: string;
    dataExpirare: string;
    cantitate: number;
  }) => {
    dispatch(
      postEditDepositStockProduct({
        params: productNewValues,
        filters: tableFilterData,
      }),
    );
  };

  return (
    <>
      {isSplitModalOpen && crudData && (
        <DepositStocksTransferModal
          productData={crudData}
          isSplitModalOpen={isSplitModalOpen}
          setIsSplitModalOpen={setIsSplitModalOpen}
          tableFilterData={tableFilterData}
        />
      )}
      {isEditModalOpen && crudData && (
        <EditProductModal
          productData={crudData}
          isEditModalOpen={isEditModalOpen}
          setIsEditModalOpen={setIsEditModalOpen}
          handleSubmitEditProduct={handleSubmitEditProduct}
        />
      )}

      <td className="text-end">
        <CustomButton
          className="badge btn btn-primary btn-sm me-1"
          color="primary"
          fullWidth
          onClick={() => setIsSplitModalOpen(true)}>
          <Text variant="body2" className="indicator-label" isBold>
            Transfer
          </Text>
        </CustomButton>
        {isAuthorized("deposit_stocks_action") && (
          <>
            <CustomButton
              className="mt-1 me-1 w-100"
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => setIsEditModalOpen(true)}>
              <Text variant="body2" className="indicator-label" isBold>
                Editeaza
              </Text>
            </CustomButton>
            <CustomButton
              variant="contained"
              color="warning"
              className="mt-1 me-1 w-100"
              withConfirmationModal={{
                modalLeftButtonVariant: "outlined",
                modalTitle: `Doriti sa stergeti din celula produsul: "${crudData.numeProdusErp}"?`,
                modalRightButtonOnClick: handleDeleteProduct,
                isSmallModal: false,
              }}>
              <Text variant="body2" className="indicator-label" isBold>
                Sterge
              </Text>
            </CustomButton>
          </>
        )}
      </td>
    </>
  );
}

export default DepositStocksActionButtons;
