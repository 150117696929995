import { Autocomplete, CircularProgress, TextField } from "@mui/material";
import React, { Dispatch, SetStateAction, useState } from "react";

function AutocompleteDropdown({
  id = "autocomplete",
  data,
  isLoadingReturnInvoices,
  setSearchValue,
  labelTag,
  placeholder = "Cauta",
  setSelectedInvoices,
}: {
  id: string;
  data: any[];
  isLoadingReturnInvoices: boolean;
  setSearchValue: Dispatch<SetStateAction<string>>;
  labelTag: string;
  placeholder?: string;
  setSelectedInvoices: Dispatch<SetStateAction<any[]>>;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Autocomplete
      id={id}
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      onInputChange={(event, value) => setSearchValue(value)}
      onChange={(event, value) => {
        setSelectedInvoices(value);
      }}
      isOptionEqualToValue={(option, value) =>
        option[labelTag] === value[labelTag]
      }
      getOptionLabel={(option) => option[labelTag]}
      options={data}
      loading={isLoadingReturnInvoices}
      multiple={true}
      renderInput={(params) => (
        <TextField
          {...params}
          label={placeholder}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {isLoadingReturnInvoices ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  );
}

export default AutocompleteDropdown;
