import React from "react";
import MultipleDropdown from "./MultipleDropdown";
import SingleDropdown from "./SingleDropdown";

export interface DropdownValue {
  id: number | string;
  label: string | number;
}

function Dropdown({
  className,
  style,
  data,
  multipleValues,
  setMultipleValues,
  singleValue,
  setSingleValue,
  placeholder,
  title,
  name,
  isLoading,
  disabled,
  withVerticalSpacer,
}: {
  className?: string;
  style?: React.CSSProperties;
  data: DropdownValue[];
  multipleValues?: DropdownValue[];
  setMultipleValues?: (value: DropdownValue[]) => void;
  singleValue?: DropdownValue | null;
  setSingleValue?: (value: DropdownValue | null) => void;
  placeholder?: string;
  title?: string;
  name?: string;
  isLoading?: boolean;
  disabled?: boolean;
  withVerticalSpacer?: boolean;
}) {
  if (multipleValues !== undefined && setMultipleValues) {
    return (
      <MultipleDropdown
        data={data ?? []}
        multipleValues={multipleValues}
        setMultipleValues={setMultipleValues}
        className={className}
        style={style}
        placeholder={placeholder}
        title={title}
        name={name}
        isLoading={isLoading}
        disabled={disabled}
        withVerticalSpacer={withVerticalSpacer}
      />
    );
  }
  if (singleValue !== undefined && setSingleValue) {
    return (
      <SingleDropdown
        data={data ?? []}
        singleValue={singleValue}
        setSingleValue={setSingleValue}
        className={className}
        style={style}
        placeholder={placeholder}
        title={title}
        name={name}
        isLoading={isLoading}
        disabled={disabled}
        withVerticalSpacer={withVerticalSpacer}
      />
    );
  }
  return <></>;
}

export default Dropdown;
