import { dateToString } from "../../../../utils/dateAndTime";

export const transferReceivedOperationsTableHeader = [
  {
    value: "selected",
    label: "",
    minW: 125,
  },
  {
    value: "numeProdus",
    label: "Nume produs",
    minW: 150,
  },
  {
    value: "codProdusWMS",
    label: "Cod CIM",
  },
  {
    value: "lot",
    label: "Lot",
  },
  {
    value: "dexpWMS",
    label: "Data expirare",
  },
  {
    value: "cantitate",
    label: "Cantitate",
    minW: 125,
  },
];

export const getTransferReceivedOperationsTableData: any = (data: any) =>
  data?.map((item: any) => {
    return {
      id: item.id,
      crudData: item,
      data: [
        {
          checkbox: {
            isSelected: item?.isSelected,
          },
        },
        {
          title: item.numeProdus,
        },
        {
          title: item.codProdusWMS,
        },
        {
          title: item.lotWMS,
        },
        {
          badges: [
            {
              badgeText: dateToString(item.dexpWMS),
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: item.cantitate,
        },
      ],
    };
  });
