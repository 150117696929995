import React from "react";
import { ProductData } from ".";
import ContentCard from "../../../../../../components/misc/ContentCard";
import Text from "../../../../../../styleguide/Text";
import CustomButton from "../../../../../../components/CustomButton";
import SvgIcon from "../../../../../../helpers/SvgIcon";
import { dateToString } from "../../../../../../utils/dateAndTime";

function NewProductsList({
  productsAdded,
  setProductsAdded,
}: {
  productsAdded: ProductData[];
  setProductsAdded: React.Dispatch<React.SetStateAction<ProductData[]>>;
}) {
  const handleRemoveProduct = (index: number) => {
    setProductsAdded((prev) => prev.filter((_, i) => i !== index));
  };

  return (
    <div className="mt-5">
      {productsAdded.map((product, index) => (
        <ContentCard key={index} className="d-flex flex-column">
          <div className="d-flex justify-content-between align-items-center">
            <Text variant="h5" gutterBottom>
              Lot: {product.lot}
            </Text>
            <CustomButton
              color="error"
              variant="contained"
              onClick={() => handleRemoveProduct(index)}>
              <SvgIcon type="DELETE" />
            </CustomButton>
          </div>
          <Text variant="h5" gutterBottom>
            Data expirare: {dateToString(product.data_exp)}
          </Text>
          <Text variant="h5" gutterBottom>
            Cantitate: {product.cantitate}
          </Text>
        </ContentCard>
      ))}
    </div>
  );
}

export default NewProductsList;
