import { dateToString } from "../../../../utils/dateAndTime";

export const nrcdTableHeader = [
  {
    value: "numeProdus",
    label: "Nume produs",
    minW: 125,
    withSearch: true,
  },
  {
    value: "codProdus",
    label: "Cod CIM",
    minW: 125,
    withSearch: true,
  },
  {
    value: "lotDepo",
    label: "Lot",
    minW: 100,
    withSearch: true,
  },

  {
    value: "dexpDepo",
    label: "Data expirare",
    minW: 150,
  },
  {
    value: "Cantitate",
    minW: 150,
  },
  {
    value: "Facturi",
    minW: 125,
  },
];

const getQuantityDifferenceConfig = (total: number) => {
  if (total === null || total === undefined) {
    return { badgeText: null };
  }
  const badgeColor = () => {
    return "light-danger";
  };
  const badgeText = () => {
    return `${total}`;
  };

  return {
    badgeText: badgeText(),
    badgeColor: badgeColor(),
  };
};

export const getNrcdTableData: any = (nrcdList: any) =>
  nrcdList?.map((nrcdItem: any) => {
    return {
      id: nrcdItem.idNrcd,
      crudData: nrcdItem,
      data: [
        {
          title: nrcdItem.numeProdus,
        },
        {
          title: nrcdItem.codProdus,
        },
        {
          title: nrcdItem.lotDepo,
        },
        {
          badges: [
            {
              badgeText: dateToString(nrcdItem.dexpDepo),
              badgeColor: "light-danger",
            },
          ],
        },
        {
          subtitle: `Scriptic: ${nrcdItem.cantDepo}`,
          title: `Faptic: ${nrcdItem.cantWms}`,
          badges: [getQuantityDifferenceConfig(nrcdItem.diferenta)],
        },
        {
          badges: nrcdItem.facturi?.map((factura: any) => ({
            badgeText: factura.codFactura,
            badgeColor: "light-info",
            style: { minWidth: "125px" },
          })),
        },
      ],
    };
  });
