import { Box } from "@mui/material";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getTransferTasksTableData,
  transferTasksTableHeader,
} from "./components/tableConfigs/transferTableConfig";
import { getTransferTasks } from "../../redux/api/receipt.api";

function TransferTasks() {
  const dispatch = useAppDispatch();

  const {
    receipt: {
      transferTasks: { data, isLoadingTransferTasks },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getTransferTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status ?? 1,
          type: action.tableFilter?.type ?? 1,
        }),
      );
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle={"Taskuri de transfer marfa catre depozitul de vanzare"}
        tableHeaderData={transferTasksTableHeader}
        tableItemsData={getTransferTasksTableData(data.taskuriTransfer)}
        totalItems={data.nrTotalTaskuriTransfer}
        tableLoading={isLoadingTransferTasks}
        handleTableActions={handleTableActions}
        withGlobalSearch={false}
        withGlobalFilter={false}
        withTablePagination
      />
    </Box>
  );
}

export default TransferTasks;
