import Breadcrumbs from "@mui/material/Breadcrumbs";

import { Link, useLocation } from "react-router-dom";
import Text from "../styleguide/Text";
import Colors from "../theme/Colors";
import { getFirstLetterUpperCase } from "../utils/parsers";
import { initialMainRoute } from "./routesConstants";

function BreadcrumbsComponent() {
  const currentPath = useLocation().pathname;

  const currentPathArray = currentPath.split("/");

  const currentArrayPathWithoutFirst = currentPathArray.slice(1);

  const addInitialRoute = !currentPathArray.includes(initialMainRoute)
    ? [initialMainRoute, ...currentArrayPathWithoutFirst]
    : [];

  const pathFinalArray = addInitialRoute;

  if (pathFinalArray.length === 0) return null;

  return (
    <div className="bg-white py-5 px-7">
      <Breadcrumbs maxItems={5} aria-label="breadcrumb">
        {pathFinalArray.map((path, idx, paths) => {
          const indexOfCurrentPath = paths.indexOf(path);
          const withoutCurrentPath = paths.slice(1, indexOfCurrentPath + 1);
          const constructedPath = withoutCurrentPath.join("/");

          if (paths.length - 1 === idx) {
            return (
              <Text key={`${path}-${idx}`} color={Colors.black}>
                {getFirstLetterUpperCase(path)}
              </Text>
            );
          }

          return (
            <Link
              key={`${path}-${idx}`}
              to={constructedPath}
              className="text-muted">
              {getFirstLetterUpperCase(path)}
            </Link>
          );
        })}
      </Breadcrumbs>
    </div>
  );
}

export default BreadcrumbsComponent;
