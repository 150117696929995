import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getIncompleteInvoices } from "../../../redux/api/expedition.api";
import ContentCard from "../../../components/misc/ContentCard";
import { TableWidget } from "../../../components/tables/TableWidget";
import {
  getIncompleteInvoicesTableData,
  incompleteInvoicesTableHeader,
} from "./tableConfig/incompleteInvoicesTableConfig";

function IncompleteInvoices() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      incompleteInvoices: {
        data: incompleteInvoicesData,
        isLoadingIncompleteInvoices,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(getIncompleteInvoices());
  }, [dispatch]);

  return (
    <ContentCard>
      <TableWidget
        tableTitle={"Facturi fara destinatar"}
        tableHeaderData={incompleteInvoicesTableHeader}
        tableItemsData={getIncompleteInvoicesTableData(incompleteInvoicesData)}
        tableLoading={isLoadingIncompleteInvoices}
        showSearchForCategories={false}
        withTablePagination={false}
        borderedRow
      />
    </ContentCard>
  );
}

export default IncompleteInvoices;
