import { useMemo, useState } from "react";

import TextInput from "../../../../../../components/misc/TextInput";
import CustomButton from "../../../../../../components/CustomButton";
import ContentCard from "../../../../../../components/misc/ContentCard";
import DatePicker from "../../../../../../components/misc/DatePicker";

import { ProductData } from ".";

function NewProductForm({
  handleNewProductAdded,
  availableQuantity,
}: {
  handleNewProductAdded: (product: ProductData) => void;
  availableQuantity: number;
}) {
  const [newProduct, setNewProduct] = useState<ProductData>({
    lot: "",
    data_exp: null,
    cantitate: "",
  });

  const handleAddNewProduct = () => {
    if (!newProduct.lot || !newProduct.data_exp || !newProduct.cantitate) {
      return;
    }
    handleNewProductAdded(newProduct);
    setNewProduct({
      lot: "",
      data_exp: null,
      cantitate: "",
    });
  };

  const disableAddProductButton = useMemo(() => {
    if (!newProduct.lot || !newProduct.data_exp || !newProduct.cantitate) {
      return true;
    }
    if (Number(newProduct.cantitate) > availableQuantity) {
      return true;
    }
    return false;
  }, [newProduct, availableQuantity]);

  return (
    <ContentCard>
      <TextInput
        label="Lot nou"
        name="newLot"
        id="newLot"
        value={newProduct?.lot}
        onChange={(value) =>
          setNewProduct((prev) => ({
            ...prev,
            lot: value,
          }))
        }
      />
      {/* label="De la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={startDate}
        handleChangeDate={(value) => setStartDate(value)} */}

      <DatePicker
        label="BBD"
        value={newProduct?.data_exp}
        handleChangeDate={(value) =>
          setNewProduct((prev) => ({
            ...prev,
            data_exp: value,
          }))
        }
      />
      <TextInput
        label="Cantitate"
        name="newQuantity"
        id="newQuantity"
        type="number"
        value={newProduct?.cantitate}
        onChange={(value) =>
          setNewProduct((prev) => ({
            ...prev,
            cantitate: value,
          }))
        }
      />
      <CustomButton
        className="ms-auto"
        disabled={disableAddProductButton}
        onClick={handleAddNewProduct}>
        Adauga produs
      </CustomButton>
    </ContentCard>
  );
}

export default NewProductForm;
