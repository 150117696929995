import { createSlice } from "@reduxjs/toolkit";
import {
  IncompleteInvoicesInterface,
  MonitoringPlasticBoxesInterface,
  PlasticBoxesListInterface,
  ScheduleWorkingPointsDetailsInterface,
  ScheduleWorkingPointsInterface,
  ScheduleWorkingRoutesDetailsInterface,
  ScheduleWorkingRoutesInterface,
  WorkingRoutesNodesInterface,
} from "../interfaces/expedition.interface";
import {
  getIncompleteInvoices,
  getScheduleWorkingPoints,
  getScheduleWorkingPointsDetails,
  getScheduleWorkingRoutes,
  getScheduleWorkingRoutesDetails,
  getWorkingRoutesNodes,
  postMonitoringPlasticBoxes,
} from "../api/expedition.api";

const initialExpeditionState: {
  scheduleWorkingPoints: ScheduleWorkingPointsInterface;
  scheduleWorkingPointsDetails: ScheduleWorkingPointsDetailsInterface;
  scheduleWorkingRoutes: ScheduleWorkingRoutesInterface;
  scheduleWorkingRoutesDetails: ScheduleWorkingRoutesDetailsInterface;
  workingRoutesNodes: WorkingRoutesNodesInterface;
  incompleteInvoices: IncompleteInvoicesInterface;
  plasticBoxesScanned: {
    data: PlasticBoxesListInterface[];
  };
  monitoringPlasticBoxes: MonitoringPlasticBoxesInterface;
} = {
  scheduleWorkingPoints: {
    data: {
      nrPagini: 1,
      nrTotal: 1,
      workingPoints: [],
    },
    isLoadingScheduleWorkingPoints: false,
    isErrorScheduleWorkingPoints: false,
    errorMessageScheduleWorkingPoints: "",
  },
  scheduleWorkingPointsDetails: {
    data: [],
    isLoadingScheduleWorkingPointsDetails: false,
    isErrorScheduleWorkingPointsDetails: false,
    errorMessageScheduleWorkingPointsDetails: "",
  },
  scheduleWorkingRoutes: {
    data: {
      nrPagini: 1,
      nrTotal: 1,
      routes: [],
    },
    isLoadingScheduleWorkingRoutes: false,
    isErrorScheduleWorkingRoutes: false,
    errorMessageScheduleWorkingRoutes: "",
  },
  scheduleWorkingRoutesDetails: {
    data: [],
    isLoadingScheduleWorkingRoutesDetails: false,
    isErrorScheduleWorkingRoutesDetails: false,
    errorMessageScheduleWorkingRoutesDetails: "",
  },
  workingRoutesNodes: {
    data: [],
    isLoadingWorkingRoutesNodes: false,
    isErrorWorkingRoutesNodes: false,
    errorMessageWorkingRoutesNodes: "",
  },
  incompleteInvoices: {
    data: [],
    isLoadingIncompleteInvoices: false,
    isErrorIncompleteInvoices: false,
    errorMessageIncompleteInvoices: "",
  },
  plasticBoxesScanned: {
    data: [],
  },
  monitoringPlasticBoxes: {
    data: {
      cutiiPlastic: [],
      nrPagini: 1,
      nrTotal: 1,
    },
    isLoadingMonitoringPlasticBoxes: false,
    isErrorMonitoringPlasticBoxes: false,
    errorMessageMonitoringPlasticBoxes: "",
  },
};

const expeditionReducer = createSlice({
  name: "expedition",
  initialState: initialExpeditionState,
  reducers: {
    setAddPlasticBox: (
      state,
      action: {
        payload: PlasticBoxesListInterface;
      },
    ) => {
      const plasticBoxesList = state.plasticBoxesScanned.data;
      const newBox = action.payload;

      const alreadyInList = plasticBoxesList.some(
        (box) => box.sn === newBox.sn,
      );

      if (alreadyInList) {
        return state;
      }

      return {
        ...state,
        plasticBoxesScanned: {
          data: [...plasticBoxesList, newBox],
        },
      };
    },
    resetPlasticBoxesScanned: (state) => {
      return {
        ...state,
        plasticBoxesScanned: {
          ...initialExpeditionState.plasticBoxesScanned,
        },
      };
    },
    resetScheduleWorkingPointsDetails: (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
        },
      };
    },
    resetScheduleWorkingRoutesDetails: (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // get schedule working points start
    builder.addCase(getScheduleWorkingPoints.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...initialExpeditionState.scheduleWorkingPoints,
          isLoadingScheduleWorkingPoints: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoadingScheduleWorkingPoints: false,
          errorMessageScheduleWorkingPoints: action.payload.message,
          isErrorScheduleWorkingPoints: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoadingScheduleWorkingPoints: false,
          data: action.payload,
        },
      };
    });
    // get schedule working points end
    // get schedule working points details start
    // get  schedule working routes details start
    builder.addCase(getScheduleWorkingPointsDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
          isLoadingScheduleWorkingPointsDetails: true,
        },
      };
    });
    builder.addCase(
      getScheduleWorkingPointsDetails.rejected,
      (state, action: any) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoadingScheduleWorkingPointsDetails: false,
            errorMessageScheduleWorkingPointsDetails: action.payload.message,
            isErrorScheduleWorkingPointsDetails: true,
          },
        };
      },
    );
    builder.addCase(
      getScheduleWorkingPointsDetails.fulfilled,
      (state, action) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoadingScheduleWorkingPointsDetails: false,
            data: action.payload,
          },
        };
      },
    );
    // end get schedule working points details end
    // get schedule working routes start
    builder.addCase(getScheduleWorkingRoutes.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...initialExpeditionState.scheduleWorkingRoutes,
          isLoadingScheduleWorkingRoutes: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingRoutes.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoadingScheduleWorkingRoutes: false,
          errorMessageScheduleWorkingRoutes: action.payload.message,
          isErrorScheduleWorkingRoutes: true,
        },
      };
    });

    builder.addCase(getScheduleWorkingRoutes.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoadingScheduleWorkingRoutes: false,
          data: action.payload,
        },
      };
    });
    // get schedule working routes end
    // get  schedule working routes details start
    builder.addCase(getScheduleWorkingRoutesDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
          isLoadingScheduleWorkingRoutesDetails: true,
        },
      };
    });
    builder.addCase(
      getScheduleWorkingRoutesDetails.rejected,
      (state, action: any) => {
        return {
          ...state,
          scheduleWorkingRoutesDetails: {
            ...state.scheduleWorkingRoutesDetails,
            isLoadingScheduleWorkingRoutesDetails: false,
            errorMessageScheduleWorkingRoutesDetails: action.payload.message,
            isErrorScheduleWorkingRoutesDetails: true,
          },
        };
      },
    );
    builder.addCase(
      getScheduleWorkingRoutesDetails.fulfilled,
      (state, action) => {
        return {
          ...state,
          scheduleWorkingRoutesDetails: {
            ...state.scheduleWorkingRoutesDetails,
            isLoadingScheduleWorkingRoutesDetails: false,
            data: action.payload,
          },
        };
      },
    );
    //  get  schedule working routes details end
    // get working routes nodes start
    builder.addCase(getWorkingRoutesNodes.pending, (state) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...initialExpeditionState.workingRoutesNodes,
          isLoadingWorkingRoutesNodes: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesNodes.rejected, (state, action: any) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoadingWorkingRoutesNodes: false,
          errorMessageWorkingRoutesNodes: action.payload.message,
          isErrorWorkingRoutesNodes: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesNodes.fulfilled, (state, action) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoadingWorkingRoutesNodes: false,
          data: action.payload,
        },
      };
    });
    // get working routes nodes end

    // incomplete invoices start
    builder.addCase(getIncompleteInvoices.pending, (state) => {
      return {
        ...state,
        incompleteInvoices: {
          ...initialExpeditionState.incompleteInvoices,
          isLoadingIncompleteInvoices: true,
        },
      };
    });
    builder.addCase(getIncompleteInvoices.rejected, (state, action: any) => {
      return {
        ...state,
        incompleteInvoices: {
          ...state.incompleteInvoices,
          isLoadingIncompleteInvoices: false,
          errorMessageIncompleteInvoices: action.payload.message,
          isErrorIncompleteInvoices: true,
        },
      };
    });
    builder.addCase(getIncompleteInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        incompleteInvoices: {
          ...state.incompleteInvoices,
          isLoadingIncompleteInvoices: false,
          data: action.payload,
        },
      };
    });
    // incomplete invoices end

    // post monitoring plastic boxes start
    builder.addCase(postMonitoringPlasticBoxes.pending, (state) => {
      return {
        ...state,
        monitoringPlasticBoxes: {
          ...initialExpeditionState.monitoringPlasticBoxes,
          isLoadingMonitoringPlasticBoxes: true,
        },
      };
    });
    builder.addCase(
      postMonitoringPlasticBoxes.rejected,
      (state, action: any) => {
        return {
          ...state,
          monitoringPlasticBoxes: {
            ...state.monitoringPlasticBoxes,
            isLoadingMonitoringPlasticBoxes: false,
            errorMessageMonitoringPlasticBoxes: action.payload.message,
            isErrorMonitoringPlasticBoxes: true,
          },
        };
      },
    );
    builder.addCase(postMonitoringPlasticBoxes.fulfilled, (state, action) => {
      return {
        ...state,
        monitoringPlasticBoxes: {
          ...state.monitoringPlasticBoxes,
          isLoadingMonitoringPlasticBoxes: false,
          data: action.payload,
        },
      };
    });
    // post monitoring plastic boxes end
  },
});

export const {
  resetScheduleWorkingPointsDetails,
  resetScheduleWorkingRoutesDetails,
  setAddPlasticBox,
  resetPlasticBoxesScanned,
} = expeditionReducer.actions;

export default expeditionReducer.reducer;
