import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getReceivedProducts = createAsyncThunk(
  "/inventory/receivedProducts",
  async (
    filters: {
      page?: number;
      perPage?: number;
      startDate?: string;
      endDate?: string;
      numeProdus?: string;
      numeFurnizor?: string;
      lot?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { page, perPage, startDate, endDate } = filters;

    try {
      const { data } = await apiRequest(
        `/produseReceptionate?page=${page ?? 1}&per_page=${
          perPage ?? 5
        }&startDate=${startDate}&endDate=${endDate}&numeProdus=${
          filters.numeProdus ?? ""
        }&numeFurnizor=${filters.numeFurnizor ?? ""}&lot=${filters.lot ?? ""}
            `,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/receivedProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

interface GetDepositStocksInterface {
  page?: number;
  perPage?: number;
  building?: string;
  row?: string;
  column?: string;
  shelf?: string;
  cell?: string;
  alley?: string;
  island?: string;
  alias?: string;
  productCode?: string;
  productName?: string;
  lot?: string;
}

export const getDepositStocks = createAsyncThunk(
  "/inventory/depositStocks",
  async (filters: GetDepositStocksInterface, { rejectWithValue, dispatch }) => {
    const {
      page = "",
      perPage = "",
      alias = "",
      alley = "",
      building = "",
      cell = "",
      column = "",
      island = "",
      productCode = "",
      productName = "",
      row = "",
      shelf = "",
      lot = "",
    } = filters;

    try {
      const { data } = await apiRequest(
        `/stocDepozit?page=${page ?? 1}&per_page=${
          perPage ?? 5
        }&cladire=${building}&rand=${row}&coloana=${column}&polita=${shelf}&celula=${cell}&alee=${alley}&insula=${island}&alias=${alias}&codProdus=${productCode}&numeProdusErp=${productName}&lot=${lot}`,
        "GET",
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/depositStocks", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDepositStocksTransfer = createAsyncThunk(
  "/inventory/transferMarfaDepozit",
  async (
    receptionData: {
      product: object;
      filters: GetDepositStocksInterface;
      deposits: any[];
      callBack?: () => void;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { filters, product, deposits, callBack } = receptionData;
    try {
      const transferObject = {
        product,
        deposits,
      };
      await apiRequest(`/transferMarfaDepozit`, "POST", transferObject);
      dispatch(getDepositStocks(filters));
      callBack && callBack();
    } catch (err: any) {
      console.warn("/inventory/transferMarfaDepozit", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteDepositStockProduct = createAsyncThunk(
  "/inventory/deleteProduct",
  async (
    transferData: {
      filters: GetDepositStocksInterface;
      idActivCel: string | number;
    },
    { rejectWithValue, dispatch },
  ) => {
    const { idActivCel, filters } = transferData;
    try {
      await apiRequest(`/deleteStocActivCel?idActivCel=${idActivCel}`, "GET");
      dispatch(getDepositStocks(filters));
    } catch (err: any) {
      console.warn("/inventory/deleteProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditDepositStockProduct = createAsyncThunk(
  "/inventory/editProduct",
  async (
    transferData: {
      filters: GetDepositStocksInterface;
      params: {
        idActivCel: number;
        codProdus: string;
        lot: string;
        dataExpirare: string;
        cantitate: number;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    const { params, filters } = transferData;
    try {
      await apiRequest("/editStocActivCel", "POST", params);
      dispatch(getDepositStocks(filters));
    } catch (err: any) {
      console.warn("/inventory/editProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postGetProducts = createAsyncThunk(
  "/inventory/postGetProducts",
  async (
    transferData: {
      page: number;
      per_page: number;
      numeProdusErp?: string;
      codProdusErp?: string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("/produseWms", "POST", transferData);
      return data;
    } catch (err: any) {
      console.warn("/inventory/postGetProducts", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getSelectProductWms = createAsyncThunk(
  "/inventory/getSelectProductWms",
  async (idProdus: number, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/selectProdusWms/${idProdus}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/inventory/getSelectProductWms", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postEditProdusWms = createAsyncThunk(
  "/inventory/postEditProdusWms",
  async (transferData: any, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest("/editProdusWms", "POST", transferData);
      return data;
    } catch (err: any) {
      console.warn("/inventory/postEditProdusWms", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeactivateProductWms = createAsyncThunk(
  "/inventory/deactivateProduct",
  async (
    transferData: {
      id: number | string;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "/deactivateProduct",
        "POST",
        transferData,
      );
      return data;
    } catch (err: any) {
      console.warn("/inventory/deactivateProduct", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
