import React from "react";

import { useAppSelector } from "../../../../../redux/hooks";
import Text from "../../../../../styleguide/Text";
import ContentCard from "../../../../../components/misc/ContentCard";
import { firstLetterToUpperCase } from "../../../../../helpers/stringFormater";

function VerifyCommandHeader() {
  const {
    picking: {
      verifyCommand: { verificationZone },
      verifyCommandProducts: {
        data: verifyCommandProductsData,
        isLoadingVerifyCommandProducts,
      },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const commandNr = verifyCommandProductsData.list[0]?.Op;

  return (
    <ContentCard
      className="text-center"
      isLoading={isLoadingVerifyCommandProducts}>
      <div className="d-flex justify-content-center mb-4 w-100">
        <Text className="text-center me-7 w-25" component={"span"}>
          Colectat de
          <Text variant="h2">
            {firstLetterToUpperCase(verifyCommandProductsData.userName)}
          </Text>
        </Text>
        <Text className="text-center me-7  w-25" component={"span"}>
          Zona verificare
          <Text variant="h2">{verificationZone}</Text>
        </Text>
        <Text className="text-center me-7  w-25">
          Numar comanda
          <Text variant="h3">{commandNr}</Text>
        </Text>
      </div>
      <div className="d-flex justify-content-center w-100">
        <Text className="text-center me-7 w-25">
          Cumparator
          <Text variant="h3">{verifyCommandProductsData.cumparator}</Text>
        </Text>
        <Text className="text-center me-7  w-25" component={"span"}>
          Ruta
          <Text variant="h2">{verifyCommandProductsData.ruta}</Text>
        </Text>
        <Text className="text-center me-7 w-25">
          Punct de livrare
          <Text variant="h3">{verifyCommandProductsData.pctDeLivr}</Text>
        </Text>
      </div>
    </ContentCard>
  );
}

export default VerifyCommandHeader;
