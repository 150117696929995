import { Link } from "react-router-dom";

import Text from "../../styleguide/Text";
import { isDevelop } from "../../helpers/apiHelper";
import { toAbsoluteUrl } from "../../helpers/assetHelper";

function NavbarLogo({
  withEnvIndicator,
  style,
}: {
  withEnvIndicator?: boolean;
  style?: React.CSSProperties;
}) {
  return (
    <div
      className="h-100 d-flex align-items-center"
      style={{ marginLeft: 10, ...style }}>
      <Link className="text-reset h-100" to="/" tabIndex={-1}>
        <img src={toAbsoluteUrl("/logo.svg")} alt="img" />
      </Link>
      {withEnvIndicator && isDevelop && (
        <Text variant="h1" className="ms-3">
          Develop
        </Text>
      )}
    </div>
  );
}

export default NavbarLogo;
