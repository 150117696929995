import objectParsers from "../../utils/objectParsers";

export const tableLocalGlobalSearch = (
  tableValues?: number[] | string[],
  tableSearch?: number | string,
  showItemsWhenEmpty = true,
) => {
  if (tableValues && tableSearch) {
    return tableValues?.some((tableValue) => {
      return tableValue
        ?.toString()
        .toLowerCase()
        .includes(tableSearch.toString().toLowerCase());
    });
  }
  return showItemsWhenEmpty;
};

export const tableLocalCategorySearch = (
  tableData?: any[],
  tableSearch?: object,
  showItemsWhenEmpty = true,
) => {
  if (tableData && tableSearch) {
    const tableDataKeys = objectParsers.getKeys(tableData[0]);

    const tableSearchToArray = objectParsers
      .transformObjectToArray(tableSearch)
      .filter((item) => item.value && tableDataKeys.includes(item?.key));

    const filteredTableData = tableData.filter((tableItem) => {
      return tableSearchToArray.every((tableSearchItem) => {
        const tableObjectValue = tableItem[tableSearchItem?.key] ?? "";
        if (Array.isArray(tableObjectValue) && tableObjectValue.length) {
          const objectKey = Object.keys(tableObjectValue[0])[0];
          const objectValues = tableObjectValue.map((item) => item[objectKey]);

          return objectValues.some((value) =>
            value
              ?.toString()
              .toLowerCase()
              .includes(tableSearchItem?.value?.toString().toLowerCase()),
          );
        }

        if (!tableObjectValue) {
          return false;
        }

        return tableObjectValue
          ?.toString()
          .toLowerCase()
          .includes(tableSearchItem.value.toString().toLowerCase());
      });
    });

    return filteredTableData;
  }
  if (showItemsWhenEmpty) {
    return tableData ?? [];
  }
  return [];
};
