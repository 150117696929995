import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import ContentCard from "../../../components/misc/ContentCard";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useDebounce } from "../../../hooks/useDebounce";

import AutocompleteDropdown from "../../../components/misc/Autocomplete";
import CustomButton from "../../../components/CustomButton";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../helpers/apiHelper";

import {
  getReturnInvoices,
  postCreateReturnTask,
} from "../../../redux/api/returning.api";

function CreateReturn() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [selectedInvoices, setSelectedInvoices] = useState<any[]>([]);

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const {
    returning: {
      returnInvoices: { data, isLoadingReturnInvoices },
    },
  } = useAppSelector((state) => ({
    returning: state.returning,
  }));

  useEffect(() => {
    if (debouncedSearchValue.toString().length) {
      dispatch(getReturnInvoices(debouncedSearchValue));
    }
  }, [dispatch, debouncedSearchValue]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleCreateReturnTask = async () => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postCreateReturnTask({
        invoices: selectedInvoices,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postCreateReturnTask) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postCreateReturnTask) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  return (
    <ContentCard cardTitle={"Creeaza task retur"}>
      <AutocompleteDropdown
        id="autocomplete"
        labelTag="act"
        placeholder="Cauta facturi..."
        data={data}
        isLoadingReturnInvoices={isLoadingReturnInvoices}
        setSearchValue={setSearchValue}
        setSelectedInvoices={setSelectedInvoices}
      />

      <div className="d-flex justify-content-between mt-6">
        <CustomButton
          variant="outlined"
          className="me-2"
          loading={isLoading}
          onClick={handleGoBack}>
          Anuleaza
        </CustomButton>
        <CustomButton
          variant="contained"
          className="me-2"
          loading={isLoading}
          onClick={handleCreateReturnTask}>
          Creeaza
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default CreateReturn;
