import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const getMediGroupsRaports = createAsyncThunk(
  "/raports/mediGroups",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: responseData } = await apiRequest("/raportGrupaMed");
      return responseData;
    } catch (err: any) {
      console.warn("/raports/mediGroups", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const getProviderReceptionsRaports = createAsyncThunk(
  "/raports/getProviderReceptionsRaports",
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data: responseData } = await apiRequest(
        "/raportReceptiiFurnizor",
      );
      return responseData;
    } catch (err: any) {
      console.warn("/raports/getProviderReceptionsRaports", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
