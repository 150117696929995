import React, { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import {
  getScheduleWorkingRoutesDetails,
  getWorkingRoutesNodes,
  postScheduleRoutes,
} from "../../../redux/api/expedition.api";
import { resetScheduleWorkingRoutesDetails } from "../../../redux/reducer/expedition.reducer";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Text from "../../../styleguide/Text";
import ConfigWorkingDays, {
  AddAllDaysItemScheduleInterface,
} from "../components/ConfigWorkingDays";
import SelectableItem from "../../../components/misc/SelectableItem/SelectableItem";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import CustomButton from "../../../components/CustomButton";

function ExpeditionScheduleWorkingRoutesOrganise() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { routeParams } = useRoutesHelper();
  const id = routeParams?.id;

  const {
    expedition: {
      scheduleWorkingRoutesDetails: { data: scheduleWorkingRoutesDetailsData },
      workingRoutesNodes: { data: workingRoutesNodesData },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [isInternode, setIsInternode] = useState(false);
  const [selectedNode, setSelectedNode] = useState<DropdownValue | null>(null);
  const [selectedDays, setSelectedDays] = useState<
    AddAllDaysItemScheduleInterface[]
  >([]);

  useEffect(() => {
    if (id) {
      dispatch(getWorkingRoutesNodes());
      dispatch(getScheduleWorkingRoutesDetails({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (
      scheduleWorkingRoutesDetailsData.length &&
      workingRoutesNodesData.length
    ) {
      if (scheduleWorkingRoutesDetailsData[0]?.internode) {
        const isDefaultInternode =
          scheduleWorkingRoutesDetailsData[0].internode;

        setIsInternode(isDefaultInternode);
      }

      const defaultNodeId = scheduleWorkingRoutesDetailsData[0]?.nodId;

      const defaultNode = workingRoutesNodesData.find(
        (item) => item.nodId === defaultNodeId,
      );

      if (defaultNode) {
        setSelectedNode({
          label: defaultNode.nodNume,
          id: defaultNode.nodId,
        });
      }
    }
  }, [scheduleWorkingRoutesDetailsData, workingRoutesNodesData]);

  useEffect(() => {
    return () => {
      dispatch(resetScheduleWorkingRoutesDetails());
    };
  }, [dispatch]);

  const onlyActiveDays = selectedDays.filter((item) => item.isActive);

  const handleSave = () => {
    if (!selectedNode) {
      return;
    }

    dispatch(
      postScheduleRoutes({
        transferData: {
          activeWorkingDays: onlyActiveDays,
          internode: isInternode,
          id: id,
          selectedNode,
        },
        callback: () => {
          navigate(-1);
        },
      }),
    );
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const workingNodesDropdownValues = useMemo(
    () =>
      workingRoutesNodesData?.map((item) => ({
        label: item.nodNume,
        id: item.nodId,
      })),
    [workingRoutesNodesData],
  );

  return (
    <ContentCard>
      <div className="d-flex align-items-center justify-content-between w-100">
        <Text variant="h1" className="mb-5">{`Organizare ruta ${id}`}</Text>
        <SelectableItem
          className="px-5"
          name={"internode"}
          onClick={() => {
            setIsInternode((prev) => !prev);
          }}
          checked={isInternode}
          type="checkbox"
          title="Internod"
        />
      </div>

      <Dropdown
        className="my-4"
        title="Selecteaza nodul"
        data={workingNodesDropdownValues}
        singleValue={selectedNode}
        setSingleValue={setSelectedNode}
      />

      <ConfigWorkingDays
        itemStyle={{
          minHeight: 80,
        }}
        alreadyCheckedDays={scheduleWorkingRoutesDetailsData}
        withoutTime={isInternode}
        setSelectedDays={setSelectedDays}
      />

      <div className="d-flex align-items-center justify-content-between p-5">
        <CustomButton variant="outlined" onClick={handleCancel}>
          <Text>Anuleaza</Text>
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => handleSave()}
          disabled={onlyActiveDays.length === 0 || !selectedNode}>
          <Text>Salveaza</Text>
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default ExpeditionScheduleWorkingRoutesOrganise;
