import React, { useEffect, useState } from "react";
import clsx from "clsx";
import Text from "../../../styleguide/Text";
import ModalWrapper from "../../Modal/ModalWrapper";
import TextInput from "../TextInput";
import CustomListItem, { CustomItemPress } from "../CustomListItem";
import { useDebounce } from "../../../hooks/useDebounce";

export interface ModalListItem {
  id: number | string;
  label: string | number;
  backgroundColor?: "info" | "success" | "warning" | "danger" | "primary";
}

function ModalList({
  items,
  modalActions,
  label,
  selectedItem,
  setSelectedItem,
  closeAfterSelect = true,
  searchPlaceholder,
  modalTitle,
}: {
  items: ModalListItem[];
  modalActions: (value: { search: string }) => void;
  label?: string;
  selectedItem?: ModalListItem;
  setSelectedItem?: React.Dispatch<React.SetStateAction<ModalListItem | null>>;
  closeAfterSelect?: boolean;
  searchPlaceholder?: string;
  modalTitle?: string;
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchValue, setSearchValue] = useState<any>("");

  const debouncedSearchValue = useDebounce(searchValue, 500);

  const classes = clsx(
    "form-control form-control-solid mb-3 mb-lg-0 p-4 text-start",
  );

  useEffect(() => {
    if (debouncedSearchValue !== null && debouncedSearchValue !== undefined) {
      const debouncedSearchValueToString = debouncedSearchValue?.toString();
      modalActions({ search: debouncedSearchValueToString });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleClose = () => {
    setIsModalOpen(false);
    setSearchValue("");
  };

  const handleButtonPress = (e: any) => {
    e.preventDefault();
    setIsModalOpen(true);
  };

  const handleSelectItem = (item: CustomItemPress) => {
    if (item.id) {
      setSelectedItem &&
        setSelectedItem({
          id: item.id,
          label: item.name,
        });
    }
    closeAfterSelect && setIsModalOpen(false);
  };

  return (
    <>
      <ModalWrapper
        handleClose={handleClose}
        show={isModalOpen}
        title={modalTitle}>
        <TextInput
          name="searchProduct"
          placeholder={searchPlaceholder}
          value={searchValue}
          onChange={setSearchValue}
        />
        {selectedItem && (
          <CustomListItem
            withIcon
            backgroundColor={"success"}
            infoText={{ text: "Selectat" }}
            name={selectedItem.label}
          />
        )}
        {items?.map(({ id, label, backgroundColor }) => {
          return (
            <CustomListItem
              key={id}
              id={id}
              withIcon
              name={label}
              onClick={handleSelectItem}
              backgroundColor={backgroundColor}
            />
          );
        })}
      </ModalWrapper>
      <div className="mb-7 w-100">
        {label && (
          <Text
            variant="body1"
            isBold
            gutterBottom
            className="form-label fs-6 fw-bolder text-dark">
            {label}
          </Text>
        )}
        <button className={classes} onClick={handleButtonPress}>
          {selectedItem ? selectedItem.label : "Selecteaza"}
        </button>
      </div>
    </>
  );
}

export default ModalList;
