import { useState, useEffect } from "react";
import { KTSVG } from "../../../helpers/KTSVG";
import { useDebounce } from "../../../hooks/useDebounce";

const TableGlobalSearch = ({ setTableGlobalSearch }: any) => {
  const [searchTerm, setSearchTerm] = useState<string>("");
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 200);
  // Effect for API call
  useEffect(
    () => {
      if (debouncedSearchTerm !== undefined && searchTerm !== undefined) {
        // updateState({search: debouncedSearchTerm, ...initialQueryState})
        setTableGlobalSearch && setTableGlobalSearch(debouncedSearchTerm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  return (
    <div className="card-title fw-bolder text-muted bg-light w-100 p-3 rounded">
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        <KTSVG
          path="/media/icons/duotune/general/gen021.svg"
          className="svg-icon-1 position-absolute ms-2"
        />
        <input
          type="text"
          data-kt-user-table-filter="search"
          className="form-control form-control-solid bg-white py-2 ps-10"
          placeholder="Cauta..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </div>
      {/* end::Search */}
    </div>
  );
};

export default TableGlobalSearch;
