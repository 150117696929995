import { useEffect, useMemo, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { PackingBoxesListInterface } from "../../../../redux/interfaces/picking.interface";
import {
  setPackingBoxes,
  setVerifyCommand,
} from "../../../../redux/reducer/picking.reducer";
import { postValidateVerification } from "../../../../redux/api/picking.api";

import withVerifySuccessModal from "../hoc/withVerifySuccessModal";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";
import FormButtons from "../../../../components/misc/FormButtons";

function BoxesNumber({
  handleSuccessModal,
}: {
  handleSuccessModal?: () => void;
}) {
  const dispatch = useAppDispatch();
  const {
    picking: {
      packingBoxes: { data: packingBoxesData, isLoadingPackingBoxes },
      verifyCommandProducts: { data: verifyCommandProductsData },
      verifyCommand: { verificationZone },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const [boxesData, setBoxesData] = useState<PackingBoxesListInterface[]>([]);
  const [isLoadingSave, setIsLoadingSave] = useState(false);

  const withPlasticBoxes = useMemo(
    () =>
      boxesData.some(
        (box) => box.tip === 3 && box.cantitate && box.cantitate > 0,
      ),
    [boxesData],
  );

  useEffect(() => {
    if (packingBoxesData && verifyCommandProductsData.pctDeLivr) {
      const addQuantity = packingBoxesData.map((box) => {
        return {
          ...box,
          cantitate: null,
        };
      });
      setBoxesData(addQuantity);
    }
  }, [packingBoxesData, verifyCommandProductsData]);

  const handleChangeBoxesQuantity = (
    value: string | number | null,
    idCutie: number,
  ) => {
    const parseValue = value ? Number(value) : null;

    setBoxesData((prev) =>
      prev.map((box) => {
        if (box.idCutie === idCutie) {
          return {
            ...box,
            cantitate: parseValue,
          };
        }
        return box;
      }),
    );
  };

  const handleValidateCommand = async () => {
    if (!verificationZone) {
      return;
    }
    const commandNr = verifyCommandProductsData?.list[0]?.Op;

    const transferData = {
      cellScanned: verificationZone,
      cutii: boxesData,
      commandNumber: commandNr,
    };

    if (withPlasticBoxes) {
      dispatch(setPackingBoxes(boxesData));
      dispatch(setVerifyCommand({ step: 2 }));
      return;
    }

    setIsLoadingSave(true);

    const resultAction = await dispatch(
      postValidateVerification({
        params: transferData,
      }),
    );

    if (
      checkEndpointStatus(resultAction, postValidateVerification) !==
      endpointStatus.pending
    ) {
      setIsLoadingSave(false);
    }

    if (
      checkEndpointStatus(resultAction, postValidateVerification) ===
      endpointStatus.fulfilled
    ) {
      setIsLoadingSave(false);
      handleSuccessModal && handleSuccessModal();
    }
  };

  const disableSaveButton = useMemo(() => {
    return (
      !boxesData.some((box) => box.cantitate && box.cantitate > 0) ||
      isLoadingSave
    );
  }, [boxesData, isLoadingSave]);

  const getNextButtonLabel = () => {
    if (withPlasticBoxes) {
      return "Urmatorul";
    }
    return isLoadingSave ? "Se printeaza facturile" : "Salveaza";
  };

  return (
    <>
      <ContentCard
        cardTitle={"Cutii ambalare"}
        isLoading={isLoadingPackingBoxes}>
        <div>
          {boxesData?.map(
            ({ idCutie, nume, dimensiuni, greutate, cantitate }) => {
              return (
                <div key={`${idCutie}`}>
                  <TextInput
                    label={`${nume} (${dimensiuni} ${greutate})`}
                    name={`${idCutie}+${nume}`}
                    id={`${idCutie}+${nume}`}
                    value={cantitate}
                    type="number"
                    onChange={(value) =>
                      handleChangeBoxesQuantity(value, idCutie)
                    }
                  />
                </div>
              );
            },
          )}
        </div>
      </ContentCard>
      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel={getNextButtonLabel()}
        nextButtonDisabled={disableSaveButton || isLoadingSave}
        handleGoBack={() => {
          dispatch(
            setVerifyCommand({
              step: 0,
            }),
          );
        }}
        handleGoNext={handleValidateCommand}
      />
    </>
  );
}

export default withVerifySuccessModal(BoxesNumber);
