import { useEffect, useState } from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { useNavigate } from "react-router-dom";

import ContentCard from "../../../components/misc/ContentCard";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import {
  getReturnInvoicesDetails,
  postCreatePdfRetur,
} from "../../../redux/api/returning.api";
import { ReturnInvoicesDataInterface } from "../../../redux/interfaces/returning.interface";
import SelectProducts from "./components/SelectProducts";
import FormDetails from "./components/FormDetails";
import ProductsQuantity from "./components/ProductsQuantity";

export const returnReasonConstants = [
  {
    name: "productDamaged",
    label: "Produs neconform",
  },
  {
    name: "productExpired",
    label: "Termen de valabilitate <1an",
  },
  {
    name: "productWronglySent",
    label: "Comanda eronata",
  },
  {
    name: "other",
    label: "Altele",
    value: "",
  },
];

const steps = ["Produse", "Cantiate produst", "Detalii"];

function CreateReturningForm() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { routeParams } = useRoutesHelper();
  const returnId = routeParams?.id;

  const {
    returning: {
      returnInvoicesDetails: {
        data: returnInvoicesDetailsData,
        isLoadingReturnInvoicesDetails,
      },
    },
  } = useAppSelector((state) => ({
    returning: state.returning,
  }));

  const [currentStep, setCurrentStep] = useState(0);

  const [fieldValue, setFieldValue] = useState<{
    returnToDelivery: boolean;
    packageLeftTheWerehouse: boolean;
    reason: {
      name: string;
      label: string;
      value?: string;
    };
    selectedProducts: ReturnInvoicesDataInterface[];
  }>({
    returnToDelivery: false,
    packageLeftTheWerehouse: false,
    reason: returnReasonConstants[1],
    selectedProducts: [],
  });

  useEffect(() => {
    if (returnId) {
      dispatch(getReturnInvoicesDetails(returnId));
    }
  }, [dispatch, returnId]);

  useEffect(() => {
    if (returnInvoicesDetailsData) {
      const { facturi, packageLeftTheWerehouse, reason, returnToDelivery } =
        returnInvoicesDetailsData;

      let initialReason =
        returnReasonConstants.find(
          (returnReason) => returnReason.name === reason.name,
        ) || returnReasonConstants[1];

      initialReason = {
        ...initialReason,
        value: reason.value,
      };

      setFieldValue((prev) => {
        const fieldValueInitialData = {
          returnToDelivery: returnToDelivery,
          packageLeftTheWerehouse,
          reason: initialReason,
          selectedProducts: facturi.flatMap((factura) => factura.pozitii),
        };

        return { ...prev, ...fieldValueInitialData };
      });
    }
  }, [returnInvoicesDetailsData]);

  const handleSetSelectedProducts = (
    products: ReturnInvoicesDataInterface[],
  ) => {
    setFieldValue((prev) => ({ ...prev, selectedProducts: products }));
  };

  const handleCurrentStep = (callBack: (value: number) => number) => {
    const step = callBack(currentStep);

    if (step < 0) {
      navigate(-1);
      return;
    }
    if (step > steps.length - 1) {
      dispatch(postCreatePdfRetur({ ...fieldValue, returnId }));
      navigate(-1);
      return;
    }

    setCurrentStep(step);
  };

  return (
    <ContentCard isLoading={isLoadingReturnInvoicesDetails || !returnId}>
      <Stepper activeStep={currentStep} alternativeLabel className="mb-6">
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      {currentStep === 0 && (
        <SelectProducts
          handleCurrentStep={handleCurrentStep}
          returnInvoicesDetailsData={returnInvoicesDetailsData}
          handleSetSelectedProducts={handleSetSelectedProducts}
        />
      )}

      {currentStep === 1 && (
        <ProductsQuantity
          handleCurrentStep={handleCurrentStep}
          selectedProducts={fieldValue.selectedProducts}
          handleSetSelectedProducts={handleSetSelectedProducts}
        />
      )}

      {currentStep === 2 && (
        <FormDetails
          handleCurrentStep={handleCurrentStep}
          fieldValue={fieldValue}
          setFieldValue={setFieldValue}
        />
      )}
    </ContentCard>
  );
}

export default CreateReturningForm;
