import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { ReturningListDataInterface } from "../../../../redux/interfaces/returning.interface";
import { dateToString } from "../../../../utils/dateAndTime";

const receptionStatusConstants = [
  { value: 1, label: "Active" },
  { value: 2, label: "Finalizate" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = receptionStatusConstants.find(
    (item) => item.value === parseInt(status),
  );

  return `${currentStatus?.value ?? ""}. ${currentStatus?.label ?? ""}`;
};

export const returningTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Id",
    minW: 125,
  },
  {
    label: "Punct de livrare",
    value: "deliveryPoint",
    minW: 200,
    withSearch: true,
  },
  {
    label: "Data creare",
    value: "createdAt",
    minW: 125,
  },
  {
    label: "Stadiu",
    value: "status",
    minW: 125,
    withFilter: receptionStatusConstants,
  },
];

export const getReturningTableData: any = (
  returningList: ReturningListDataInterface[],
) =>
  returningList?.map((returning) => {
    return {
      id: returning.idReceptieRetur,
      crudData: returning,
      data: [
        {
          title: returning.idReceptieRetur,
        },
        {
          title: returning.pctDeLivr,
        },
        {
          badges: [
            {
              badgeText: dateToString(returning.createdAt),
              badgeColor: "light-info",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(returning.status),
              badgeColor: "light-primary",
            },
          ],
        },
      ],
    };
  });
