import { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

import ContentCard from "../../../../components/misc/ContentCard";
import TextInput from "../../../../components/misc/TextInput";
import CustomButton from "../../../../components/CustomButton";
import Text from "../../../../styleguide/Text";
import { calculatePercentFromNumber } from "../../../../utils/numericHelpers";
import SelectableChecker from "../../../../components/misc/SelectableItem/SelectableChecker";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { useNavigate } from "react-router-dom";
import { clearSelectProductWms } from "../../../../redux/reducer/inventory.reducer";
import {
  getSelectProductWms,
  postEditProdusWms,
} from "../../../../redux/api/inventory.api";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

interface initialValuesInterface {
  percent: number | null;
  lowLimit: number | null;
  columnNumber: number | null;
}

const editLimitSchema = Yup.object().shape({
  percent: Yup.number()
    .typeError("Trebuie sa adaugati un procent")
    .required("Trebuie sa adaugati un procent")
    .test(
      "Is positive?",
      "Procentul trebuie sa fie mai mare ca 0",
      (value) => (value || 0) > 0,
    ),
  lowLimit: Yup.number()
    .typeError("Trebuie sa adaugati o limita minima")
    .required("Trebuie sa adaugati o limita minima")
    .test(
      "Is positive?",
      "Limita minima trebuie sa fie mai mare ca 0",
      (value) => {
        return (value || 0) > 0;
      },
    ),
  columnNumber: Yup.number()
    .typeError("Trebuie sa adaugati o limita maxima")
    .required("Trebuie sa adaugati o limita maxima")
    .test(
      "Is positive?",
      "Limita maxima trebuie sa fie mai mare ca 0",
      (value) => (value || 0) > 0,
    ),
});

const initialValues: initialValuesInterface = {
  percent: null,
  lowLimit: null,
  columnNumber: null,
};

function NomenclatureEditLimit() {
  const navigate = useNavigate();

  const { routeParams } = useRoutesHelper();
  const { idProdus } = routeParams || {};

  const dispatch = useAppDispatch();

  const {
    inventory: {
      selectProductWms: { data: productDetailsData, isLoadingSelectProductWms },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const [isLoading, setIsLoading] = useState(false);
  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);
  const [isDefault, setIsDefault] = useState(true);

  useEffect(() => {
    if (idProdus) {
      dispatch(getSelectProductWms(idProdus));
    }
  }, [dispatch, idProdus]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectProductWms());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productDetailsData) {
      setIsDefault(!!productDetailsData.defaultLimit);
      setFormikValues((value) => ({
        ...value,
        percent: productDetailsData?.procent || null,
        lowLimit: productDetailsData?.lowLimit || null,
        columnNumber: productDetailsData?.nrPicioare || null,
      }));
    }
  }, [productDetailsData]);

  const handleEditPercent = (value: any) => {
    const percentValue = value ? Number(value) : null;

    if (percentValue && percentValue > 100) {
      return;
    }

    const getMinLimitValue = () => {
      if (
        formikValues?.columnNumber &&
        productDetailsData?.maxLimit &&
        percentValue
      ) {
        return (
          calculatePercentFromNumber(
            formikValues.columnNumber * productDetailsData.maxLimit,
            percentValue,
          ) || 1
        );
      }
      return formikValues?.lowLimit;
    };

    setFormikValues((prev) => ({
      ...prev,
      percent: percentValue,
      lowLimit: getMinLimitValue(),
    }));
  };

  const handleEditMinLimit = (value: any) => {
    const maxLimitValue =
      formikValues.columnNumber && productDetailsData?.maxLimit
        ? Number(formikValues.columnNumber * productDetailsData?.maxLimit)
        : null;

    if (value > (maxLimitValue ?? 0)) {
      return;
    }

    const lowLimitValue = value ? Number(value) : null;
    const getPercentValue = () => {
      if (maxLimitValue && lowLimitValue) {
        return (Math.floor(lowLimitValue) / Math.floor(maxLimitValue)) * 100;
      }
      return null;
    };

    setFormikValues((prev) => ({
      ...prev,
      lowLimit: lowLimitValue,
      percent: getPercentValue(),
    }));
  };

  const handleEditColumnNumber = (value: any) => {
    const maxLimitValue =
      value && productDetailsData?.maxLimit
        ? Number(value * productDetailsData?.maxLimit)
        : null;

    const lowLimitValue = formikValues.lowLimit
      ? Number(formikValues.lowLimit)
      : null;

    const getLowLimit = () => {
      if (lowLimitValue && maxLimitValue && lowLimitValue > maxLimitValue) {
        return maxLimitValue;
      }
      return lowLimitValue;
    };

    const getPercentValue = () => {
      const lowLimitValue = getLowLimit();
      if (maxLimitValue && lowLimitValue) {
        return (Math.floor(lowLimitValue) / Math.floor(maxLimitValue)) * 100;
      }
      return null;
    };

    setFormikValues((prev) => ({
      ...prev,
      columnNumber: value,
      percent: getPercentValue(),
      lowLimit: getLowLimit(),
    }));
  };

  const handleDefaultValue = (isDefaultValue: any) => {
    if (isDefaultValue) {
      setFormikValues((value) => ({
        ...value,
        percent: productDetailsData?.procent ?? value.percent,
        lowLimit: productDetailsData?.lowLimit ?? value.lowLimit,
        columnNumber: productDetailsData?.nrPicioare ?? value.columnNumber,
      }));
    }
    setIsDefault(isDefaultValue);
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleEditLimit = async (values: any) => {
    setIsLoading(true);

    const resultAction = await dispatch(
      postEditProdusWms({
        ...values,
        isDefault,
        type: "limit",
      }),
    );

    if (
      checkEndpointStatus(resultAction, postEditProdusWms) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postEditProdusWms) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  return (
    <ContentCard
      isLoading={isLoadingSelectProductWms}
      CardHeader={() => {
        return (
          <div className="d-flex justify-content-between">
            <Text
              variant="h3"
              component="h3"
              className="card-title mb-4"
              gutterBottom>
              Editare limite
            </Text>
            <div className="d-flex align-items-center">
              <Text
                forId="default"
                marginRight={1}
                component="label"
                className={"cursor-pointer ms-2"}>
                Default
              </Text>
              <SelectableChecker
                type="checkbox"
                id="default"
                name="default"
                checked={isDefault}
                onChange={handleDefaultValue}
              />
            </div>
          </div>
        );
      }}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={editLimitSchema}
        onSubmit={handleEditLimit}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          isSubmitting,
        }) => {
          const maxLimitValue =
            productDetailsData?.maxLimit &&
            values["columnNumber"] &&
            productDetailsData?.maxLimit * values["columnNumber"];

          return (
            <form
              className="form w-100"
              onSubmit={handleSubmit}
              noValidate
              id="kt_login_signin_form">
              <TextInput
                name="percent"
                type="number"
                label="Procent"
                value={values["percent"]}
                error={errors["percent"]}
                touched={touched["percent"]}
                inputProps={getFieldProps("percent")}
                onChange={handleEditPercent}
                disabled={isDefault}
              />
              <TextInput
                name="lowLimit"
                type="number"
                label="Limita de jos"
                value={values["lowLimit"]}
                error={errors["lowLimit"]}
                touched={touched["lowLimit"]}
                inputProps={getFieldProps("lowLimit")}
                onChange={handleEditMinLimit}
                disabled={isDefault}
              />
              <TextInput
                name="columnNumber"
                type="number"
                onChange={handleEditColumnNumber}
                label="Numar coloane"
                value={values["columnNumber"]}
                error={errors["columnNumber"]}
                touched={touched["columnNumber"]}
                inputProps={getFieldProps("columnNumber")}
                disabled={isDefault}
              />
              <Text variant="body1" isBold className="mb-6">
                {maxLimitValue && `Limita maxima: ${maxLimitValue}`}
              </Text>
              <div className="d-flex justify-content-between">
                <CustomButton
                  type="submit"
                  variant="outlined"
                  onClick={handleGoBack}>
                  Anuleaza
                </CustomButton>
                <CustomButton
                  type="submit"
                  variant="contained"
                  disabled={!isValid || isSubmitting}
                  loading={isSubmitting || isLoading}>
                  Salveaza
                </CustomButton>
              </div>
            </form>
          );
        }}
      </Formik>
    </ContentCard>
  );
}

export default NomenclatureEditLimit;
