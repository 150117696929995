import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { MonitoringPlasticBoxesListInterface } from "../../../../redux/interfaces/expedition.interface";
import { dateToString } from "../../../../utils/dateAndTime";

const monitoringPlasticBoxesTableStatus = [
  { value: 1, label: "Scanata de verificator" },
  { value: 2, label: "Preluata de sofer" },
  { value: 3, label: "Predata de sofer" },
  { value: 4, label: "Verificata de expeditie" },
];

const getStatusLabel = (status: any) => {
  const currentStatus = monitoringPlasticBoxesTableStatus.find(
    (item) => item.value === parseInt(status),
  );

  return currentStatus?.label;
};

export const monitoringPlasticBoxesTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Punct de livrare",
    value: "pctDeLivr",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Status",
    value: "status",
    minW: 125,
    withFilter: monitoringPlasticBoxesTableStatus,
  },
  {
    label: "Ruta",
    value: "ruta",
    minW: 125,
    withSearch: true,
  },
  {
    label: "Data operatiei",
    value: "dataStart",
    minW: 125,
  },
];

export const getMonitoringPlasticBoxesTableData: any = (
  data: MonitoringPlasticBoxesListInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.sn,
      crudData: item,
      data: [
        {
          title: item.pctDeLivr,
        },
        {
          badges: [
            {
              badgeText: getStatusLabel(item.status),
            },
          ],
        },
        {
          title: item.ruta,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(item.dataStart ?? "-")}`,
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
