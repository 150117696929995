const RECEPTION_OPEN = "Receptie deschisa";
const ADDED_INVOICES = "Facturi introduse";
const COUNTING_COMPLETED = "Numarare finalizata";
const NIR_GENERATED_WITH_DIFFERENCES = "Nir generat cu diferente";
const RECEPTION_COMPLETED = "Receptie finalizata";
const CLEAR_ZONE = "Elibereaza zona";
const TRANSFERRED_GOODS = "Marfa transferata";

export default {
  RECEPTION_OPEN,
  ADDED_INVOICES,
  COUNTING_COMPLETED,
  NIR_GENERATED_WITH_DIFFERENCES,
  RECEPTION_COMPLETED,
  CLEAR_ZONE,
  TRANSFERRED_GOODS,
};
