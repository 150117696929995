import React, { useState } from "react";
import ContentCard from "../../../../components/misc/ContentCard";
import { ReturnInvoicesDataInterface } from "../../../../redux/interfaces/returning.interface";
import TextInput from "../../../../components/misc/TextInput";
import { dateToString } from "../../../../utils/dateAndTime";
import FormButtons from "../../../../components/misc/FormButtons";

function ProductsQuantity({
  handleCurrentStep,
  selectedProducts,
  handleSetSelectedProducts,
}: {
  handleCurrentStep: (callBack: (value: number) => number) => void;
  selectedProducts: ReturnInvoicesDataInterface[];
  handleSetSelectedProducts: (products: ReturnInvoicesDataInterface[]) => void;
}) {
  const [
    currentSelectedProductsWithQuantity,
    setCurrentSelectedProductsWithQuantity,
  ] = useState<ReturnInvoicesDataInterface[]>(selectedProducts);

  const handleGoBack = () => {
    handleCurrentStep((prev) => prev - 1);
  };

  const handleGoNext = () => {
    handleSetSelectedProducts(currentSelectedProductsWithQuantity);
    handleCurrentStep((prev) => prev + 1);
  };

  const oneProductWithNoQuantity = currentSelectedProductsWithQuantity.some(
    (product) => !product.cantitateRetur,
  );

  return (
    <div>
      {currentSelectedProductsWithQuantity.map((product, index) => (
        <ProductCard
          key={index}
          product={product}
          setCurrentSelectedProductsWithQuantity={
            setCurrentSelectedProductsWithQuantity
          }
        />
      ))}

      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Inainte"
        handleGoBack={handleGoBack}
        handleGoNext={handleGoNext}
        nextButtonDisabled={oneProductWithNoQuantity}
      />
    </div>
  );
}

const ProductCard = ({
  product,
  setCurrentSelectedProductsWithQuantity,
}: {
  product: ReturnInvoicesDataInterface;
  setCurrentSelectedProductsWithQuantity: React.Dispatch<
    React.SetStateAction<ReturnInvoicesDataInterface[]>
  >;
}) => {
  const handleChangeQuantity = (value: string | number | null) => {
    const quantityBiggerThanLimit = Number(product.cantitate) < Number(value);

    if (value && quantityBiggerThanLimit) {
      return;
    }

    setCurrentSelectedProductsWithQuantity((prev) => {
      return prev.map((prevProduct) => {
        if (prevProduct.idPozitiePicking === product.idPozitiePicking) {
          return { ...prevProduct, cantitateRetur: value };
        }
        return prevProduct;
      });
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexBasis: "50%",
        flexGrow: 1,
      }}>
      <ContentCard
        className={`w-100 border cursor-pointer ${
          product.cantitateRetur
            ? "text-primary border-success"
            : "border-danger"
        }`}>
        <div>
          <h4>{product.bNume}</h4>
          <div className="d-flex justify-content-between">
            <p>Cod: {product.cod_mat}</p>
            <p>Lot: {product.lot}</p>
          </div>
          <div className="d-flex justify-content-between">
            <p>Data expirare: {dateToString(product.data_exp)}</p>
            <p>Cantitate: {product.cantitate}</p>
          </div>
        </div>
        <TextInput
          className="mt-4"
          label="Adauga cantiate"
          name="Cantiate"
          type="number"
          value={product.cantitateRetur}
          onChange={handleChangeQuantity}
          touched={!product.cantitateRetur}
          error="Adaugati cantitatea"
        />
      </ContentCard>
    </div>
  );
};

export default ProductsQuantity;
