import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../../components/CustomButton";
import ContentCard from "../../components/misc/ContentCard";
import { TableLoading } from "../../components/tables/components/TableLoading";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import useTableCtx from "../../components/tables/tableContext/useTableCtx";
import { TableWidget } from "../../components/tables/TableWidget";
import useRoutesHelper from "../../hooks/useRoutesHelper";

import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { receptionRoutes } from "../../router/routesConstants";
import { dateToString } from "../../utils/dateAndTime";
import TransferReceivedOperationsModal from "./components/TransferReceivedOperationsModal";
import {
  getTransferReceivedOperationsTableData,
  transferReceivedOperationsTableHeader,
} from "./components/tableConfigs/putawayProductsTableConfig";
import { TransferReceivedOperationsDataInterface } from "../../redux/interfaces/receipt.interface";
import {
  getTransferReceivedOperations,
  postMultipleTransferReceivedOperations,
} from "../../redux/api/receipt.api";

function TransferReceivedOperations() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeParams } = useRoutesHelper();
  const receptionId = routeParams?.reception;

  const {
    receipt: {
      transferReceivedOperations: {
        data: transferReceivedOperationsData,
        isLoadingTransferReceivedOperations,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [tableData, setTableData] =
    useState<TransferReceivedOperationsDataInterface | null>(null);
  const [selectedItems, setSelectedItems] = useState<any>([]);

  useEffect(() => {
    if (receptionId) {
      dispatch(getTransferReceivedOperations({ idFluxReceptie: receptionId }));
    }
  }, [dispatch, receptionId]);

  useEffect(() => {
    if (transferReceivedOperationsData?.tasks) {
      setTableData(transferReceivedOperationsData);
    }
  }, [transferReceivedOperationsData]);

  useEffect(() => {
    if (tableData?.tasks?.length === 0) {
      navigate(
        `/${receptionRoutes.reception}/${receptionRoutes.transferReceived}`,
        {
          replace: true,
        },
      );
    }
  }, [navigate, tableData?.tasks]);

  if (isLoadingTransferReceivedOperations) {
    return <TableLoading />;
  }

  const handleTableActions = (tableActions: TableActions) => {
    if (tableActions.selectedItems) {
      setSelectedItems(tableActions.selectedItems);
      return;
    }
  };

  const handleMultipleProducts = () => {
    dispatch(
      postMultipleTransferReceivedOperations({
        idFluxReceptie: receptionId,
        selectedItems: selectedItems,
      }),
    );
    return;
  };

  const CustomHeaderComponent = ({
    tableContext,
  }: {
    tableContext: ReturnType<typeof useTableCtx>;
  }) => {
    return (
      <div className="mb-5">
        <CustomButton
          className="me-2"
          onClick={() => tableContext && tableContext.handleCheckAllItems()}>
          Selecteaza toate
        </CustomButton>
        {selectedItems.length > 0 && (
          <CustomButton
            withConfirmationModal={{
              modalTitle: "Valideaza transferul?",
              modalRightButtonOnClick: handleMultipleProducts,
            }}
            variant="contained"
            className="me-2">
            Transfer
          </CustomButton>
        )}
        {selectedItems.length === 1 && (
          <TransferReceivedOperationsModal
            selectedSplitItem={selectedItems[0]}
            initialDepositsData={tableData?.depozit ?? []}
            idFluxReceptie={receptionId}
          />
        )}
      </div>
    );
  };

  const creationDate =
    transferReceivedOperationsData?.createdAt &&
    dateToString(transferReceivedOperationsData?.createdAt);

  return (
    <ContentCard>
      <TableWidget
        tableTitle={`Intrare in depozit a marfii de la furnizorul ${transferReceivedOperationsData?.numeFurnizor} din data ${creationDate}`}
        CustomHeaderComponent={CustomHeaderComponent}
        tableHeaderData={transferReceivedOperationsTableHeader}
        tableItemsData={getTransferReceivedOperationsTableData(
          tableData?.tasks,
        )}
        withTablePagination={false}
        showSearchForCategories={false}
        borderedRow
        handleTableActions={handleTableActions}
      />
    </ContentCard>
  );
}

export default TransferReceivedOperations;
