import React, { useEffect } from "react";
import ContentCard from "../../../components/misc/ContentCard";
import { TableWidget } from "../../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getWorkingRoutesNodes } from "../../../redux/api/expedition.api";
import {
  getNodesTableData,
  nodesTableHeader,
} from "./tableConfigs/expeditionNodesTableConfig";

import NodesRouteTableActionButtons from "./TableActionsButtonsComponents/NodesTableActionButtons";

function NodesRoute() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      workingRoutesNodes: {
        data: workingRoutesNodesData,
        isLoadingWorkingRoutesNodes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  useEffect(() => {
    dispatch(getWorkingRoutesNodes());
  }, [dispatch]);

  return (
    <ContentCard>
      <TableWidget
        tableTitle={"Noduri"}
        tableHeaderData={nodesTableHeader}
        tableItemsData={getNodesTableData(workingRoutesNodesData)}
        tableLoading={isLoadingWorkingRoutesNodes}
        ActionButtonsComponent={NodesRouteTableActionButtons}
        showSearchForCategories={false}
        borderedRow
      />
    </ContentCard>
  );
}

export default NodesRoute;
