import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import clsx from "clsx";

interface DatePicker {
  label?: string;
  placeholder?: string;
  handleChangeDate?: (newValue: Date | null) => void;
  value?: Date | null;
  disabled?: boolean;
  className?: string;
  withVerticalSpacer?: boolean;
  isRequired?: boolean;
  touched?: boolean;
  error?: string;
  textInputClassName?: string;
  dateFormat?: string;
  fullWith?: boolean;
  gutterBottom?: boolean;
  maxDate?: Date;
  minDate?: Date;
}

const DatePicker = ({
  label,
  placeholder,
  handleChangeDate,
  value,
  disabled,
  className,
  withVerticalSpacer,
  isRequired,
  touched,
  error,
  textInputClassName,
  dateFormat = "D/MM/yyyy",
  fullWith = true,
  gutterBottom = true,
  maxDate,
  minDate,
}: DatePicker) => {
  const handleChange = (newValue: Date | null) => {
    if (newValue) {
      handleChangeDate && handleChangeDate(newValue);
    }
  };
  return (
    <div
      className={`${fullWith ? "w-100" : ""} ${
        withVerticalSpacer ? "px-1" : ""
      } ${className}`}>
      <MuiDatePicker
        value={value || null}
        inputFormat={dateFormat}
        disableMaskedInput
        onChange={handleChange}
        disabled={disabled}
        maxDate={maxDate}
        minDate={minDate}
        className={clsx(
          "form-control form-control-solid mb-3 mb-lg-0",
          { "is-invalid": error && touched },
          {
            "is-valid": !error && touched,
          },
          textInputClassName,
        )}
        renderInput={(params) => (
          <div className={`d-flex flex-column ${gutterBottom ? "mb-7" : ""}`}>
            {label && (
              <label
                className={`${
                  isRequired && "required"
                } fs-6 mb-2 form-label fw-bolder text-dark`}>
                {label}
              </label>
            )}
            <TextField
              sx={{
                "& fieldset": { border: "none" },
              }}
              variant="outlined"
              placeholder={placeholder}
              {...params}
              onKeyDown={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        )}
      />
    </div>
  );
};

export default DatePicker;
