import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const fetchProducts = createAsyncThunk(
  "/products",
  async (params: { search: string }, { rejectWithValue }) => {
    const verifySearch =
      params?.search?.length > 0 ? params?.search : "nosearch";

    try {
      const { data } = await apiRequest(`/products/${verifySearch}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/products", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const fetchProductDetails = createAsyncThunk(
  "/productDetails",
  async (params: { productCode: string }, { rejectWithValue }) => {
    const { productCode } = params;
    try {
      const { data } = await apiRequest(`/receptieAviz/${productCode}`, "GET");
      return data;
    } catch (err: any) {
      console.warn("/productDetails", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);
