import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TableWidget } from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { getReceivedProducts } from "../../../redux/api/inventory.api";
import { ReceivedProductInterface } from "../../../redux/interfaces/inventory.interface";

import {
  getInventoryTableData,
  inventoryTableHeader,
} from "./tableConfigs/inventoryTableConfig";
import TotalReceivedProductsHeader from "./components/TotalReceivedProductsHeader";

function TotalReceivedProducts() {
  const dispatch = useAppDispatch();

  const {
    inventory: { receivedProducts },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const [receiptsData, setReceiptsData] = useState<ReceivedProductInterface[]>(
    [],
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!receivedProducts.isLoadingReceivedProducts) {
      setIsLoading(false);
      setReceiptsData(receivedProducts.data.produse);
    } else {
      setIsLoading(true);
    }
  }, [receivedProducts]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableSearch
    ) {
      if (action.tableSearch.startDate && action.tableSearch.endDate) {
        dispatch(
          getReceivedProducts({
            page: action.tablePagination.page,
            perPage: action.tablePagination.rowsPerPage,
            startDate: action.tableSearch.startDate,
            endDate: action.tableSearch.endDate,
            numeProdus: action.tableSearch?.numeProdus,
            numeFurnizor: action.tableSearch?.numeFurnizor,
            lot: action.tableSearch?.lot,
          }),
        );
      }
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle="Total produse receptionate"
        tableHeaderData={inventoryTableHeader}
        tableItemsData={getInventoryTableData(receiptsData)}
        tableLoading={isLoading}
        handleTableActions={handleTableActions}
        totalItems={receivedProducts.data.nrTotalProduse}
        CustomHeaderComponent={TotalReceivedProductsHeader}
      />
    </Box>
  );
}

export default TotalReceivedProducts;
