import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";

import { useAppDispatch } from "../../../../../redux/hooks";
import { getCorrectVerification } from "../../../../../redux/api/picking.api";
import { VerifyCommandProductsListInterface } from "../../../../../redux/interfaces/picking.interface";

import CustomButton from "../../../../../components/CustomButton";

import { setVerifyCommand } from "../../../../../redux/reducer/picking.reducer";

function VerifyCommandProductsButtons({
  verificationZone,
  verifyCommandProductsList,
  handleClearCommandData,
}: {
  verificationZone: string;
  verifyCommandProductsList: VerifyCommandProductsListInterface[];
  handleClearCommandData: () => void;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleCorrection = () => {
    handleClearCommandData();
    const op = verifyCommandProductsList?.[0]?.Op;

    dispatch(
      getCorrectVerification({
        params: {
          op,
        },
        callBack: () => handleClearCommandData,
      }),
    );
  };

  const handleGoBack = () => {
    handleClearCommandData();
    navigate(-1);
  };

  const withAllProductsValidated = useMemo(
    () => verifyCommandProductsList.every((product) => product.isValidate),
    [verifyCommandProductsList],
  );

  const WithAllProductsQuantityValid = useMemo(
    () =>
      verifyCommandProductsList.every((product) => {
        if (product.anulat) {
          return product.cantitatePick === 0;
        }
        return product.cantitatePick === product.cantitate;
      }),
    [verifyCommandProductsList],
  );

  return (
    <div className="d-flex justify-content-between">
      <CustomButton tabIndex={-1} variant="outlined" onClick={handleGoBack}>
        Anuleaza
      </CustomButton>
      {!!verificationZone && verifyCommandProductsList.length > 0 && (
        <div>
          {!withAllProductsValidated && (
            <CustomButton
              className="me-2"
              tabIndex={-1}
              variant="contained"
              disabled={
                withAllProductsValidated || WithAllProductsQuantityValid
              }
              withConfirmationModal={{
                modalTitle: "Corectie comanda",
                modalSubtitle: `Doriti sa corectati comanda de pe zona ${verificationZone}?`,
                modalLeftButtonVariant: "outlined",
                modalRightButtonOnClick: handleCorrection,
              }}>
              Corectie
            </CustomButton>
          )}
          {withAllProductsValidated && (
            <CustomButton
              tabIndex={-1}
              variant="contained"
              disabled={!withAllProductsValidated}
              onClick={() => {
                dispatch(
                  setVerifyCommand({
                    step: 1,
                  }),
                );
              }}>
              Valideaza comanda
            </CustomButton>
          )}
        </div>
      )}
    </div>
  );
}

export default VerifyCommandProductsButtons;
