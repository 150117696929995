import React from "react";
import Text from "../../styleguide/Text";

function CategoryHeader({
  title,
  isRequired = true,
}: {
  title: string;
  isRequired?: boolean;
}) {
  return (
    <label className="d-flex align-items-center fs-5 fw-semibold mb-4">
      <Text
        variant="h2"
        component="span"
        className={`${isRequired ? "required" : ""}`}>
        {title}
      </Text>
      {isRequired && (
        <i
          className="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Select your app category"
        />
      )}
    </label>
  );
}

export default CategoryHeader;
