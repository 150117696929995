import React, { useEffect, useState } from "react";
import ContentCard from "../../../components/misc/ContentCard";
import TransferList from "../../../components/misc/TransferableLists";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  getDeliveryInvoices,
  postSplitDeliveryArrangements,
} from "../../../redux/api/picking.api";
import CustomButton from "../../../components/CustomButton";
import { useNavigate } from "react-router-dom";

function SplitInvoices() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    routeParams: { op, delivery },
  }: any = useRoutesHelper() || {};

  const {
    picking: {
      deliveryInvoices: { data: allInvoices, isLoadingDeliveryInvoices },
    },
  } = useAppSelector((state) => ({
    picking: state.picking,
  }));

  const [leftList, setLeftList] = useState<any[]>([]);
  const [rightList, setRightList] = useState<any[]>([]);
  const [isLoadingSplitting, setIsLoadingSplitting] = useState(false);

  useEffect(() => {
    if (!op) return;
    dispatch(getDeliveryInvoices({ params: { op } }));
  }, [dispatch, op]);

  useEffect(() => {
    if (allInvoices.length === 0) return;
    const availableInvoices = allInvoices.map((invoice) => invoice.act);
    setLeftList(availableInvoices);
  }, [allInvoices]);

  const handleValidateSplit = (type: "split" | "disable") => {
    setIsLoadingSplitting(true);
    const callBack = () => {
      setIsLoadingSplitting(false);
      navigate(-1);
    };

    dispatch(
      postSplitDeliveryArrangements({
        params: { op, type, facturi: rightList },
        callBack,
      }),
    );
  };

  return (
    <ContentCard
      cardTitle={`Split facturi din comanda ${op} pentru punctul de livrare ${delivery}`}>
      <TransferList
        isLoading={isLoadingDeliveryInvoices}
        leftListTitle="Facturi disponibile "
        rightListTitle="Facturi adaugate "
        leftListItems={leftList}
        rightListItems={rightList}
        listCrudItems={allInvoices}
        setRightList={setRightList}
        setLeftList={setLeftList}
        disabled={false}
      />
      <div className="d-flex justify-content-end mt-4">
        <CustomButton
          variant="outlined"
          className="me-2"
          onClick={() => handleValidateSplit("disable")}
          loading={isLoadingSplitting}
          disabled={isLoadingDeliveryInvoices || isLoadingSplitting}
          withConfirmationModal={{
            modalTitle: "Dezactiveaza facturi",
            modalSubtitle: "Esti sigur ca vrei sa dezactivezi facturile?",
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: () => handleValidateSplit("disable"),
          }}>
          Dezactiveaza facturi
        </CustomButton>
        <CustomButton
          variant="contained"
          className="me-2"
          onClick={() => handleValidateSplit("split")}
          loading={isLoadingSplitting}
          disabled={isLoadingDeliveryInvoices || isLoadingSplitting}
          withConfirmationModal={{
            modalTitle: "Imparte comanda",
            modalSubtitle: "Esti sigur ca vrei sa imparti comanda?",
            modalLeftButtonVariant: "outlined",
            modalRightButtonOnClick: () => handleValidateSplit("split"),
          }}>
          Imparte comanda
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default SplitInvoices;
