import TextInput from "../../../components/misc/TextInput";
import { dateForDatabase } from "../../../utils/dateAndTime";
import DatePicker from "../../../components/misc/DatePicker";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import { useAppSelector } from "../../../redux/hooks";
import { useEffect, useState } from "react";

function QuarantineSolutionResolveAviz({
  type,
  onPropertyChange,
  newLot,
  newBBD,
}: {
  crudData: any;
  type: string;
  onPropertyChange: (property: string, value: any, type: string) => void;
  newLot?: string;
  newBBD?: Date;
}) {
  const {
    general: {
      productDetails: { data: productDetails, isLoadingProductDetails },
    },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  const [selectedProductDetailsData, setSelectedProductDetailsData] =
    useState<DropdownValue | null>(null);
  const [selectedProductDetailsApData, setSelectedProductDetailsApData] =
    useState<DropdownValue | null>(null);

  const [selectedProductDetailsBoxData, setSelectedProductDetailsBoxData] =
    useState<DropdownValue | null>(null);

  useEffect(() => {
    if (isLoadingProductDetails) return;

    if (productDetails?.produs && productDetails?.produs?.length > 0) {
      const selectFirstItem = productDetails?.produs[0];
      setSelectedProductDetailsData({
        label: `${selectFirstItem?.numeProdusErp} -> ${selectFirstItem?.sn} -> ${selectFirstItem.codProdusErp}`,
        id: selectFirstItem?.idProdusErp,
      });
      onPropertyChange("newProductId", selectFirstItem.idProdusErp, type);
      onPropertyChange("newProductCode", selectFirstItem?.codProdusErp, type);
      onPropertyChange("newProductSn", selectFirstItem?.sn, type);
    }
    if (productDetails?.produsAp && productDetails?.produsAp?.length > 0) {
      const selectFirstItem = productDetails?.produsAp[0];
      setSelectedProductDetailsApData({
        label: `Latime: ${selectFirstItem?.latimeA}/Lungime: ${selectFirstItem?.lungimeA}/ Inaltime: ${selectFirstItem?.inaltimeA}/ Greutate: ${selectFirstItem.greutateA}`,
        id: selectFirstItem?.idAp,
      });
      onPropertyChange("newProductApId", selectFirstItem.idAp, type);
    }
    if (productDetails?.produsBox && productDetails?.produsBox?.length > 0) {
      const selectFirstItem = productDetails?.produsBox[0];
      setSelectedProductDetailsBoxData({
        label: `Latime: ${selectFirstItem?.latimeB}/Lungime: ${selectFirstItem?.lungimeB}/ Inaltime: ${selectFirstItem?.inaltimeB}/ Greutate: ${selectFirstItem?.greutateB}`,
        id: selectFirstItem?.idBox,
      });
      onPropertyChange("newProductBoxId", selectFirstItem.idBox, type);
    }
  }, [onPropertyChange, productDetails, type, isLoadingProductDetails]);

  const handleSelectedProductChange = (value: DropdownValue | null) => {
    if (!value) return;
    const getSelectedProduct = productDetails?.produs.find(
      // @ts-ignore
      (item) => item.idProdusErp === value.id,
    );
    if (!getSelectedProduct) return;
    onPropertyChange("newProductId", getSelectedProduct.idProdusErp, type);
    onPropertyChange("newProductCode", getSelectedProduct.codProdusErp, type);
    onPropertyChange("newProductSn", getSelectedProduct.sn, type);

    setSelectedProductDetailsData(value);
  };

  if (isLoadingProductDetails) {
    return (
      <div className="spinner-border text-primary my-2" role="status">
        {" "}
      </div>
    );
  }

  const productDetailsData: DropdownValue[] = productDetails
    ? productDetails.produs.map((item) => {
        return {
          label: `${item.numeProdusErp} -> ${item.sn} -> ${item.codProdusErp}`,
          id: item.idProdusErp,
        };
      })
    : [];

  const productDetailsAp: DropdownValue[] = productDetails
    ? productDetails.produsAp.map((item) => {
        return {
          label: `Latime: ${item.latimeA}/Lungime: ${item.lungimeA}/ Inaltime: ${item.inaltimeA}/ Greutate: ${item.greutateA}`,
          id: item.idAp,
        };
      })
    : [];

  const productDetailsBox: DropdownValue[] = productDetails
    ? productDetails.produsBox.map((item) => {
        return {
          label: `Latime: ${item.latimeB}/Lungime: ${item.lungimeB}/ Inaltime: ${item.inaltimeB}/ Greutate: ${item.greutateB}`,
          id: item.idBox,
        };
      })
    : [];

  return (
    <>
      {productDetails?.produs && productDetails?.produs?.length > 0 && (
        <Dropdown
          className="mb-4 me-2"
          title="Selecteaza produsul"
          data={productDetailsData}
          singleValue={selectedProductDetailsData}
          setSingleValue={handleSelectedProductChange}
        />
      )}
      {productDetails?.produsAp && productDetails?.produsAp?.length > 0 && (
        <Dropdown
          className="mb-4 me-2"
          title="Selecteaza ambalajul primar"
          data={productDetailsAp}
          singleValue={selectedProductDetailsApData}
          setSingleValue={(value) => {
            // @ts-ignore
            onPropertyChange("newProductApId", value?.id, type);
            setSelectedProductDetailsApData(value);
          }}
        />
      )}
      {productDetails?.produsBox && productDetails?.produsBox?.length > 0 && (
        <Dropdown
          className="mb-4 me-2"
          title="Selecteaza ambalajul secundar"
          data={productDetailsBox}
          singleValue={selectedProductDetailsBoxData}
          setSingleValue={(value) => {
            // @ts-ignore
            onPropertyChange("newProductBoxId", value?.id, type);
            setSelectedProductDetailsBoxData(value);
          }}
        />
      )}
      <TextInput
        id={`newLot-${type}`}
        label="Lot nou"
        type="text"
        name={`newLot-${type}`}
        value={newLot}
        onChange={(value) => onPropertyChange("newLot", value, type)}
      />
      <DatePicker
        label="BBD nou"
        className="mb-6"
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        minDate={new Date()}
        value={newBBD}
        handleChangeDate={(value) =>
          onPropertyChange("newBBD", dateForDatabase(value), type)
        }
      />
    </>
  );
}

export default QuarantineSolutionResolveAviz;
