import React, { useEffect } from "react";

import { Box } from "@mui/material";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import {
  getMonitorQuarantineTableData,
  monitorQuarantineTableHeader,
} from "./components/tableConfigs/monitorQuarantineTableConfig";
import { getMonitorQuarantine } from "../../redux/api/receipt.api";
import MonitorQuarantineActionButtons from "./components/TableActionsButtonsComponents/MonitorQuarantineActionButtons";
import { clearMonitorQuarantine } from "../../redux/reducer/receipt.reducer";

function MonitorQuarantine() {
  const dispatch = useAppDispatch();

  const {
    receipt: {
      monitorQuarantine: {
        data: { nrTotalTaskuriMonitorizare, taskuriMonitorizare },
        isLoadingMonitorQuarantine,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearMonitorQuarantine());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getMonitorQuarantine({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          codProdus: action.tableSearch?.codProdus,
          numeProdus: action.tableSearch?.numeProdus,
        }),
      );
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle={"Produse aflate in carantina"}
        tableHeaderData={monitorQuarantineTableHeader}
        tableItemsData={getMonitorQuarantineTableData(taskuriMonitorizare)}
        totalItems={nrTotalTaskuriMonitorizare}
        tableLoading={isLoadingMonitorQuarantine}
        handleTableActions={handleTableActions}
        ActionButtonsComponent={MonitorQuarantineActionButtons}
        withGlobalSearch={false}
        withGlobalFilter={false}
        withTablePagination
      />
    </Box>
  );
}

export default MonitorQuarantine;
