import React, { useState } from "react";

import { postValidateQuantityVerification } from "../../../../../redux/api/picking.api";
import { VerifyCommandProductsListInterface } from "../../../../../redux/interfaces/picking.interface";
import { useAppDispatch } from "../../../../../redux/hooks";

import TextInput from "../../../../../components/misc/TextInput";
import ModalWrapper from "../../../../../components/Modal/ModalWrapper";
import CustomButton from "../../../../../components/CustomButton";
import Text from "../../../../../styleguide/Text";
import { dateToString } from "../../../../../utils/dateAndTime";

function withWrongQuantityModal<P>(WrappedComponent: React.ComponentType<P>) {
  function WrongQuantityModal(props: P) {
    const dispatch = useAppDispatch();

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [reasonType, setReasonType] = useState<string | number | null>(null);
    const [productSelected, setProductSelected] =
      useState<VerifyCommandProductsListInterface | null>(null);

    const [commandCode, setCommandCode] = useState<string | number | null>(
      null,
    );

    const [productQuantityBelow, setProductQuantityBelow] = useState<
      string | number | null
    >(null);

    const [productQuantityAbove, setProductQuantityAbove] = useState<
      string | number | null
    >(null);

    const [scannedCode, setScannedCode] = useState<string | number | null>(
      null,
    );

    const handleCloseModal = () => {
      setShowModal(false);
      setProductSelected(null);
      setCommandCode(null);
      setProductQuantityAbove(null);
      setProductQuantityBelow(null);
      setScannedCode(null);
    };

    const handleShowWrongQuantityModal = ({
      product,
      commandCodeValue,
      type,
    }: {
      product?: VerifyCommandProductsListInterface;
      commandCodeValue?: string | number | null;
      type?: string;
    }) => {
      if (!product || !commandCodeValue || !type) {
        handleCloseModal();
        return;
      }
      setShowModal(true);
      setProductSelected(product);
      setCommandCode(commandCodeValue);
      setReasonType(type);
    };

    const handleValidateCommand = () => {
      if (!commandCode || !productSelected || !reasonType) {
        return;
      }
      setIsLoadingSave(true);

      dispatch(
        postValidateQuantityVerification({
          params: {
            quantity:
              reasonType === "below"
                ? productQuantityBelow
                : productQuantityAbove,
            commandCode: commandCode.toString(),
            scannedCode: scannedCode?.toString(),
            productId: productSelected.idProdusErp.toString(),
            type: reasonType.toString(),
            product: productSelected,
          },
          callBack: () => {
            setIsLoadingSave(false);
            handleCloseModal();
          },
        }),
      );
    };

    const getDisableSaveButton = () => {
      if (reasonType === "below") {
        return !productQuantityBelow;
      }
      if (reasonType === "above") {
        return !productQuantityAbove || !scannedCode;
      }
      return true;
    };

    const disableSaveButton = getDisableSaveButton();

    return (
      <>
        <ModalWrapper
          title={`Produs ${productSelected?.bnume}`}
          show={showModal}
          handleClose={() => handleShowWrongQuantityModal({})}>
          <div className="d-flex justify-content-between mb-5">
            <Text variant="h5">Lot: {productSelected?.lot}</Text>
            <Text variant="h5">
              Data expirare: {dateToString(productSelected?.data_exp)}
            </Text>
          </div>
          {reasonType === "below" && (
            <TextInput
              label={`Cantitate in minus (${productSelected?.cantitatePick}/${productSelected?.cantitate})`}
              placeholder="Scrieti cate produse lipsesc"
              name="productQuantityBelow"
              id="productQuantityBelow"
              value={productQuantityBelow}
              type="number"
              onChange={(value) => setProductQuantityBelow(value)}
            />
          )}
          {reasonType === "above" && (
            <>
              <TextInput
                label="Cod scanat"
                name="codScanat"
                id="codScanat"
                value={scannedCode}
                onChange={(value) => setScannedCode(value)}
                autoFocus
              />
              <TextInput
                label={`Cantitate in plus (${productSelected?.cantitatePick}/${productSelected?.cantitate})`}
                name="productQuantityAbove"
                id="productQuantityAbove"
                value={productQuantityAbove}
                type="number"
                onChange={(value) => setProductQuantityAbove(value)}
              />
            </>
          )}
          <div className="d-flex justify-content-end">
            <CustomButton
              variant="contained"
              onClick={handleValidateCommand}
              loading={isLoadingSave}
              disabled={disableSaveButton}>
              Salveaza
            </CustomButton>
          </div>
        </ModalWrapper>
        <WrappedComponent
          {...props}
          handleShowWrongQuantityModal={handleShowWrongQuantityModal}
        />
      </>
    );
  }
  return WrongQuantityModal;
}

export default withWrongQuantityModal;
