import React, { useEffect, useRef } from "react";
import Text from "../../styleguide/Text";
import clsx from "clsx";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";

interface Props {
  id?: string;
  className?: string;
  style?: React.CSSProperties;
  textInputClassName?: string;
  name: string;
  placeholder?: string;
  label?: string;
  touched?: boolean;
  error?: string;
  value?: string | number | null;
  onChange?: (value: number | string | null) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  inputProps?: any;
  type?: "text" | "number" | "password" | "textarea";
  disabled?: boolean;
  withVerticalSpacer?: boolean;
  onBlur?: any;
  onFocus?: any;
  onlyPositiveNumbers?: boolean;
  startComponent?: React.ReactNode;
  endComponent?: React.ReactNode;
  externalInputRef?: React.RefObject<HTMLInputElement>;
  autoFocus?: boolean;
}

const TextInput = ({
  id,
  className,
  style,
  textInputClassName,
  name,
  label,
  touched,
  error,
  value,
  onChange,
  onKeyDown,
  type = "text",
  inputProps,
  disabled,
  withVerticalSpacer,
  placeholder,
  onBlur,
  onFocus,
  onlyPositiveNumbers,
  startComponent,
  endComponent,
  externalInputRef,
  autoFocus = false,
}: Props) => {
  const localRef = useRef<HTMLInputElement>(null);
  const inputRef = externalInputRef || localRef;

  const getValue = () => {
    const inputPropsValue = inputProps?.value;
    if (value !== undefined && value !== null) {
      return value;
    } else if (inputPropsValue !== undefined && inputPropsValue !== null) {
      return inputPropsValue;
    }
    return "";
  };

  const classes = clsx(
    "form-control form-control-solid mb-3 mb-lg-0",
    { "is-invalid": error && touched },
    {
      "is-valid": !error && touched,
    },
  );

  const handleOnChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { value } = event.target;
    if (onChange) {
      onChange(value);
      return;
    }
    if (inputProps?.onChange) {
      inputProps.onChange(event);
    }
    return;
  };

  const withFormik = () => {
    if (inputProps) {
      return inputProps;
    }
    return;
  };

  useEffect(() => {
    const element = inputRef.current;

    if (element) {
      const isNumber = element.type === "number";

      if (isNumber) {
        element.addEventListener("wheel", () => {
          element?.blur();
        });
      }
    }
  }, [inputRef]);

  return (
    <div
      className={`fv-row mb-7 w-100 ${withVerticalSpacer ? "px-1" : ""} ${
        className ?? ""
      }`}
      style={style}>
      {label && (
        <label htmlFor={name} className="form-label fs-6 fw-bolder text-dark">
          <Text variant="body1" isBold>
            {label}
          </Text>
        </label>
      )}
      {type !== "textarea" && (
        <TextField
          inputRef={inputRef}
          id={id}
          className={classes}
          sx={{
            "& fieldset": { border: "none" },
          }}
          autoFocus={autoFocus}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          type={type}
          disabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyPress={(event) => {
            if (
              onlyPositiveNumbers &&
              (event?.key === "-" || event?.key === "+")
            ) {
              event.preventDefault();
            }
          }}
          onKeyDown={onKeyDown}
          {...withFormik()}
          onChange={handleOnChange}
          value={getValue()}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">{startComponent}</InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">{endComponent}</InputAdornment>
            ),
          }}
        />
      )}
      {type === "textarea" && (
        <textarea
          ref={inputRef}
          id={id}
          className={`${classes} ${textInputClassName}`}
          name={name}
          placeholder={placeholder}
          autoComplete="off"
          disabled={disabled}
          onBlur={onBlur}
          onKeyDown={onKeyDown}
          {...withFormik()}
          onChange={handleOnChange}
          value={getValue()}
        />
      )}
      {touched && error && (
        <div className="fv-plugins-message-container">
          <Text variant="body1" color="red" role="alert" className="mt-2">
            {error}
          </Text>
        </div>
      )}
    </div>
  );
};

export default TextInput;
