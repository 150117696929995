import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { dateToString } from "../../../../utils/dateAndTime";

export const inventoryTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire produs",
    value: "numeProdus",
    withSearch: true,
    minW: 200,
  },
  {
    value: "Data receptiei",
    minW: 125,
  },

  {
    label: "Furnizor",
    value: "numeFurnizor",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Lot",
    value: "lot",
    withSearch: true,
    minW: 125,
  },
  {
    value: "BBD",
    minW: 125,
  },
  {
    value: "Cantitate",
    minW: 125,
  },
];

export const getInventoryTableData: any = (inventoryList: any) =>
  inventoryList?.map((inventory: any) => {
    return {
      id: inventory.idReceptie,
      crudData: inventory,
      data: [
        {
          title: inventory.numeProdusErp,
        },
        {
          badges: [
            {
              badgeText: dateToString(inventory.dataReceptie),
              badgeColor: "light-info",
            },
            {
              badgeText: inventory.oraReceptie,
              badgeColor: "light-info",
            },
          ],
        },
        {
          title: inventory.numeFurnizor,
        },
        {
          title: inventory.lot,
        },
        {
          badges: [
            {
              badgeText: `${dateToString(inventory.dataExpirare ?? "-")}`,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: inventory.totalCantitate,
        },
      ],
    };
  });
