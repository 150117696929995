import React, { useState } from "react";

import TextInput from "../../../../components/misc/TextInput";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { useAppDispatch } from "../../../../redux/hooks";
import CustomButton from "../../../../components/CustomButton";
import { useNavigate } from "react-router-dom";
import { getValidatePacking } from "../../../../redux/api/picking.api";

function withScanCommandBoxCode<P>(WrappedComponent: React.ComponentType<P>) {
  function ScanCommandBoxCode(props: P) {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [showBoxCodeModal, setShowBoxCodeModal] = useState(false);
    const [cellScanned, setCellScanned] = useState<string | number | null>(
      null,
    );
    const [boxScannedCode, setBoxScannedCode] = useState<
      string | number | null
    >(null);

    const handleShowBoxCodeModal = (
      cellScannedValue?: string | number | null,
    ) => {
      if (!cellScannedValue) {
        setShowBoxCodeModal(false);
        setCellScanned(null);
        return;
      }
      setShowBoxCodeModal(true);
      setCellScanned(cellScannedValue);
    };

    const handleValidateCommand = () => {
      if (!cellScanned || !boxScannedCode) {
        return;
      }
      setIsLoadingSave(true);

      dispatch(
        getValidatePacking({
          params: {
            cellScanned: boxScannedCode.toString(),
            op: cellScanned.toString(),
          },
          callBack: () => {
            setIsLoadingSave(false);
            setBoxScannedCode(null);
            setCellScanned(null);
            navigate(-1);
          },
        }),
      );
    };

    const disableSaveButton = !cellScanned;

    return (
      <>
        <ModalWrapper
          title={"Cod cutie"}
          show={showBoxCodeModal}
          handleClose={() => handleShowBoxCodeModal(null)}>
          <TextInput
            label="Scanati cutia"
            name="boxScannedCode"
            id="boxScannedCode"
            value={boxScannedCode}
            onChange={(value) => setBoxScannedCode(value)}
          />
          <div className="d-flex justify-content-end">
            <CustomButton
              variant="contained"
              onClick={handleValidateCommand}
              loading={isLoadingSave}
              disabled={disableSaveButton}>
              Salveaza
            </CustomButton>
          </div>
        </ModalWrapper>
        <WrappedComponent
          {...props}
          handleShowBoxCodeModal={handleShowBoxCodeModal}
        />
      </>
    );
  }
  return ScanCommandBoxCode;
}

export default withScanCommandBoxCode;
