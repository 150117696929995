import React from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { postGetProducts } from "../../../redux/api/inventory.api";
import { TableWidget } from "../../../components/tables/TableWidget";
import {
  getProductsTableData,
  productsTableHeader,
} from "./tableConfigs/productsTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import NomenclatureTableActionButtons from "./TableActionButtonsComponent/NomenclatureTableActionButtons";

function Nomenclature() {
  const dispatch = useAppDispatch();

  const {
    inventory: {
      products: {
        data: { produseWms, nrTotal },
        isLoadingProducts,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        postGetProducts({
          page: action.tablePagination.page,
          per_page: action.tablePagination.rowsPerPage,
          numeProdusErp: action.tableSearch?.numeProdusErp ?? "",
          codProdusErp: action.tableSearch?.codProdusErp ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Produse WMS"}
      tableHeaderData={productsTableHeader}
      tableItemsData={getProductsTableData(produseWms)}
      totalItems={nrTotal}
      tableLoading={isLoadingProducts}
      handleTableActions={handleTableActions}
      ActionButtonsComponent={NomenclatureTableActionButtons}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination
    />
  );
}

export default Nomenclature;
