import React from "react";
import { useAppSelector } from "../../../../../redux/hooks";
import ContentCard from "../../../../../components/misc/ContentCard";
import Text from "../../../../../styleguide/Text";

function LastVerifiedCommand() {
  const {
    general: { lastSavedVerifiedCommands },
  } = useAppSelector((state) => ({
    general: state.general,
  }));

  if (lastSavedVerifiedCommands.length === 0) {
    return null;
  }

  return (
    <ContentCard cardBodyClassName="d-flex flex-row">
      <Text variant="body1" className="me-2" isBold>
        Istoric comenzi:
      </Text>
      {lastSavedVerifiedCommands.map((command, idx) => (
        <Text
          variant="body1"
          className={`me-7 ${idx === 0 ? "text-success" : "text-black"}`}
          isBold={idx === 0}>
          {command}
        </Text>
      ))}
    </ContentCard>
  );
}

export default LastVerifiedCommand;
