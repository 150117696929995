import React, { useEffect } from "react";
import ContentCard from "../../components/misc/ContentCard";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import BarChart from "../../components/misc/Charts/BarChart";
import {
  getMediGroupsRaports,
  getProviderReceptionsRaports,
} from "../../redux/api/raports.api";
import PieChart from "../../components/misc/Charts/PieChart";

function Raports() {
  const dispatch = useAppDispatch();
  const {
    raports: {
      mediGroups: { data: mediGroups },
      providerReceptions: { data: providerReceptions },
    },
  } = useAppSelector((state) => ({
    raports: state.raports,
  }));

  useEffect(() => {
    dispatch(getMediGroupsRaports());
    dispatch(getProviderReceptionsRaports());
  }, [dispatch]);

  const medicGroupsData = {
    count: mediGroups.map((item) => item.nrProduse),
    code: mediGroups.map((item) => item.codGrupa),
    total: mediGroups.reduce((acc, item) => acc + item.nrProduse, 0),
  };

  const providerReceptionData = {
    count: providerReceptions.map((item) => item.nrReceptii),
    provider: providerReceptions.map((item) => item.numeFurnizor),
    total: providerReceptions.reduce((acc, item) => acc + item.nrReceptii, 0),
    bestProvider:
      providerReceptions.length > 0
        ? [...providerReceptions].sort(
            (a, b) => b?.nrReceptii - a?.nrReceptii,
          )[0]
        : { numeFurnizor: "", nrReceptii: 0 },
  };

  return (
    <ContentCard>
      <BarChart
        title="Receptii produse in functie de categoria lor"
        subtitle={`Total ${medicGroupsData.total} linii pe facturi`}
        data={[
          {
            name: "Numar medicamente: ",
            data: medicGroupsData.count,
          },
        ]}
        categories={medicGroupsData.code}
        height={500}
        labelHoverFormatter={(value) => `${value}`}
      />
      <PieChart
        title={`Furnizori pentru (${providerReceptionData.total}) receptii)`}
        subtitle={`Furnizorul cel mai solititat: ${providerReceptionData?.bestProvider.numeFurnizor} (${providerReceptionData.bestProvider.nrReceptii})`}
        data={providerReceptionData.count}
        categories={providerReceptionData.provider}
        height={500}
        labelHoverFormatter={(value) => `${value} receptii`}
      />
    </ContentCard>
  );
}

export default Raports;
