import { Formik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";

import ContentCard from "../../../../components/misc/ContentCard";
import ModalList, {
  ModalListItem,
} from "../../../../components/misc/ModalList/ModalList";
import TextInput from "../../../../components/misc/TextInput";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import { fetchProducts } from "../../../../redux/api/general.api";
import CustomButton from "../../../../components/CustomButton";
import {
  getSelectProductWms,
  postEditProdusWms,
} from "../../../../redux/api/inventory.api";
import { clearSelectProductWms } from "../../../../redux/reducer/inventory.reducer";
import Checkbox from "../../../../components/misc/Checkbox";
import { ProductsDataInterface } from "../../../../redux/interfaces/general.interface";
import {
  checkEndpointStatus,
  endpointStatus,
} from "../../../../helpers/apiHelper";

interface initialValuesInterface {
  sn: string | null;
  isLiquid: boolean;

  [key: string]: any;
}

const initialValues: initialValuesInterface = {
  sn: null,
  isLiquid: false,
};

const productSchema = Yup.object({
  sn: Yup.string().nullable().required("Campul este obligatoriu"),
});

function NomenclatureEditProduct() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { routeParams } = useRoutesHelper();

  const { idProdus } = routeParams || {};

  const [formikValues, setFormikValues] =
    useState<initialValuesInterface>(initialValues);

  const [oldProductData, setOldProductData] =
    useState<initialValuesInterface>(initialValues);
  const [product, setProduct] = useState<Partial<ProductsDataInterface> | null>(
    null,
  );
  const [isLoading, setIsLoading] = useState(false);

  const {
    general: { products },
    inventory: {
      selectProductWms: { data: productDetailsData, isLoadingSelectProductWms },
    },
    user: { userId },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
    inventory: state.inventory,
    user: state.user,
    general: state.general,
  }));

  const selectedProductFormat: ModalListItem | undefined = useMemo(() => {
    if (product?.idProdusErp) {
      return {
        id: product.idProdusErp,
        label: `${product.numeProdus} - ${product.codProdus}`,
      };
    }
  }, [product]);

  const initialProductsList: ModalListItem[] = useMemo(
    () =>
      products.productsList.map(
        ({ numeProdus, codProdus, idProdusErp, isPaired }) => {
          return {
            label: `${numeProdus} - ${codProdus}`, // required
            id: idProdusErp, // required
            backgroundColor: isPaired ? "warning" : "info",
          };
        },
      ),
    [products.productsList],
  );

  useEffect(() => {
    if (idProdus) {
      dispatch(getSelectProductWms(idProdus));
    }
  }, [dispatch, idProdus]);

  useEffect(() => {
    return () => {
      dispatch(clearSelectProductWms());
    };
  }, [dispatch]);

  useEffect(() => {
    if (productDetailsData) {
      setProduct({
        idProdusErp: productDetailsData?.idProdusErp,
        numeProdus: productDetailsData?.numeProdusErp,
        codProdus: productDetailsData?.codProdusErp,
      });

      setFormikValues((prev) => ({
        ...prev,
        sn: productDetailsData?.sn ?? prev.sn,
        isLiquid: productDetailsData?.isLiquid ?? prev.isLiquid,
      }));
      setOldProductData(productDetailsData);
    }
  }, [productDetailsData]);

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleSaveProductDetails = async (value: initialValuesInterface) => {
    setIsLoading(true);

    const response = {
      newProductData: {
        ...oldProductData,
        ...product,
        ...value,
        userId,
        idProdus,
      },
      oldProductData,
      type: "product",
    };

    const resultAction = await dispatch(postEditProdusWms(response));

    if (
      checkEndpointStatus(resultAction, postEditProdusWms) !==
      endpointStatus.pending
    ) {
      setIsLoading(false);
    }

    if (
      checkEndpointStatus(resultAction, postEditProdusWms) ===
      endpointStatus.fulfilled
    ) {
      navigate(-1);
    }
  };

  const modalActions = ({ search }: { search: string }) => {
    dispatch(fetchProducts({ search: search }));
  };

  const handleProductSelected = (value: any) => {
    const productDetails = products.productsList.find(
      (item) => item.idProdusErp === value?.id,
    );
    if (productDetails) {
      setProduct(productDetails);
    }
  };

  return (
    <ContentCard
      cardTitle="Editeaza detalii produs"
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}
      isLoading={isLoadingSelectProductWms}>
      <Formik
        enableReinitialize
        initialValues={formikValues}
        validationSchema={productSchema}
        onSubmit={handleSaveProductDetails}>
        {({
          isValid,
          errors,
          touched,
          values,
          getFieldProps,
          handleSubmit,
          isSubmitting,
          setFieldValue,
        }) => (
          <form
            className="form w-100"
            onSubmit={handleSubmit}
            noValidate
            id="kt_login_signin_form">
            <ModalList
              label="Nume produs"
              items={initialProductsList}
              modalActions={modalActions}
              selectedItem={selectedProductFormat}
              setSelectedItem={handleProductSelected}
              searchPlaceholder={"Cauta produs"}
              modalTitle={"Cauta produs"}
            />
            <TextInput
              name="serialNumber"
              label="Serial Number"
              error={errors["sn"]}
              touched={touched["sn"]}
              inputProps={getFieldProps("sn")}
            />
            <div className="d-flex">
              <Checkbox
                name="isLiquid"
                label="Lichid"
                checked={values.isLiquid}
                onChange={(checked) => setFieldValue("isLiquid", checked)}
              />
            </div>
            <div className="d-flex justify-content-between  mt-6">
              <CustomButton
                variant="outlined"
                onClick={handleGoBack}>
                Anuleaza
              </CustomButton>
              <CustomButton
                type="submit"
                variant="contained"
                loading={isSubmitting || isLoading}
                disabled={!isValid || isSubmitting || !product}>
                Salveaza
              </CustomButton>
            </div>
          </form>
        )}
      </Formik>
    </ContentCard>
  );
}

export default NomenclatureEditProduct;
