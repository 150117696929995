import React, { useMemo, useEffect } from "react";

import { Checkbox } from "@mui/material";
import { dateToString } from "../../../../../../utils/dateAndTime";
import { VerifyCommandProductsListInterface } from "../../../../../../redux/interfaces/picking.interface";
import withWrongQuantityModal from "../../hoc/withWrongQuantityModal";
import withProductWrongDetails from "../../hoc/withProductWrongDetails";
import { QrCodeObjectInterface } from "../../../../../../helpers/qrCodeFormater";

import CustomButton from "../../../../../../components/CustomButton";

function ProductCardComponent({
  product,
  qrCodeParams,
  commandCode,
  handleValidateProduct,
  handleShowWrongQuantityModal,
  handleShowWronProductDetailsModal,
}: {
  product: VerifyCommandProductsListInterface;
  qrCodeParams: QrCodeObjectInterface | null;
  commandCode: string | number | null;
  handleValidateProduct: (
    product: VerifyCommandProductsListInterface,
    withProductScanAutoFocus?: boolean,
  ) => void;
  handleShowWrongQuantityModal?: ({
    product,
    commandCodeValue,
  }: {
    product?: VerifyCommandProductsListInterface;
    commandCodeValue?: string | number | null;
    type?: string;
  }) => void;
  handleShowWronProductDetailsModal?: ({
    product,
    commandCodeValue,
  }: {
    product?: VerifyCommandProductsListInterface;
    commandCodeValue?: string | number | null;
  }) => void;
}) {
  const getTextStyle = () => {
    if (product.anulat) {
      return "text-danger";
    }
    if (product.cantitatePick > product.cantitate) {
      return "text-warning";
    }
    return "";
  };

  const isCheckboxVisible = useMemo(() => {
    if (product.anulat) {
      return product.cantitatePick === 0;
    }
    return product.cantitatePick === product.cantitate;
  }, [product]);

  const isScannedProduct = useMemo(
    () => qrCodeParams?.serialNumber === product.sn,
    [qrCodeParams?.serialNumber, product.sn],
  );

  useEffect(() => {
    if (isScannedProduct && qrCodeParams?.isQrCode) {
      if (
        !product.isValidate &&
        product.cantitatePick === product.cantitate &&
        product.lot === qrCodeParams.lot &&
        dateToString(product.data_exp) ===
          dateToString(qrCodeParams.expirationDate)
      ) {
        handleValidateProduct(product);
      }
    }
  }, [
    handleValidateProduct,
    isScannedProduct,
    product,
    qrCodeParams?.expirationDate,
    qrCodeParams?.isQrCode,
    qrCodeParams?.lot,
  ]);

  return (
    <tr
      className={`fw-bolder text-hover-primary fs-6 ${getTextStyle()} ${
        isScannedProduct ? "text-info bg-gray-300" : ""
      }`}
      id={product.sn}>
      <td>
        {isCheckboxVisible && (
          <button
            tabIndex={1}
            className="border-0 bg-transparent"
            onClick={() => handleValidateProduct(product)}>
            <Checkbox
              checked={product?.isValidate || false}
              disableRipple
              style={{
                transform: "scale(2)",
              }}
              onClick={() => handleValidateProduct(product, true)}
              disabled={false}
              inputProps={{
                "aria-labelledby": `enhanced-table-checkbox-${product.idPozitiePicking}`,
              }}
            />
          </button>
        )}
      </td>
      <td>{product.bnume}</td>
      <td>{`${product.cantitatePick}/${product.cantitate}`}</td>
      <td>{product.lot}</td>
      <td>{dateToString(product.data_exp)}</td>
      <td>{product.cNume}</td>
      <td>{product.cellScanned}</td>
      <td>
        <CustomButton
          tabIndex={-1}
          variant="contained"
          color="error"
          className="mt-0 mb-0 mx-1"
          withDropdown={{
            menuItems: [
              {
                value: 1,
                label: "Cantitate -",
                onClick: () =>
                  handleShowWrongQuantityModal &&
                  handleShowWrongQuantityModal({
                    product,
                    commandCodeValue: commandCode,
                    type: "below",
                  }),
              },
              {
                value: 2,
                label: "Cantitate +",
                onClick: () =>
                  handleShowWrongQuantityModal &&
                  handleShowWrongQuantityModal({
                    product,
                    commandCodeValue: commandCode,
                    type: "above",
                  }),
              },
              {
                value: 3,
                label: "Lot si BBD",
                onClick: () =>
                  handleShowWronProductDetailsModal &&
                  handleShowWronProductDetailsModal({
                    product,
                    commandCodeValue: commandCode,
                  }),
              },
            ],
          }}>
          Valideaza produs
        </CustomButton>
      </td>
    </tr>
  );
}

export default withProductWrongDetails(
  withWrongQuantityModal(ProductCardComponent),
);
