import { useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

const useQueryParams = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const searchParamsData: any = {};

  const searchParamsEntries = searchParams.entries();
  //   @ts-ignore
  for (const entry of searchParamsEntries) {
    const [param, value] = entry;
    searchParamsData[param] = value;
  }

  const handleSetQuerryParams = useCallback((params: object) => {
    navigate(
      `?${new URLSearchParams({
        ...params,
      })}`,
      { replace: true },
    );
    // setSearchParams(
    //   `?${new URLSearchParams({
    //     ...params,
    //   })}`,
    // );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    urlParams: searchParamsData,
    handleSetQuerryParams: handleSetQuerryParams,
  };
};

export default useQueryParams;
