import React from "react";
import CustomButton from "../../../components/CustomButton";
import ContentCard from "../../../components/misc/ContentCard";
import { useAppDispatch } from "../../../redux/hooks";
import { receptionRoutes } from "../../../router/routesConstants";
import Text from "../../../styleguide/Text";
import { syncProducts, syncProviders } from "../../../redux/api/external.api";

function ReceptionActionButtons() {
  const dispatch = useAppDispatch();
  const handleSyncProducts = () => {
    dispatch(syncProducts());
  };

  const handleSyncProviders = () => {
    dispatch(syncProviders());
  };

  return (
    <ContentCard>
      <CustomButton
        variant="contained"
        color="primary"
        navigate={`/${receptionRoutes.reception}/${receptionRoutes.createReception}`}>
        <Text>Creeaza receptie</Text>
      </CustomButton>
      <CustomButton
        variant="contained"
        color="primary"
        className="ms-2"
        withConfirmationModal={{
          modalTitle: "Doriti sa sincronizati produsele?",
          modalSubtitle: "Aceasta actiune poate dura cateva minute.",
          modalRightButtonText: "Da",
          modalRightButtonOnClick: handleSyncProducts,
          modalRightButtonVariant: "outlined",
          modalLeftButtonText: "Nu",
        }}>
        <Text>Sincronizeaza produse</Text>
      </CustomButton>
      <CustomButton
        variant="contained"
        color="primary"
        className="ms-2"
        withConfirmationModal={{
          modalTitle: "Doriti sa sincronizati furnizorii?",
          modalSubtitle: "Aceasta actiune poate dura cateva minute.",
          modalRightButtonText: "Da",
          modalRightButtonOnClick: handleSyncProviders,
          modalRightButtonVariant: "outlined",
          modalLeftButtonText: "Nu",
        }}>
        <Text>Sincronizeaza furnizori</Text>
      </CustomButton>
    </ContentCard>
  );
}

export default ReceptionActionButtons;
