import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TransferReceivedListDataInterface } from "../../redux/interfaces/receipt.interface";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import PutawayTableActionButtons from "./components/TableActionsButtonsComponents/PutawayTableActionButtons";
import {
  getTranferReceivedTableData,
  transferReceivedTableHeader,
} from "./components/tableConfigs/transferReceivedTableConfig";
import { getTransferReceivedTasks } from "../../redux/api/receipt.api";

function TransferReceived() {
  const dispatch = useAppDispatch();

  const {
    receipt: {
      transferReceived: {
        data: transferReceivedList,
        isLoadingTransferReceived,
      },
    },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [transferReceivedData, setTransferReceivedData] = useState<
    TransferReceivedListDataInterface[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!isLoadingTransferReceived) {
      setIsLoading(false);
      setTransferReceivedData(transferReceivedList.receptii);
    } else {
      setIsLoading(true);
    }
  }, [transferReceivedList, isLoadingTransferReceived]);

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getTransferReceivedTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          provider: action.tableSearch?.provider,
        }),
      );
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle="Transfer marfa recepionata in depozitul de vanzare"
        tableHeaderData={transferReceivedTableHeader}
        tableItemsData={getTranferReceivedTableData(transferReceivedData)}
        tableLoading={isLoading}
        handleTableActions={handleTableActions}
        totalItems={transferReceivedList.nrTotalReceptii}
        withGlobalSearch={false}
        ActionButtonsComponent={(props: any) => (
          <PutawayTableActionButtons {...props} />
        )}
      />
    </Box>
  );
}

export default TransferReceived;
