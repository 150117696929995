import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getRearrangementTasks } from "../../../redux/api/putaway.api";
import { TableWidget } from "../../../components/tables/TableWidget";
import {
  getPutawayRearrangementTasksTableData,
  putawayRearrangementTasksTableHeader,
} from "./tableConfig/rearrangementTasksTableConfig";

function PutawayRearrangementTasks() {
  const dispatch = useAppDispatch();

  const {
    putaway: {
      putawayRearrangementTasks: { data, isLoadingPutawayRearrangementTasks },
    },
  } = useAppSelector((state) => ({
    putaway: state.putaway,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter?.status
    ) {
      dispatch(
        getRearrangementTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status ?? "",
          productName: action.tableSearch?.productName ?? "",
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Taskuri rearanjare marfa"}
      tableHeaderData={putawayRearrangementTasksTableHeader}
      tableItemsData={getPutawayRearrangementTasksTableData(data.list)}
      totalItems={data.total}
      handleTableActions={handleTableActions}
      tableLoading={isLoadingPutawayRearrangementTasks}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination
    />
  );
}

export default PutawayRearrangementTasks;
