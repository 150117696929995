import moment from "moment";

export const isTodayDate = (date: Date | string) => {
  return moment().format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD");
};

export const isTomorrowDate = (date: Date | string) => {
  return (
    moment().add(1, "day").format("YYYY-MM-DD") ===
    moment(date).format("YYYY-MM-DD")
  );
};

export const getCurrentDate = () => {
  return moment().format("YYYY-MM-DD");
};

export const getCurrentTime = () => {
  return moment().format("HH:mm:ss");
};

export const formatDateToValidFormat = (date: string) => {
  return moment(date).format("YYYY-MM-DD");
};

export const getLastDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const endOfMonth = moment(date).endOf("month").format(mask);
  return endOfMonth;
};

export const getFirstDayOfMonth = (
  date: Date | string,
  withFullDate?: boolean,
) => {
  const mask = withFullDate ? "YYYY-MM-DD " : "DD";
  const startOfMonth = moment(date).startOf("month").format(mask);
  return startOfMonth;
};
