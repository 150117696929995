import React, { useEffect, useState } from "react";

import TextInput from "../../../../components/misc/TextInput";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { PackingBoxesListInterface } from "../../../../redux/interfaces/picking.interface";
import {
  getPrintAWBPackingBoxes,
  postValidateAWBVerification,
} from "../../../../redux/api/picking.api";
import CustomButton from "../../../../components/CustomButton";
import { resetPrintAwbPackingBoxes } from "../../../../redux/reducer/picking.reducer";
import LoadingIndicator from "../../../../components/misc/LoadingIndicator";

function withAwbBoxesNumberModal<P>(WrappedComponent: React.ComponentType<P>) {
  function AwbBoxesNumberModal(props: P) {
    const dispatch = useAppDispatch();
    const {
      picking: {
        printAwbPackingBoxes: { data, isLoadingPrintAwbPackingBoxes },
        validateAwbVerification: { isLoadingValidateAwbVerification },
      },
    } = useAppSelector((state) => ({
      picking: state.picking,
    }));

    const [boxesData, setBoxesData] = useState<PackingBoxesListInterface[]>([]);
    const [showBoxesModal, setShowBoxesModal] = useState(false);
    const [cellScanned, setCellScanned] = useState<string | number | null>(
      null,
    );

    useEffect(() => {
      if (data) {
        setBoxesData(data);
      }
    }, [data]);

    useEffect(() => {
      if (cellScanned) {
        dispatch(
          getPrintAWBPackingBoxes({
            params: {
              commandId: cellScanned,
            },
          }),
        );
      }
      return () => {
        if (!cellScanned) {
          dispatch(resetPrintAwbPackingBoxes());
        }
      };
    }, [dispatch, cellScanned]);

    const handleShowPrintAwbBoxesModal = (
      cellScannedValue?: string | number | null,
    ) => {
      if (!cellScannedValue) {
        setShowBoxesModal(false);
        setCellScanned(null);
        return;
      }
      setCellScanned(cellScannedValue);
      setShowBoxesModal(true);
    };

    const handleChangeBoxesQuantity = (
      value: string | number | null,
      idCutie: number,
    ) => {
      const parseValue =
        typeof value === "string" ? parseInt(value.toString(), 10) : value;

      const newBoxesData = boxesData.map((box) => {
        if (box.idCutie === idCutie) {
          return {
            ...box,
            cantitate: parseValue,
          };
        }
        return box;
      });
      setBoxesData(newBoxesData);
    };

    const handleValidateCommand = () => {
      if (!cellScanned) {
        return;
      }
      const transferData = {
        op: cellScanned,
        cutii: boxesData,
      };

      dispatch(
        postValidateAWBVerification({
          params: transferData,
          callback: () => {
            handleShowPrintAwbBoxesModal(null);
          },
        }),
      );
    };

    const disableSaveButton =
      !boxesData.some((box) => box.cantitate) ||
      isLoadingValidateAwbVerification;

    return (
      <>
        <ModalWrapper
          title={"Cutii ambalare"}
          show={showBoxesModal}
          handleClose={() => handleShowPrintAwbBoxesModal(null)}>
          {isLoadingPrintAwbPackingBoxes && (
            <div className="d-flex justify-content-center">
              <LoadingIndicator size={50} color="primary" />
            </div>
          )}
          {!isLoadingPrintAwbPackingBoxes &&
            boxesData?.map(
              ({ idCutie, nume, dimensiuni, greutate, cantitate }) => {
                return (
                  <div key={`${idCutie}`}>
                    <TextInput
                      label={`${nume} (${dimensiuni} ${greutate})`}
                      name={`${idCutie}+${nume}`}
                      id={`${idCutie}+${nume}`}
                      value={cantitate}
                      type="number"
                      onChange={(value) =>
                        handleChangeBoxesQuantity(value, idCutie)
                      }
                    />
                  </div>
                );
              },
            )}
          <div className="d-flex justify-content-end">
            <CustomButton
              variant="contained"
              onClick={handleValidateCommand}
              loading={isLoadingValidateAwbVerification}
              disabled={disableSaveButton || isLoadingValidateAwbVerification}>
              Salveaza
            </CustomButton>
          </div>
        </ModalWrapper>
        <WrappedComponent
          {...props}
          handleShowPrintAwbBoxesModal={handleShowPrintAwbBoxesModal}
        />
      </>
    );
  }
  return AwbBoxesNumberModal;
}

export default withAwbBoxesNumberModal;
