import { createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest, errorFormatHandler } from "../../helpers/apiHelper";

export const fetchUsers = createAsyncThunk(
  "/admin/users",
  async (
    transferData: {
      page: number;
      perPage: number;
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { page, perPage } = transferData;
      const { data } = await apiRequest(
        `/showAllUsers?page=${page ?? 1}&per_page=${perPage ?? 5}`,
        "GET",
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/users", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserDetails = createAsyncThunk(
  "/admin/user",
  async (userId: string, { rejectWithValue }) => {
    try {
      const { data } = await apiRequest(`/returnUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err));
    }
  },
);

export const postUserDetails = createAsyncThunk(
  "/admin/user",
  async (
    {
      userId,
      userDetails,
    }: {
      userId: string | null;
      userDetails: {
        userName: string;
        firstName: string;
        lastName: string;
        password: string;
      };
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(`/register`, "POST", {
        userId,
        ...userDetails,
      });

      return data;
    } catch (err: any) {
      console.warn("/admin/user", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postDeleteUser = createAsyncThunk(
  "/admin/deleteUser",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/deleteUser/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/deleteUser", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsWeb = createAsyncThunk(
  "/admin/fetchUserPermissionsWeb",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteWebApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsWeb = createAsyncThunk(
  "/admin/postUserPermissionsWeb",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
        subRoutes: {
          idRoute: number;
          isChecked: boolean;
        }[];
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest("addUserRoutes", "POST", transferData);

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsWeb", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const fetchUserPermissionsMobile = createAsyncThunk(
  "/admin/fetchUserPermissionsMobile",
  async (userId: string, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await apiRequest(`/ruteMobileApp/${userId}`, "GET");

      return data;
    } catch (err: any) {
      console.warn("/admin/fetchUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);

export const postUserPermissionsMobile = createAsyncThunk(
  "/admin/postUserPermissionsMobile",
  async (
    transferData: {
      userId: string;
      permissions: {
        idRoute: number;
      }[];
    },
    { rejectWithValue, dispatch },
  ) => {
    try {
      const { data } = await apiRequest(
        "addUserRoutesMobile",
        "POST",
        transferData,
      );

      return data;
    } catch (err: any) {
      console.warn("/admin/postUserPermissionsMobile", err);
      return rejectWithValue(errorFormatHandler(err, dispatch));
    }
  },
);
