import { LoadingButton } from "@mui/lab";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentCard from "../../components/misc/ContentCard";
import TransferList from "../../components/misc/TransferableLists";
import { checkEndpointStatus, endpointStatus } from "../../helpers/apiHelper";
import useRoutesHelper from "../../hooks/useRoutesHelper";
import {
  getInvoiceDetails,
  postInsertInvoice,
} from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { InvoiceDetailsItem } from "../../redux/interfaces/receipt.interface";
import { clearInvoiceDetails } from "../../redux/reducer/receipt.reducer";
import { receptionRoutes } from "../../router/routesConstants";
import Text from "../../styleguide/Text";
import Grid from "../../theme/Grid";
import { dateToString } from "../../utils/dateAndTime";
import Colors from "../../theme/Colors";

function AttachInvoice() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    receipt: { invoiceDetails, insertInvoice },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const finalizedReception =
    invoiceDetails.data &&
    (invoiceDetails.data?.status === 2 || invoiceDetails.data?.status > 3);

  const [invoiceDetailsData, setInvoiceDetailsData] = useState<
    InvoiceDetailsItem[]
  >([]);
  const [invoicesDetails, setInvoicesDetails] = useState<{
    numeFurnizor: string | null;
    facturiAdaugate: string[] | null;
    createdAt: Date | null;
  } | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const [leftList, setLeftList] = useState<any[]>([]);
  const [rightList, setRightList] = useState<any[]>([]);
  const [allInvoices, setAllInvoices] = useState<any[]>([]);

  const { routeParams }: any = useRoutesHelper();
  const providerId = routeParams?.idFurnizor;
  const fluxId = routeParams?.idFlux;

  useEffect(() => {
    if (invoiceDetails.data?.facturiDepo && invoiceDetails.data?.facturiWms) {
      const wmsInvoicedCodeToUpperCase = invoiceDetails.data.facturiWms.map(
        (invoice) => {
          return {
            ...invoice,
            codFactura: invoice.codFactura.toString().toUpperCase(),
          };
        },
      );

      const depoInvoicedCodeToUpperCase = invoiceDetails.data.facturiDepo.map(
        (invoice) => {
          return {
            ...invoice,
            codFactura: invoice.codFactura.toString().toUpperCase(),
          };
        },
      );

      const allAvailableInvoices = [
        ...wmsInvoicedCodeToUpperCase,
        ...depoInvoicedCodeToUpperCase,
      ];
      const leftListItems =
        depoInvoicedCodeToUpperCase.map((depo) => depo.codFactura) ?? [];

      const rightListItems =
        wmsInvoicedCodeToUpperCase.map((wms) => wms.codFactura) ?? [];

      const addedInvoices = invoiceDetails.data.facturiAdaugate.map(
        (invoices) => {
          return invoices.toUpperCase();
        },
      );

      setInvoiceDetailsData(allAvailableInvoices);
      setAllInvoices(allAvailableInvoices);
      setLeftList(leftListItems);
      setRightList(rightListItems);
      setInvoicesDetails({
        numeFurnizor: invoiceDetails.data.numeFurnizor,
        facturiAdaugate: addedInvoices,
        createdAt: invoiceDetails.data.createdAt,
      });
      setIsLoading(false);
    }
  }, [invoiceDetails]);

  useEffect(() => {
    if (providerId && fluxId) {
      dispatch(
        getInvoiceDetails({
          providerId,
          fluxId,
        }),
      );
    }
  }, [dispatch, providerId, fluxId]);

  useEffect(() => {
    return () => {
      dispatch(clearInvoiceDetails());
    };
  }, [dispatch]);

  const handleSubmit = async (finish?: boolean) => {
    if (rightList.length <= 0) {
      return;
    }
    const confirmedInvoices = invoiceDetailsData.filter((invoice) => {
      return rightList.includes(invoice.codFactura);
    });
    const transferData = {
      finalize: finish,
      idFluxReceptie: fluxId,
      facturi: confirmedInvoices,
    };
    const resultAction = await dispatch(postInsertInvoice(transferData));

    if (
      checkEndpointStatus(resultAction, postInsertInvoice) ===
      endpointStatus.fulfilled
    ) {
      navigate(`/${receptionRoutes.reception}`, { replace: true });
    }
  };

  const invoiceDetailsParser = (item: string, crudListItems?: any[] | null) => {
    const crudItem = crudListItems
      ? crudListItems.find(
          (crudItem) => crudItem?.codFactura?.toUpperCase() === item,
        )
      : item;

    const isHighlighted = invoicesDetails?.facturiAdaugate?.includes(item);

    if (!crudItem) {
      return null;
    }

    if (typeof crudItem === "string") {
      return (
        <div>
          <Text
            variant="subtitle2"
            color={
              isHighlighted ? Colors.green : Colors.black
            }>{`Factura cu codul ${crudItem}`}</Text>
        </div>
      );
    }

    return (
      <div>
        <Text
          variant="subtitle2"
          color={
            isHighlighted ? Colors.green : Colors.black
          }>{`Factura cu codul ${crudItem.codFactura}`}</Text>
        <Text variant="subtitle2">{`Data ${dateToString(
          crudItem.dataFactura,
        )}`}</Text>
      </div>
    );
  };

  const getPageTitle = () => {
    if (isLoading) {
      return "Se incarca facturile...";
    }
    return `Atașează factura recepției de la ${
      invoicesDetails?.numeFurnizor
    } in data de ${dateToString(invoicesDetails?.createdAt)}`;
  };

  const availableInvoices = leftList.filter((item) => {
    return invoicesDetails?.facturiAdaugate?.includes(item);
  });

  const leftListSortedByPriority = leftList.sort((a, b) => {
    const aIndex = availableInvoices.indexOf(a);
    const bIndex = availableInvoices.indexOf(b);
    return bIndex - aIndex;
  });

  const CardHeader = () => {
    if (isLoading || !invoicesDetails?.facturiAdaugate) {
      return <></>;
    }

    const alreadyAddedInvoices = invoicesDetails?.facturiAdaugate?.filter(
      (invoice) => {
        return leftList.includes(invoice) || rightList.includes(invoice);
      },
    );

    const notAddedInvoices = invoicesDetails?.facturiAdaugate?.filter(
      (invoice) => {
        return !leftList.includes(invoice) && !rightList.includes(invoice);
      },
    );

    return (
      <Box marginBottom={Grid[1]}>
        {/* <div className="d-flex flex-wrap"> */}

        <div className="d-flex">
          <Text variant="h5" gutterBottom className="me-1">
            Facturi introduse la receptie:
          </Text>
          {alreadyAddedInvoices?.map((invoice) => {
            return (
              <Text
                variant="h5"
                component="p"
                color={Colors.green}
                className="me-1">
                {`${invoice},`}
              </Text>
            );
          })}
          {notAddedInvoices?.map((invoice) => {
            return (
              <Text
                variant="h5"
                component="p"
                color={Colors.black}
                className="me-1">
                {`${invoice},`}
              </Text>
            );
          })}
        </div>

        {/* </div> */}
        <Text variant="h5" gutterBottom>
          {`Numar Total facturi alocate/disponibile: ${invoicesDetails?.facturiAdaugate.length}/${availableInvoices.length} `}
        </Text>
      </Box>
    );
  };

  return (
    <ContentCard CardHeader={CardHeader} cardTitle={getPageTitle()}>
      <TransferList
        isLoading={isLoading}
        leftListTitle="Facturi disponibile "
        rightListTitle="Facturi adaugate "
        leftListItems={leftListSortedByPriority}
        rightListItems={rightList}
        listCrudItems={allInvoices}
        alreadyCheckedItems={availableInvoices}
        listTitleParser={invoiceDetailsParser}
        setRightList={setRightList}
        setLeftList={setLeftList}
        disabled={!!finalizedReception}
      />
      {!isLoading && !finalizedReception && (
        <Box display={"flex"}>
          <LoadingButton
            type="submit"
            fullWidth
            className="btn btn-lg btn-primary w-100 mt-8 mx-1"
            onClick={() => handleSubmit(false)}
            loading={insertInvoice.isLoadingInsertInvoice}>
            <Text variant="body1" className="indicator-label" isBold>
              Salveaza
            </Text>
          </LoadingButton>
          <LoadingButton
            type="submit"
            fullWidth
            className="btn btn-lg btn-primary w-100 mt-8 mx-1"
            onClick={() => handleSubmit(true)}
            loading={insertInvoice.isLoadingInsertInvoice}>
            <Text variant="body1" className="indicator-label" isBold>
              Salveaza si finalizeaza
            </Text>
          </LoadingButton>
        </Box>
      )}
    </ContentCard>
  );
}

export default AttachInvoice;
