import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import { fetchUser } from "../redux/api/user.api";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { removeUserToken } from "../redux/reducer/auth.reducer";
import Layout from "./Layout";
import ProtectedRoute from "./ProtectedRoute";
import { initialMainRoute } from "./routesConstants";
import ScrollToTop from "./scrollToTop";
import useAppRoutes from "./useAppRoutes";

const MainRoutes = () => {
  // Redirect them to the /login page, but save the current location they were
  // trying to go to when they were redirected. This allows us to send them
  // along to that page after they login, which is a nicer user experience
  // than dropping them off on the home page.
  // let location = useLocation();
  // return <Navigate to="/login" state={{ from: location }} />;
  const dispatch = useAppDispatch();

  const { errorRoutes, allRoutes } = useAppRoutes();

  const {
    auth: { token },
  } = useAppSelector((state) => ({
    auth: state.auth,
  }));

  useEffect(() => {
    if (token) {
      dispatch(fetchUser());
    } else {
      dispatch(removeUserToken());
    }
  }, [dispatch, token]);

  return (
    <Router>
      <ScrollToTop />
      <Layout>
        <Routes>
          <Route path="/" element={<Navigate to={`/${initialMainRoute}`} />} />
          {errorRoutes.map(({ id, path, element }) => {
            return <Route key={id} path={path} element={element} />;
          })}
          <Route element={<ProtectedRoute isAuthorized={!!token} />}>
            <Route path={`/${initialMainRoute}`} element={<Home />}>
              <Route path=":userId" element={<Home />} />
            </Route>
            {allRoutes.map(({ id, path, element }) => {
              return <Route key={id} path={path} element={element} />;
            })}
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAuthorized={!token}
                redirectPath={`/${initialMainRoute}`}
              />
            }>
            <Route path={"/login"} element={<Login />} />;
            <Route path=":userId" element={<Home />} />
            {/* <Route path="/dashboard" element={<Home />}>
               <Route path=":userId" element={<Home />} />
            </Route> */}
          </Route>
        </Routes>
      </Layout>
    </Router>
  );
};

export default MainRoutes;
