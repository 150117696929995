import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ContentCard from "../../../components/misc/ContentCard";
import useRoutesHelper from "../../../hooks/useRoutesHelper";
import {
  getScheduleWorkingPointsDetails,
  getScheduleWorkingRoutes,
  postScheduleWorkingDays,
} from "../../../redux/api/expedition.api";
import { resetScheduleWorkingPointsDetails } from "../../../redux/reducer/expedition.reducer";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Text from "../../../styleguide/Text";
import ConfigWorkingDays, {
  AddAllDaysItemScheduleInterface,
} from "../components/ConfigWorkingDays";
import Dropdown, { DropdownValue } from "../../../components/misc/Dropdown";
import CustomButton from "../../../components/CustomButton";

function ExpeditionScheduleWorkingPointsOrganise() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();
  const id = routeParams?.id;
  const routeId = routeParams?.routeId;

  const {
    expedition: {
      scheduleWorkingPointsDetails: {
        data: scheduleWorkingPointsDetailsData,
        isLoadingScheduleWorkingPointsDetails,
      },
      scheduleWorkingRoutes: {
        data: scheduleWorkingRoutesData,
        isLoadingScheduleWorkingRoutes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const [selectedWorkingRoute, setSelectedWorkingRoute] =
    useState<DropdownValue | null>(null);

  const [selectedDays, setSelectedDays] = useState<
    AddAllDaysItemScheduleInterface[]
  >([]);

  useEffect(() => {
    if (id) {
      dispatch(
        getScheduleWorkingRoutes({
          page: 1,
          perPage: 500,
        }),
      );
      dispatch(getScheduleWorkingPointsDetails({ id: id }));
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (scheduleWorkingRoutesData?.routes.length > 0 && routeId) {
      const route = scheduleWorkingRoutesData?.routes.find(
        (route) => route.rutaId.toString() === routeId.toString(),
      );
      if (route?.rutaId) {
        setSelectedWorkingRoute({
          id: route.rutaId,
          label: route.denumire,
        });
      }
    }
  }, [scheduleWorkingRoutesData?.routes, routeId]);

  useEffect(() => {
    return () => {
      dispatch(resetScheduleWorkingPointsDetails());
    };
  }, [dispatch]);

  const onlyActiveDays = selectedDays.filter((item) => item.isActive);

  const handleSave = () => {
    const getWorkingRoute = scheduleWorkingRoutesData?.routes.find(
      (route) => route.rutaId === selectedWorkingRoute?.id,
    );
    if (getWorkingRoute) {
      dispatch(
        postScheduleWorkingDays({
          transferData: {
            activeWorkingDays: onlyActiveDays,
            id: id,
            selectedWorkingRoute: getWorkingRoute,
          },
          callback: () => {
            navigate(-1);
          },
        }),
      );
    }
  };

  const handleCancel = () => {
    navigate(-1);
  };

  const scheduleWorkingRoutesDataFormatted =
    scheduleWorkingRoutesData?.routes.map((route) => ({
      id: route.rutaId,
      label: route.denumire,
    }));

  return (
    <ContentCard
      isLoading={
        isLoadingScheduleWorkingRoutes || isLoadingScheduleWorkingPointsDetails
      }>
      <Text
        variant="h1"
        className="mb-5">{`Organizare punctul de lucru ${id}`}</Text>
      <Dropdown
        className="mb-4 me-2"
        title="Selecteaza ruta"
        data={scheduleWorkingRoutesDataFormatted}
        singleValue={selectedWorkingRoute}
        setSingleValue={setSelectedWorkingRoute}
      />
      <ConfigWorkingDays
        withoutTime
        alreadyCheckedDays={scheduleWorkingPointsDetailsData}
        setSelectedDays={setSelectedDays}
      />

      <div className="d-flex align-items-center justify-content-between p-5">
        <CustomButton variant="outlined" onClick={handleCancel}>
          <Text>Anuleaza</Text>
        </CustomButton>
        <CustomButton
          variant="contained"
          onClick={() => handleSave()}
          disabled={onlyActiveDays.length === 0}>
          <Text>Salveaza</Text>
        </CustomButton>
      </div>
    </ContentCard>
  );
}

export default ExpeditionScheduleWorkingPointsOrganise;
