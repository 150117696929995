const apiEnv =
  process.env.NODE_ENV !== "production"
    ? process.env.REACT_APP_TEST_API_URL
    : process.env.REACT_APP_PUBLIC_API_URL;

export const downloadNrcd = ({
  nrcdId,
  furnizor,
  data,
}: {
  nrcdId: number;
  furnizor: string;
  data: string;
}) => `${apiEnv}/carantinate/${nrcdId}?dld=1&furnizor=${furnizor}&data=${data}`;


