import React, { useEffect, useRef } from "react";
import ApexCharts, { ApexOptions } from "apexcharts";
import { getCSS, getCSSVariableValue } from "../../../assets/ts/_utils";

const PieChart = ({
  className,
  style,
  data,
  categories,
  colors,
  height,
  labelHoverFormatter,
  title,
  subtitle,
}: {
  className?: string;
  style?: React.CSSProperties;
  data?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  categories?: string[];
  colors?: string[];
  height?: number;
  labelHoverFormatter?: (value: string) => string;
  title?: string;
  subtitle?: string;
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions({
        height,
        data,
        categories,
        colors,
        labelHoverFormatter,
      }),
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [data, categories, colors, labelHoverFormatter]);

  return (
    <div className={`card ${className}`} style={style}>
      {/* begin::Header */}
      <div className="card-header border-0 pt-5">
        {/* begin::Title */}
        {(title || subtitle) && (
          <h3 className="card-title align-items-start flex-column">
            <span className="card-label fw-bolder fs-3 mb-1">{title}</span>
            <span className="text-muted fw-bold fs-7">{subtitle}</span>
          </h3>
        )}
        {/* end::Title */}
      </div>
      {/* end::Header */}

      {/* begin::Body */}
      <div className="card-body">
        {/* begin::Chart */}
        <div ref={chartRef} id="pieChart" style={{ height: height ?? 350 }} />
        {/* end::Chart */}
      </div>
      {/* end::Body */}
    </div>
  );
};

export default PieChart;

function getChartOptions({
  height,
  data,
  categories,
  colors,
  labelHoverFormatter,
}: {
  height: number;
  data?: ApexAxisChartSeries | ApexNonAxisChartSeries;
  categories?: string[];
  colors?: string[];
  labelHoverFormatter?: (value: string) => string;
}): ApexOptions {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-gray-200");

  return {
    series: data,
    labels: categories ?? ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
    chart: {
      fontFamily: "inherit",
      type: "donut",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          value: 0.1,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return labelHoverFormatter
            ? labelHoverFormatter(val.toString())
            : `${val}`;
        },
      },
    },
    colors: colors,
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
