import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { IncompleteInvoicesListInterface } from "../../../../redux/interfaces/expedition.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const incompleteInvoicesTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Cumparator",
    minW: 125,
  },
  {
    value: "Facturi",
    minW: 125,
  },
  {
    value: "Punct de livrare",
    minW: 125,
  },
  {
    value: "Data facturii",
    minW: 125,
  },

  {
    value: "Intocmit de",
    minW: 125,
  },
  {
    value: "Erori",
    minW: 125,
  },
];

export const getIncompleteInvoicesTableData: any = (
  data: IncompleteInvoicesListInterface[],
) =>
  data?.map((item) => {
    return {
      id: item.idFactura,
      crudData: item,
      data: [
        {
          title: item.cumparator,
        },
        {
          title: item.act,
        },
        {
          title: item.pctDeLivr ?? "-",
        },
        {
          badges: [
            {
              badgeText: `${dateToString(item.dataFacturii ?? "-")}`,
              badgeColor: "light-info",
            },
          ],
        },
        {
          title: item.intocmitId,
        },
        {
          badges: [
            {
              badgeText: !item.pl_id && "Punct de lucru",
              badgeColor: "light-danger",
            },
            {
              badgeText: !item.ruta_id && "Ruta",
              badgeColor: "light-danger",
            },
          ],
        },
      ],
    };
  });
