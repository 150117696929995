import React from "react";
import ContentCard from "../../../components/misc/ContentCard";
import { TableWidget } from "../../../components/tables/TableWidget";

import { getScheduleWorkingRoutes } from "../../../redux/api/expedition.api";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import PickingScheduleWorkingRoutesTableActionButtons from "./components/TableActionsButtonsComponents/ExpeditionScheduleWorkingRoutesTableActionButtons";
import {
  getExpeditionScheduleWorkingRoutesTableData,
  expeditionScheduleWorkingRoutesTableHeader,
} from "./components/tableConfigs/expeditionScheduleWorkingRoutesTableConfig";
import { TableActions } from "../../../components/tables/tableContext/TableContext";

function ExpeditionScheduleRoutes() {
  const dispatch = useAppDispatch();

  const {
    expedition: {
      scheduleWorkingRoutes: {
        data: scheduleWorkingRoutesData,
        isLoadingScheduleWorkingRoutes,
      },
    },
  } = useAppSelector((state) => ({
    expedition: state.expedition,
  }));

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getScheduleWorkingRoutes({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
        }),
      );
    }
  };

  return (
    <ContentCard>
      <TableWidget
        tableTitle={"Rute de transport"}
        tableHeaderData={expeditionScheduleWorkingRoutesTableHeader}
        tableItemsData={getExpeditionScheduleWorkingRoutesTableData(
          scheduleWorkingRoutesData?.routes,
        )}
        handleTableActions={handleTableActions}
        totalItems={scheduleWorkingRoutesData.nrTotal}
        tableLoading={isLoadingScheduleWorkingRoutes}
        ActionButtonsComponent={PickingScheduleWorkingRoutesTableActionButtons}
        showSearchForCategories={false}
        borderedRow
      />
    </ContentCard>
  );
}

export default ExpeditionScheduleRoutes;
