import { memo } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import * as pdfConstants from "../../constants/pdfConstants";
import { dateToString } from "../../../../utils/dateAndTime";
import CustomButton from "../../../../components/CustomButton";

import { KTSVG } from "../../../../helpers/KTSVG";
import { useAppDispatch } from "../../../../redux/hooks";
import { getRenumberProducts } from "../../../../redux/api/receipt.api";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";

function ReceptionTableActionButtons({
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();

  const itemStatusArr = crudData.statusZona
    .split(",")
    .map((item: any) => parseInt(item));

  const buttonNrcdEnabled = crudData?.buttonNrcdEnabled;
  const buttonQuarantineEnabled = crudData?.buttonQuarantineEnabled;
  const showProductRenumberingButton = itemStatusArr.includes(3);

  const buttonPreviewNrcdEnabled =
    itemStatusArr.includes(2) &&
    itemStatusArr.includes(3) &&
    itemStatusArr[itemStatusArr.length - 1] < 4;

  const receivedAt = dateToString(crudData?.createdAt);

  const handleEditReception = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.editReception}?idFurnizor=${crudData?.idFurnizor}&idFlux=${crudData?.idFluxReceptie}`;
  };

  const addInvoice = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.addInvoice}?idFurnizor=${crudData?.idFurnizor}&idFlux=${crudData?.idFluxReceptie}`;
  };

  const goToNrcd = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.nrcd}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToPreviewQuarantine = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.previewQuarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToQuarantine = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.quarantine}?reception=${crudData.idFluxReceptie}&createdAt=${crudData.createdAt}`;
  };

  const goToProducts = () => {
    return `/${receptionRoutes.reception}/${receptionRoutes.receivedProducts}?reception=${crudData.idFluxReceptie}&data=${receivedAt}`;
  };

  const handleDownloadNrcd = () => {
    return pdfConstants.downloadNrcd({
      nrcdId: crudData.idFluxReceptie,
      furnizor: crudData.numeFurnizor,
      data: receivedAt,
    });
  };

  const handleRenumberingProducts = () => {
    const callBack = () => {
      // tableContext.setTablePagination((prev: any) => ({ ...prev, page: 1 }));
    };
    dispatch(
      getRenumberProducts({
        idFluxReceptie: crudData.idFluxReceptie,
        callBack,
      }),
    );
  };

  return (
    <td className="text-start" style={{ width: 250 }}>
      {/* <CustomButton
        className="me-1 mb-2"
        variant="contained"
        color="primary"
        fullWidth
        href={goToProducts()}>
        <Text variant="caption" className="indicator-label" isBold>
          Produse receptionate
        </Text>
      </CustomButton>
      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        href={addInvoice()}>
        <Text variant="caption" className="indicator-label" isBold>
          Adauga factura
        </Text>
      </CustomButton>
      <CustomButton
        className="me-1  mb-2"
        variant="contained"
        color="primary"
        fullWidth
        disabled={!buttonNrcdEnabled}
        href={goToNrcd()}>
        <Text variant="caption" className="indicator-label" isBold>
          Vizualizare NIR
        </Text>
      </CustomButton>
      <CustomButton
        className="me-1 mb-2"
        color="primary"
        variant="contained"
        fullWidth
        disabled={!buttonQuarantineEnabled}
        href={goToQuarantine()}>
        <Text variant="caption" className="indicator-label" isBold>
          Vezi neconformități
        </Text>
      </CustomButton>
      <CustomButton
        className="me-1 mb-2"
        color="primary"
        variant="contained"
        fullWidth
        disabled={!buttonNrcdEnabled}
        withDefaultAnchor
        href={handleDownloadNrcd()}>
        <Text variant="caption" className="indicator-label" isBold>
          Descaca NIR
        </Text>
      </CustomButton> */}

      {/* start icons */}
      <CustomButton
        variant="contained"
        tooltipTitle="Produse receptionate"
        className="mx-1 mb-1"
        navigate={goToProducts()}>
        <KTSVG
          path="/media/svg/reception/inventory.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Adauga factura"
        className="mx-1 mb-1"
        navigate={addInvoice()}>
        <KTSVG
          path="/media/svg/reception/add-receipt.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>

      <CustomButton
        variant="contained"
        tooltipTitle="Vizualizare NIR"
        disabled={!buttonNrcdEnabled}
        className="mx-1 mb-1"
        navigate={goToNrcd()}>
        <KTSVG
          path="/media/svg/reception/analysis.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        disabled={!buttonPreviewNrcdEnabled}
        tooltipTitle="Previzualizare neconformitati"
        className="mx-1 mb-1"
        navigate={goToPreviewQuarantine()}>
        <KTSVG
          path="/media/svg/reception/nonconforming.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Vezi neconformitati"
        disabled={!buttonQuarantineEnabled}
        className="mx-1 mb-1"
        navigate={goToQuarantine()}>
        <KTSVG
          path="/media/svg/reception/nonconforming.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Descarca PV neconformitati"
        className="mx-1 mb-1"
        href={handleDownloadNrcd()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Editeaza receptia"
        className="mx-1 mb-1"
        navigate={handleEditReception()}>
        <KTSVG
          path="/media/svg/reception/document.svg"
          className="svg-icon-4"
          fill="white"
        />
      </CustomButton>
      {showProductRenumberingButton && (
        <CustomButton
          variant="contained"
          tooltipTitle="Deblocare receptie"
          className="mx-1 mb-1"
          disabled={buttonNrcdEnabled}
          withConfirmationModal={{
            modalTitle: `Vreti sa incepeti renumararea produselor pentru ${crudData.numeFurnizor}?`,
            modalSubtitle: "Acest proces poate dura cateva minute",
            modalLeftButtonText: "Nu",
            modalRightButtonText: "Da",
            modalLeftButtonColor: "primary",
            modalRightButtonColor: "error",
            modalRightButtonOnClick: handleRenumberingProducts,
          }}>
          <KTSVG
            path="/media/svg/reception/document.svg"
            className="svg-icon-4"
            fill="white"
          />
        </CustomButton>
      )}
    </td>
  );
}

export default memo(ReceptionTableActionButtons);
