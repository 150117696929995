import { dateToString } from "../../../../utils/dateAndTime";

export const quarantineTableHeader = [
  {
    value: "numeProdus",
    label: "Nume produs",
    minW: 150,
    withSearch: true,
  },
  {
    value: "codProdusDepo",
    label: "Cod CIM",
    minW: 125,
    withSearch: true,
  },
  {
    value: "lot",
    label: "Lot",
    minW: 160,
    withSearch: true,
  },
  {
    value: "Data expirare",
    minW: 160,
  },
  {
    value: "Data intrare",
    minW: 160,
  },
  {
    value: "Cantitate",
    minW: 150,
  },
  {
    value: "facturi",
    label: "Facturi",
    minW: 125,
    withSearch: true,
  },
  {
    value: "diferente",
    label: "Neconformitati",
    minW: 125,
  },
  {
    value: "Data receptiei",
    minW: 150,
  },
];

export const getQuarantineTableData: any = (quarantineList: any) =>
  quarantineList?.map((quarantineItem: any) => {
    const splittedLot = quarantineItem.lot?.split("-") ?? [];
    const lotWms = splittedLot[1] ?? "";
    const lotDepo = splittedLot[0] ?? "";
    return {
      id: quarantineItem.idCarantina,
      crudData: quarantineItem,
      data: [
        {
          title: quarantineItem.numeProdus,
        },
        {
          title: quarantineItem.codProdusDepo,
        },
        {
          title: [
            {
              text: `Faptic: ${lotWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${lotDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${dateToString(quarantineItem.dexpWms)}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${dateToString(quarantineItem.dexpDepo)}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          title: [
            {
              text: `Faptic: ${quarantineItem.cantWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${quarantineItem.cantDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          badges: quarantineItem.facturi?.map((factura: any) => ({
            badgeText: factura.codFactura,
            badgeColor: "light-info",
            style: { minWidth: "125px" },
          })),
        },
        {
          title: [
            {
              text: `Solutionate: ${quarantineItem.cantitateSolutionata}`,
              textClassName: "text-info",
            },
            {
              text: `Nesolutionate: ${
                Math.abs(quarantineItem.diferenta) -
                Math.abs(quarantineItem.cantitateSolutionata)
              }`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: dateToString(quarantineItem.receptionDate),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
