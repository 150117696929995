import React, { useEffect, useState } from "react";

import { Box } from "@mui/material";
import DatePicker from "../../../../components/misc/DatePicker";
import { dateForDatabase } from "../../../../utils/dateAndTime";

function TotalReceivedProductsHeader({ tableContext }: any) {
  const startDateWithoutNthMonths = new Date(
    new Date().setMonth(new Date().getMonth() - 2),
  );
  const [startDate, setStartDate] = useState<Date | null>(
    startDateWithoutNthMonths,
  );
  const [endDate, setEndDate] = useState<Date | null>(new Date());

  useEffect(() => {
    tableContext.handleTableSearch("startDate", dateForDatabase(startDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate]);

  useEffect(() => {
    tableContext.handleTableSearch("endDate", dateForDatabase(endDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  return (
    <Box className="d-flex justify-content-between fw-bolder text-muted bg-light w-100 p-5">
      <DatePicker
        label="De la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={startDate}
        handleChangeDate={(value) => setStartDate(value)}
      />
      <DatePicker
        label="Pana la"
        textInputClassName="bg-white"
        isRequired
        withVerticalSpacer
        fullWith={false}
        gutterBottom={false}
        maxDate={new Date()}
        value={endDate}
        handleChangeDate={(value) => setEndDate(value)}
      />
    </Box>
  );
}

export default TotalReceivedProductsHeader;
