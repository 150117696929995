import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../../components/tables/TableWidget";
import { receptionRoutes } from "../../../../router/routesConstants";
import Text from "../../../../styleguide/Text";

interface NrcdTableActionButtonProps {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  showViewButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
}

function NrcdTableActionButton({ crudData }: NrcdTableActionButtonProps) {
  return (
    <td className="text-end">
      {crudData.neconformitati?.length > 0 && (
        <ActionButtons
          neconformitati={crudData.neconformitati}
          crudData={crudData}
        />
      )}
    </td>
  );
}

const ActionButtons = ({
  crudData,
}: {
  neconformitati: {
    idType: number;
    type: string;
    cantitate: number;
    status: number;
    reasons: any[];
  }[];
  crudData: any;
}) => {
  const navigate = useNavigate();

  const quarantineProduct = () => {
    navigate(
      `/${receptionRoutes.reception}/${receptionRoutes.quarantine}?reception=${crudData.idFluxReceptie}`,
    );
  };

  // quarantined
  if (crudData.neconformitati?.length > 0) {
    return (
      <Button
        className="badge btn btn-primary btn-sm me-1"
        color="primary"
        fullWidth
        onClick={quarantineProduct}>
        <Text variant="body2" className="indicator-label" isBold>
          Vezi neconfirmitati
        </Text>
      </Button>
    );
  }

  return <></>;
};

export default NrcdTableActionButton;
