import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { MonitorQuarantineListInterface } from "../../../../redux/interfaces/receipt.interface";
import { dateToString } from "../../../../utils/dateAndTime";

export const monitorQuarantineTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Zona receptiei",
    minW: 125,
  },
  {
    value: "Nume furnizor",
    minW: 125,
  },
  {
    label: "Nume produs",
    value: "numeProdus",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Cod produs",
    value: "codProdus",
    withSearch: true,
    minW: 125,
  },
  {
    value: "Lot",
    minW: 125,
  },
  {
    value: "BBD",
    minW: 125,
  },
  {
    value: "Cantitate",
    minW: 125,
  },
  {
    value: "Data intrare",
    minW: 150,
  },
];

export const getMonitorQuarantineTableData: any = (
  monitorAndQuarantineList: MonitorQuarantineListInterface[],
) =>
  monitorAndQuarantineList?.map((monitorAndQuarantineItem) => {
    return {
      id: monitorAndQuarantineItem.idCarantina,
      crudData: monitorAndQuarantineItem,
      data: [
        {
          title: monitorAndQuarantineItem.denumireZona,
        },
        {
          title: monitorAndQuarantineItem.numeFurnizor,
        },
        {
          title: monitorAndQuarantineItem.numeProdus,
        },
        {
          title: monitorAndQuarantineItem.codProdusDepo,
        },
        {
          title: monitorAndQuarantineItem.lot,
        },
        {
          title: monitorAndQuarantineItem.bbd,
        },
        {
          title: [
            {
              text: `Faptic: ${monitorAndQuarantineItem.cantWms ?? "-"}`,
              textClassName: "text-info",
            },
            {
              text: `Scriptic: ${monitorAndQuarantineItem.cantDepo ?? "-"}`,
              textClassName: "text-muted",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: dateToString(monitorAndQuarantineItem.addedAt),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
