import React, { useEffect, useState } from "react";
import ContentCard from "../../../../components/misc/ContentCard";
import useRoutesHelper from "../../../../hooks/useRoutesHelper";
import TextInput from "../../../../components/misc/TextInput";
import CustomButton from "../../../../components/CustomButton";
import { useAppDispatch } from "../../../../redux/hooks";
import { postEditNodeDetails } from "../../../../redux/api/expedition.api";
import { useNavigate } from "react-router-dom";

function EditNodeRoutes() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { routeParams } = useRoutesHelper();
  const { nodId, driver, car } = routeParams || {};

  const [driverValue, setDriverValue] = useState("");
  const [carValue, setCarValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setDriverValue(driver);
    setCarValue(car);
  }, [driver, car]);

  const handleEditNode = () => {
    setIsLoading(true);

    const callBack = () => {
      setIsLoading(false);

      navigate(-1);
    };
    dispatch(
      postEditNodeDetails({
        callBack,
        params: {
          nodId: nodId,
          sofer: driverValue,
          auto: carValue,
        },
      }),
    );
  };

  return (
    <ContentCard
      cardTitle="Editeaza detalii nod"
      cardHeaderClassName="text-center"
      isLoadingClassName="d-flex flex-column justify-content-center align-items-center vh-75"
      CardHeader={() => <></>}>
      <TextInput
        name="driver"
        label="Sofer"
        value={driverValue}
        onChange={(value) => {
          if (value) {
            setDriverValue(value.toString());
          }
        }}
      />
      <TextInput
        name="car"
        label="Masina"
        value={carValue}
        onChange={(value) => {
          if (value) {
            setCarValue(value.toString());
          }
        }}
      />
      <CustomButton
        type="submit"
        fullWidth
        variant="contained"
        loading={isLoading}
        disabled={!driverValue || !carValue || isLoading}
        onClick={handleEditNode}>
        Salveaza
      </CustomButton>
    </ContentCard>
  );
}

export default EditNodeRoutes;
