import { memo } from "react";
import { TableItemId } from "../../../../components/tables/TableWidget";
import CustomButton from "../../../../components/CustomButton";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import {
  getGenerateBarcodeNodeDetails,
  getGenerateBorderouNodeDetails,
  getPreviewBorderou,
} from "../../../../redux/api/expedition.api";
import { useAppDispatch } from "../../../../redux/hooks";
import { useNavigate } from "react-router-dom";
import { expeditionRoutes } from "../../../../router/routesConstants";

function NodesRouteTableActionButtons({
  itemId,
  crudData,
}: {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  tableContext?: any;
}) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const generateBarcode = () => {
    dispatch(getGenerateBarcodeNodeDetails({ idNode: itemId }));
  };

  const generateBorderou = () => {
    dispatch(getGenerateBorderouNodeDetails({ nodeName: crudData.nodNume }));
  };

  const handleEditNod = () => {
    navigate(
      `/${expeditionRoutes.expedition}/${expeditionRoutes.editNodesRoutes}?driver=${crudData.sofer}&car=${crudData.auto}&nodId=${itemId}`,
    );
    return;
  };

  return (
    <td className="text-start" style={{ width: 250 }}>
      <CustomButton
        variant="contained"
        tooltipTitle="Genereaza borderou"
        className="mx-1 mb-1"
        onClick={handleEditNod}>
        Editeaza
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Genereaza eticheta"
        className="mx-1 mb-1"
        withConfirmationModal={{
          modalTitle: `Genereaza eticheta pentru nodul ${crudData.nodNume}`,
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: generateBarcode,
        }}>
        {/* <KTSVG
          path="/media/svg/general/barcode.svg"
          className="svg-icon-1"
          fill="white"
        /> */}
        Eticheta
      </CustomButton>
      <CustomButton
        variant="contained"
        tooltipTitle="Genereaza borderou"
        className="mx-1 mb-1"
        withConfirmationModal={{
          modalTitle: `Genereaza borderou pentru nodul ${crudData.nodNume}`,
          modalLeftButtonColor: "inherit",
          modalRightButtonOnClick: generateBorderou,
          modalLeftButtonOnClick: () => getPreviewBorderou(crudData.nodNume),
          modalRightButtonText: "Genereaza",
          modalLeftButtonText: "Preview",
        }}>
        Borderou
      </CustomButton>
    </td>
  );
}

export default memo(NodesRouteTableActionButtons);
