import { Button } from "@mui/material";
import { useState } from "react";
import { TableItemType } from "../../../../components/tables/tableContext/TableContext";
import { TableItemId } from "../../../../components/tables/TableWidget";
import Text from "../../../../styleguide/Text";
import QuarantineSolutionsModal from "../QuarantineSolutionsModal";
import QuarantineSolutionResolveModal from "../QuarantineSolutionResolveModal/QuarantineSolutionResolveModal";

interface QuarantineTableActionButtonProps {
  itemId: TableItemId;
  tableItemData: TableItemType[];
  crudData?: any;
  showViewButton?: boolean;
  showEditButton?: boolean;
  showDeleteButton?: boolean;
}

function QuarantineTableActionButton({
  crudData,
}: QuarantineTableActionButtonProps) {
  const [showSolutionResolveModal, setShowSolutionResolveModal] =
    useState(false);
  const [showSolutionsModal, setShowSolutionsModal] = useState(false);

  const toggleSolutionResolveModal = (showModal = false) => {
    setShowSolutionResolveModal(showModal);
  };

  const onQuarantineResolvePress = () => {
    setShowSolutionResolveModal(true);
  };

  const toggleSolutionsModal = (showModal = false) => {
    setShowSolutionsModal(showModal);
  };

  const onQuarantinePress = () => {
    setShowSolutionsModal(true);
  };

  const showResolveQuarantineButton =
    crudData?.cantitateSolutionata < Math.abs(crudData?.diferenta);

  const showSolutionsButton = crudData?.cantitateSolutionata > 0;

  return (
    <td className="text-end">
      {!crudData?.solutionare && (
        <>
          {showSolutionResolveModal && (
            <QuarantineSolutionResolveModal
              showModal={showSolutionResolveModal}
              toggleModal={toggleSolutionResolveModal}
              crudData={crudData}
            />
          )}
          {showSolutionsButton && (
            <QuarantineSolutionsModal
              showModal={showSolutionsModal}
              toggleModal={toggleSolutionsModal}
              crudData={crudData}
            />
          )}
          {showResolveQuarantineButton && (
            <Button
              className="badge btn btn-primary btn-sm me-1"
              color="primary"
              fullWidth
              onClick={onQuarantineResolvePress}>
              <Text variant="body2" className="indicator-label" isBold>
                Solutionare neconfirmitati
              </Text>
            </Button>
          )}
          {showSolutionsButton && (
            <Button
              className="badge btn btn-primary btn-sm me-1 mt-2"
              color="primary"
              fullWidth
              onClick={onQuarantinePress}>
              <Text variant="body2" className="indicator-label" isBold>
                Vezi solutionari
              </Text>
            </Button>
          )}
        </>
      )}
    </td>
  );
}

export default QuarantineTableActionButton;
