import CustomButton from "../../../../../components/CustomButton";
import { receptionRoutes } from "../../../../../router/routesConstants";

function ReturningTasksActionButton({ itemId, crudData }: any) {
  return (
    <div>
      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${receptionRoutes.returns}/${receptionRoutes.createReturnForm}?id=${itemId}`}>
        Formular retur
      </CustomButton>

      <CustomButton
        variant="contained"
        className="mt-1 me-1"
        navigate={`/${receptionRoutes.returns}/${receptionRoutes.returnDifferences}?id=${itemId}&pctDeLivr=${crudData.pctDeLivr}`}>
        Diferente
      </CustomButton>
    </div>
  );
}

export default ReturningTasksActionButton;
