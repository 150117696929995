import { Dispatch, SetStateAction } from "react";
import ContentCard from "../../../../components/misc/ContentCard";
import Checkbox from "../../../../components/misc/Checkbox";
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import TextInput from "../../../../components/misc/TextInput";
import { ReturnInvoicesDataInterface } from "../../../../redux/interfaces/returning.interface";
import FormButtons from "../../../../components/misc/FormButtons";

import { returnReasonConstants } from "..";

const formDetailsContant = [
  {
    name: "returnToDelivery",
    label: "Retur la livrare",
  },
  {
    name: "packageLeftTheWerehouse",
    label: "Marfa a parasit depozitul",
  },
];

function FormDetails({
  handleCurrentStep,
  fieldValue,
  setFieldValue,
}: {
  handleCurrentStep: (callBack: (value: number) => number) => void;
  fieldValue: {
    returnToDelivery: boolean;
    packageLeftTheWerehouse: boolean;
    reason: {
      name: string;
      label: string;
      value?: string;
    };
    selectedProducts: ReturnInvoicesDataInterface[];
  };
  setFieldValue: Dispatch<
    SetStateAction<{
      returnToDelivery: boolean;
      packageLeftTheWerehouse: boolean;
      reason: {
        name: string;
        label: string;
        value?: string;
      };
      selectedProducts: ReturnInvoicesDataInterface[];
    }>
  >;
}) {
  const handleGoBack = () => {
    handleCurrentStep((prev) => prev - 1);
  };

  const handleGoNext = () => {
    handleCurrentStep((prev) => prev + 1);
  };

  return (
    <div>
      <ContentCard>
        {formDetailsContant.map((formDetail, index) => {
          return (
            <div key={`checkbox-${index}`} className="d-flex mb-4">
              <Checkbox
                className="d-flex justify-content-left"
                label={formDetail.label}
                name={formDetail.name}
                labelAlign="right"
                onChange={(checked) =>
                  setFieldValue((prev) => ({
                    ...prev,
                    [formDetail.name]: checked,
                  }))
                }
                // @ts-ignore
                checked={fieldValue[formDetail.name]}
              />
            </div>
          );
        })}
      </ContentCard>

      <ContentCard>
        <FormControl>
          <FormLabel id="return-reason-radio">Motivul returnarii</FormLabel>
          <RadioGroup
            aria-labelledby="return-reason-radio"
            defaultValue="female"
            name="radio-buttons-group"
            className="d-flex"
            value={fieldValue.reason.name}
            onChange={(e) => {
              const selectedReason = returnReasonConstants.find(
                (reason) => reason.name === e.target.value,
              );
              setFieldValue((prev) => ({
                ...prev,
                reason: selectedReason || prev.reason,
              }));
            }}>
            {returnReasonConstants.map((returnReason) => {
              return (
                <FormControlLabel
                  key={returnReason.name}
                  value={returnReason.name}
                  control={<Radio />}
                  label={returnReason.label}
                />
              );
            })}
          </RadioGroup>
        </FormControl>

        {fieldValue.reason.name === "other" && (
          <TextInput
            className="mt-4"
            label="Adauga motiv"
            name="AddInvoice"
            value={fieldValue.reason.value}
            touched={
              fieldValue.reason.name === "other" && !fieldValue.reason.label
            }
            error="Campul este obligatoriu"
            onChange={(value) =>
              setFieldValue((prev) => ({
                ...prev,
                reason: { ...prev.reason, value: value?.toString() ?? "" },
              }))
            }
          />
        )}
      </ContentCard>

      <FormButtons
        goBackLabel="Inapoi"
        goNextLabel="Inainte"
        handleGoBack={handleGoBack}
        handleGoNext={handleGoNext}
      />
    </div>
  );
}

export default FormDetails;
