import { useState, useEffect } from "react";
import { KTSVG } from "../../../helpers/KTSVG";
import { useDebounce } from "../../../hooks/useDebounce";
import useTableCtx from "../tableContext/useTableCtx";

const TableSearch = ({
  value,
  withoutSearchIcon,
  withoutSearchClear,
}: {
  value: string;
  withoutSearchIcon?: boolean;
  withoutSearchClear?: boolean;
}) => {
  const { handleTableSearch } = useTableCtx()!;
  const [searchTerm, setSearchTerm] = useState<string | null>(null);
  // Debounce search term so that it only gives us latest value ...
  // ... if searchTerm has not been updated within last 500ms.
  // The goal is to only have the API call fire when user stops typing ...
  // ... so that we aren't hitting our API rapidly.
  const debouncedSearchTerm = useDebounce(searchTerm, 400);
  // Effect for API call
  useEffect(
    () => {
      if (
        debouncedSearchTerm !== undefined &&
        debouncedSearchTerm !== null &&
        searchTerm !== undefined
      ) {
        handleTableSearch(value, debouncedSearchTerm);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [debouncedSearchTerm], // Only call effect if debounced search term changes
    // More details about useDebounce: https://usehooks.com/useDebounce/
  );

  return (
    <div>
      {/* begin::Search */}
      <div className="d-flex align-items-center position-relative">
        {!withoutSearchIcon && (
          <KTSVG
            path="/media/icons/duotune/general/gen021.svg"
            className="svg-icon-1 position-absolute ms-2"
          />
        )}
        <input
          type="text"
          data-kt-user-table-filter="search"
          className={`form-control form-control-solid bg-white py-2 ${
            withoutSearchIcon ? "ps-4" : "ps-9"
          }`}
          // placeholder='Search'
          value={searchTerm || ""}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        {searchTerm && searchTerm.length > 0 && !withoutSearchClear && (
          <button
            className="position-absolute end-0 cursor-pointer btn btn-icon rounded-circle"
            onClick={() => setSearchTerm("")}>
            <KTSVG
              path="/media/icons/duotune/general/gen040.svg"
              className="svg-icon-1"
            />
          </button>
        )}
      </div>
      {/* end::Search */}
    </div>
  );
};

export { TableSearch };
