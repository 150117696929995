import { useState, useEffect } from "react";

import CustomButton from "../../../../components/CustomButton";
import TextInput from "../../../../components/misc/TextInput";
import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { useAppDispatch } from "../../../../redux/hooks";
import Text from "../../../../styleguide/Text";
import Colors from "../../../../theme/Colors";
import {
  DepositDataInterface,
  DepositStockInterface,
} from "../../../../redux/interfaces/inventory.interface";
import { postDepositStocksTransfer } from "../../../../redux/api/inventory.api";

interface SplitQuantityInterface extends DepositDataInterface {
  quantity?: string;
}

const DepositStocksTransferModal = ({
  productData,
  isSplitModalOpen,
  setIsSplitModalOpen,
  tableFilterData,
}: {
  productData: DepositStockInterface;
  isSplitModalOpen: boolean;
  setIsSplitModalOpen: (show: boolean) => void;
  tableFilterData: any;
}) => {
  const dispatch = useAppDispatch();
  const [splitQuantitys, setSplitQuantitys] = useState<
    SplitQuantityInterface[]
  >([]);

  useEffect(() => {
    if (isSplitModalOpen) {
      setSplitQuantitys(productData.depozit);
    } else {
      setSplitQuantitys([]);
    }
  }, [isSplitModalOpen, productData.depozit]);

  const handleSplitQuantity = (value: any, depId: any) => {
    const newSplitQuantitys = splitQuantitys.map((depo) => {
      if (depo.idDepo === depId) {
        return { ...depo, quantity: value };
      }
      return { quantity: "", ...depo };
    });

    setSplitQuantitys(newSplitQuantitys);
  };

  const handleSaveSplit = () => {
    dispatch(
      postDepositStocksTransfer({
        product: productData,
        deposits: splitQuantitys,
        filters: tableFilterData,
      }),
    );
  };

  const totalQuantityAdded = splitQuantitys.reduce((acc: number, curr: any) => {
    const quantity = curr?.quantity || 0;
    return acc + parseInt(quantity);
  }, 0);

  const isSubmitDisabled = totalQuantityAdded > productData?.cantitate;

  return (
    <ModalWrapper
      title={`Impartire produs ${productData?.numeProdusErp}`}
      show={isSplitModalOpen}
      handleClose={() => setIsSplitModalOpen(false)}>
      {splitQuantitys?.map((dep) => {
        return (
          <TextInput
            key={dep.idDepo}
            type="number"
            label={`Depozit ${dep.codDep}`}
            name={dep.codDep}
            value={dep.quantity ?? ""}
            onChange={(value) => handleSplitQuantity(value, dep.idDepo)}
          />
        );
      })}
      <Text variant="h3" color={isSubmitDisabled ? Colors.red : Colors.black}>
        {`Cantitate ${totalQuantityAdded}/${productData?.cantitate}`}
      </Text>

      <CustomButton
        type="submit"
        variant="contained"
        fullWidth
        className="btn btn-lg btn-primary w-100 mt-5"
        disabled={isSubmitDisabled}
        onClick={handleSaveSplit}>
        <Text variant="body1" className="indicator-label" isBold>
          Salveaza
        </Text>
      </CustomButton>
    </ModalWrapper>
  );
};

export default DepositStocksTransferModal;
