import React, { useCallback, useRef, useState } from "react";
import {
  QrCodeObjectInterface,
  getQrCodeData,
} from "../../../../helpers/qrCodeFormater";
import { useAppDispatch } from "../../../../redux/hooks";
import {
  resetVerifyCommandProducts,
  setVerifyCommand,
} from "../../../../redux/reducer/picking.reducer";
import TextInput from "../../../../components/misc/TextInput";
import VerifyCommandProductsButtons from "./components/VerifyCommandProductsButtons";
import useVerifyCommandProductsConfig from "./hooks/useVerifyCommandProductsConfig";
import VerifyCommandTable from "./components/VerifyCommandTable";
import ContentCard from "../../../../components/misc/ContentCard";

function VerifyCommandProducts() {
  const dispatch = useAppDispatch();

  const productCodeInputRef = useRef<HTMLInputElement>(null);

  const [productScannedCode, setProductScannedCode] = useState<
    string | number | null
  >("");

  const [qrCodeParams, setQrCodeParams] =
    useState<QrCodeObjectInterface | null>(null);

  const handleFocusProductScannedInput = useCallback(() => {
    productCodeInputRef.current?.focus();
  }, []);

  const {
    verifyCommandProductsData,
    isLoadingVerifyCommandProducts,
    verificationZone,
  } = useVerifyCommandProductsConfig({
    qrCodeParams,
  });

  const handleClearCommandData = useCallback(() => {
    dispatch(resetVerifyCommandProducts());
    dispatch(setVerifyCommand({ verificationZone: "" }));
    setQrCodeParams(null);
  }, [dispatch]);

  const handleProductScannedCode = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => {
    // @ts-ignore
    const targetValue = event.target.value;
    if (targetValue && event.key == "Enter") {
      const debouncedProductScannedValueToString = targetValue.toString();
      if (debouncedProductScannedValueToString.length >= 5) {
        const qrCodeData = getQrCodeData(targetValue.toString().trim());
        setQrCodeParams(qrCodeData);
        setProductScannedCode("");
        handleFocusProductScannedInput();
      }
    }
  };

  return (
    <>
      {verificationZone && !isLoadingVerifyCommandProducts && (
        <ContentCard>
          <TextInput
            label="Cauta produs"
            id="productScannInput"
            name="productScannInput"
            value={productScannedCode}
            // externalInputRef={productCodeInputRef}
            onChange={setProductScannedCode}
            autoFocus
            // onKeyDown={handleProductScannedCode}
            onKeyDown={(event) => {
              // @ts-ignore
              const targetValue = event.target.value;
              if (targetValue && event.key == "Enter") {
                handleProductScannedCode(event);
              }
            }}
          />
          <VerifyCommandTable
            verifyCommandProductsList={verifyCommandProductsData.list}
            verificationZone={verificationZone}
            qrCodeParams={qrCodeParams}
            handleFocusProductScannedInput={handleFocusProductScannedInput}
          />
        </ContentCard>
      )}
      <VerifyCommandProductsButtons
        verificationZone={verificationZone}
        verifyCommandProductsList={verifyCommandProductsData.list}
        handleClearCommandData={handleClearCommandData}
      />
      <TextInput
        className="position-fixed"
        style={{ opacity: 0, pointerEvents: "none" }}
        label="Cauta produs"
        id="productScannInput"
        name="productScannInput"
        value={productScannedCode}
        externalInputRef={productCodeInputRef}
        onChange={setProductScannedCode}
        onKeyDown={handleProductScannedCode}
      />
    </>
  );
}

export default VerifyCommandProducts;
