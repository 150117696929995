import React, { useState, useMemo, useCallback } from "react";
import {
  TableContext,
  TableData,
  TableFilterDataItem,
  TableHeaderCategoryConfig,
} from "./TableContext";

type Props = {
  children: React.ReactNode;
};

const TableProvider = ({ children }: Props) => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [tableHeader, setTableHeader] = useState<TableHeaderCategoryConfig[]>(
    [],
  );
  const [tablePagination, setTablePagination] = useState({
    page: 1,
    rowsPerPage: 25,
  });
  const [tableSearch, setTableSearch] = useState<object | null>(null);
  const [tableFilter, setTableFilter] = useState<object | null>(null);
  const [tableGlobalSearch, setTableGlobalSearch] = useState("");
  const [tableGlobalFilter, setTableGlobalFilter] = useState("");
  const [globalFilterOptions, setGlobalFilterOptions] = useState<
    null | TableFilterDataItem[]
  >(null);
  const [selectedItems, setSelectedItems] = useState<any[]>([]);

  const deleteItem = (id: string | number) => {
    setTableData(tableData.filter((item) => item.id !== id));
  };

  const handleTableFilter = useCallback(
    (key: string, value: string | number) => {
      if (
        typeof key === "string" &&
        (typeof value === "string" || typeof value === "number")
      ) {
        setTableFilter((prev) => ({ ...prev, [key]: value }));
        setTablePagination((prev) => ({ ...prev, page: 1 }));
      }
    },
    [],
  );

  const handleTableRouteParams = useCallback((pageParams: any) => {
    if (pageParams?.page || pageParams?.rowsPerPage) {
      setTablePagination((prev) => ({ ...prev, ...pageParams }));
    }
    setTableFilter((prev) => ({ ...prev, ...pageParams }));
    setTableSearch((prev) => ({ ...prev, ...pageParams }));
  }, []);

  const handleTableSearch = (key: string, value: string | number) => {
    if (
      typeof key === "string" &&
      (typeof value === "string" || typeof value === "number")
    ) {
      setTableSearch((prev) => ({ ...prev, [key]: value }));
      setTablePagination((prev) => ({ ...prev, page: 1 }));
    }
  };

  const handleCheckboxClick = (
    crudData: any,
    checkboxId?: string | number,
    tableItemId?: string | number,
  ) => {
    if (crudData) {
      const itemId = checkboxId ?? tableItemId ?? crudData?.id;

      setSelectedItems((prev) => {
        if (prev.some((item) => item.id === itemId)) {
          return prev.filter((item) => item.id !== itemId);
        } else {
          return [...prev, { id: itemId, ...crudData }];
        }
      });
    }
  };

  const handleCheckAllItems = () => {
    if (selectedItems.length === tableData.length) {
      setSelectedItems([]);
      return;
    }
    const allItems = tableData.map((item) => item.crudData);
    setSelectedItems(allItems);
  };

  const tableDataWithCheckedItems = useMemo(
    () =>
      tableData.map((item) => {
        return {
          ...item,
          data: item.data.map((tableItem) => {
            if (tableItem.checkbox) {
              return {
                ...tableItem,
                checkbox: {
                  ...tableItem.checkbox,
                  isSelected: selectedItems.some(
                    (selectedItem) => selectedItem.id === item.id,
                  ),
                },
              };
            }
            return tableItem;
          }),
        };
      }),
    [tableData, selectedItems],
  );

  return (
    <TableContext.Provider
      value={{
        tableData: tableDataWithCheckedItems,
        setTableData,
        tableHeader,
        setTableHeader,
        tablePagination,
        setTablePagination,
        tableSearch,
        handleTableSearch,
        tableFilter,
        handleTableFilter,
        deleteItem,
        tableGlobalSearch,
        setTableGlobalSearch,
        tableGlobalFilter,
        setTableGlobalFilter,
        globalFilterOptions,
        setGlobalFilterOptions,
        handleCheckboxClick,
        selectedItems,
        setSelectedItems,
        handleCheckAllItems,
        handleTableRouteParams,
      }}>
      {children}
    </TableContext.Provider>
  );
};

export default TableProvider;
