import { Box } from "@mui/material";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../../components/tables/TableWidget";
import { getPutawayTasks } from "../../../redux/api/putaway.api";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import {
  putawayTasksTableHeader,
  getPutawayTasksTableData,
} from "./tableConfigs/putawayTableConfig";

function PutawayTasks() {
  const dispatch = useAppDispatch();

  const {
    putaway: {
      putawayTasks: { data, isLoadingPutawayTasks },
    },
  } = useAppSelector((state) => ({
    putaway: state.putaway,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getPutawayTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status,
          productName: action.tableSearch?.productName ?? "",
        }),
      );
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle={"Taskuri de putaway"}
        tableHeaderData={putawayTasksTableHeader}
        tableItemsData={getPutawayTasksTableData(data.taskuriPutAway)}
        totalItems={data.nrTotalTaskuriPutAway}
        handleTableActions={handleTableActions}
        tableLoading={isLoadingPutawayTasks}
        withGlobalSearch={false}
        withGlobalFilter={false}
        withTablePagination
      />
    </Box>
  );
}

export default PutawayTasks;
