import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { PlasticBoxesListInterface } from "../../../../redux/interfaces/expedition.interface";

export const scanPlasticBoxesHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Cod cutie",
    value: "boxCode",
  },
  {
    label: "Ruta",
    value: "route",
  },

  {
    label: "Punct de livrare",
    value: "pctLivr",
  },
];

export const getScanPlasticBoxesTableData: any = (
  plasticBoxesList: PlasticBoxesListInterface[],
) =>
  plasticBoxesList?.map((plasticBox) => {
    return {
      id: plasticBox.sn,
      crudData: plasticBox,
      data: [
        {
          title: plasticBox.sn,
        },
        {
          title: plasticBox.ruta,
        },
        {
          title: plasticBox.pctDeLivr,
        },
      ],
    };
  });
