import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { clearDepositStocks } from "../../../redux/reducer/inventory.reducer";
import { getDepositStocks } from "../../../redux/api/inventory.api";

import { TableWidget } from "../../../components/tables/TableWidget";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import {
  getInventoryDepositStocksTableData,
  inventoryDepositStocksTableHeader,
} from "./tableConfigs/depositStocksConfig";
import DepositStocksActionButtons from "./components/DepositStocksActionButtons";

function Stocks() {
  const dispatch = useAppDispatch();
  const {
    inventory: {
      depositStocks: {
        data: { stocuri, nrTotalStoc },
        isLoadingDepositStocks,
      },
    },
  } = useAppSelector((state) => ({
    inventory: state.inventory,
  }));

  useEffect(() => {
    return () => {
      dispatch(clearDepositStocks());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (action.tablePagination.page && action.tablePagination.rowsPerPage) {
      dispatch(
        getDepositStocks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          alias: action.tableSearch?.alias,
          alley: action.tableSearch?.alley,
          building: action.tableSearch?.building,
          cell: action.tableSearch?.cell,
          column: action.tableSearch?.column,
          island: action.tableSearch?.island,
          productCode: action.tableSearch?.productCode,
          productName: action.tableSearch?.productName,
          row: action.tableSearch?.row,
          shelf: action.tableSearch?.shelf,
          lot: action.tableSearch?.lot,
        }),
      );
    }
  };

  return (
    <TableWidget
      tableTitle={"Stocuri depozit"}
      tableHeaderData={inventoryDepositStocksTableHeader}
      tableItemsData={getInventoryDepositStocksTableData(stocuri)}
      totalItems={nrTotalStoc}
      tableLoading={isLoadingDepositStocks}
      handleTableActions={handleTableActions}
      ActionButtonsComponent={DepositStocksActionButtons}
      withGlobalSearch={false}
      withGlobalFilter={false}
      withTablePagination
    />
  );
}

export default Stocks;
