export const initialMainRoute = "dashboard";

export const receptionRoutes = {
  reception: "receptie",
  createReception: "creeaza-receptie",
  editReception: "editeaza-receptie",
  nrcd: "nrcd",
  quarantine: "carantina",
  previewQuarantine: "previzualizare-carantina",
  addInvoice: "adauga-factura",
  receivedProducts: "produse-receptionate",
  receivedProductsEdit: "editeaza-produs-receptionat",
  transferReceived: "marfa-receptionata",
  splitOperations: "transfer",
  transferTasks: "transfer-tasks",
  raports: "rapoarte",
  quarantineMonitor: "monitorizare-carantina",
  returns: "retururi",
  createReturn: "creeaza-retur",
  createReturnForm: "creeaza-formular-retur",
  returnDifferences: "diferente-retur",
};

export const putawayRoutes = {
  putaway: "putaway",
  putawayRefill: "refill-putaway",
  putawayRearrangement: "rearanjare-putaway",
  putawayOperations: "operatiuni-putaway",
};

export const pickingRoutes = {
  picking: "picking",
  verifyCommand: "verifica-comanda",
  printCommandInvoice: "printeaza-comanda",
  deliveryArrangement: "dispozitie-livrare",
  splitInvoices: "split-invoices",
};

export const expeditionRoutes = {
  expedition: "expeditie",
  scheduleWorkingPoints: "puncte-de-lucru",
  workingPointsOrganize: "puncte-de-lucru/organizare",
  scheduleWorkingRoute: "program-rute",
  workingRoutesOrganize: "program-rute/organizare",
  nodes: "noduri",
  editNodesRoutes: "noduri/editare",
  incompleteInvoices: "facturi-neconforme",
  scanPlasticBoxes: "scanare-cutii-plastic",
  monitoringPlasticBoxes: "monitorizare-cutii-plastic",
};

export const inventoryRoutes = {
  inventory: "inventar",
  receivedProducts: "produse-receptionate",
  depositStocks: "stocuri",
  nomenclature: "nomenclator",
  editProduct: "editeaza-produs",
  editPackage: "editeaza-ambalaj",
  editLimit: "editeaza-limite",
};

export const adminRoutes = {
  users: "utilizatori",
  createUser: "creaza",
  editUser: "editeaza",
  adminUserWebRoles: "roluri-web",
  adminUserMobileRoles: "roluri-mobile",
};
