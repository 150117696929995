import { Box } from "@mui/material";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { TableActions } from "../../../components/tables/tableContext/TableContext";
import { getRefillTasks } from "../../../redux/api/putaway.api";
import { TableWidget } from "../../../components/tables/TableWidget";
import {
  getPutawayRefillTasksTableData,
  putawayRefillTasksTableHeader,
} from "./tableConfig/refillTasksTableConfig";

function PutawayRefillTasks() {
  const dispatch = useAppDispatch();

  const {
    putaway: {
      putawayRefillTasks: { data, isLoadingPutawayRefillTasks },
    },
  } = useAppSelector((state) => ({
    putaway: state.putaway,
  }));

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter?.status
    ) {
      dispatch(
        getRefillTasks({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          status: action.tableFilter?.status,
          productName: action.tableSearch?.productName ?? "",
        }),
      );
    }
  };

  return (
    <Box>
      <TableWidget
        tableTitle={"Taskuri refill"}
        tableHeaderData={putawayRefillTasksTableHeader}
        tableItemsData={getPutawayRefillTasksTableData(data.list)}
        totalItems={data.total}
        handleTableActions={handleTableActions}
        tableLoading={isLoadingPutawayRefillTasks}
        withGlobalSearch={false}
        withGlobalFilter={false}
        withTablePagination
      />
    </Box>
  );
}

export default PutawayRefillTasks;
