import { useState } from "react";
import CustomButton from "../../../components/CustomButton";
import TextInput from "../../../components/misc/TextInput";
import ModalWrapper from "../../../components/Modal/ModalWrapper";
import { useAppDispatch } from "../../../redux/hooks";
import Text from "../../../styleguide/Text";
import Colors from "../../../theme/Colors";
import { postSplitTransferReceivedOperations } from "../../../redux/api/receipt.api";

const TransferReceivedOperationsModal = ({
  selectedSplitItem,
  initialDepositsData,
  idFluxReceptie,
}: {
  selectedSplitItem: any;
  initialDepositsData: any[];
  idFluxReceptie: string;
}) => {
  const dispatch = useAppDispatch();
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [splitQuantitys, setSplitQuantitys] = useState<any[]>([]);

  const handleSplitTasksShow = (show: boolean) => {
    setSplitQuantitys(initialDepositsData);
    setIsSplitModalOpen(show || false);
    return;
  };

  const handleSplitQuantity = (value: any, depId: any) => {
    const newSplitQuantitys = splitQuantitys.map((depo) => {
      if (depo.idDepo === depId) {
        return { ...depo, quantity: value };
      }
      return depo;
    });

    setSplitQuantitys(newSplitQuantitys);
  };

  const handleSaveSplit = () => {
    dispatch(
      postSplitTransferReceivedOperations({
        idFluxReceptie,
        product: selectedSplitItem,
        deposits: splitQuantitys,
      }),
    );
  };

  const totalQuantityAdded = splitQuantitys.reduce((acc: number, curr: any) => {
    const quantity = curr?.quantity || 0;
    return acc + parseInt(quantity);
  }, 0);

  const isSubmitDisabled = totalQuantityAdded !== selectedSplitItem?.cantitate;

  return (
    <>
      <CustomButton
        onClick={() => handleSplitTasksShow(true)}
        variant="contained">
        Split
      </CustomButton>
      <ModalWrapper
        title={`Impartire produs ${selectedSplitItem?.numeProdus}`}
        show={isSplitModalOpen}
        handleClose={() => handleSplitTasksShow(false)}>
        {splitQuantitys?.map((dep) => {
          return (
            <TextInput
              key={dep.idDepo}
              type="number"
              label={`Depozit ${dep.codDep}`}
              name={dep.codDep}
              value={dep?.quantity || ""}
              onChange={(value) => handleSplitQuantity(value, dep.idDepo)}
            />
          );
        })}
        <Text variant="h3" color={isSubmitDisabled ? Colors.red : Colors.black}>
          {`Cantitate ${totalQuantityAdded}/${selectedSplitItem?.cantitate}`}
        </Text>

        <CustomButton
          type="submit"
          variant="contained"
          fullWidth
          className="btn btn-lg btn-primary w-100 mt-5"
          disabled={isSubmitDisabled}
          onClick={handleSaveSplit}>
          <Text variant="body1" className="indicator-label" isBold>
            Salveaza
          </Text>
        </CustomButton>
      </ModalWrapper>
    </>
  );
};

export default TransferReceivedOperationsModal;
