import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  closeGlobalLoading,
  setGlobalLoading,
} from "../reducer/globalComponents.reducer";
import axios from "axios";

const ipSyncDepo = process.env.REACT_APP_BKSTAGE_API_URL;

const spName = process.env.REACT_APP_BKSTAGE_SP_NAME;

const handleSetGlobalLoading = (
  dispatch: any,
  open = false,
  title?: string,
  subtitle?: string,
) => {
  if (open) {
    dispatch(setGlobalLoading({ open, title, subtitle }));
  } else {
    dispatch(closeGlobalLoading());
  }
};

export const getSyncronizeInvoices = createAsyncThunk(
  "/receipt/syncInvoices",
  async (
    params: {
      op: string | number;
    },
    { dispatch },
  ) => {
    //   this will always return a cors error
    const { op } = params;
    handleSetGlobalLoading(
      dispatch,
      true,
      "Se sincronizeaza facturile...",
      "Acest proces poate dura cateva minute",
    );

    try {
      await Promise.all([
        axios.get(
          `${ipSyncDepo}/WMSAPI2SP/api/SpCallsStatus/sp_ExecuteSpAsync?spName=${spName}ERP2WMSFacturiPicking&spParam=${op}&spUser=1`,
        ),
        await axios.get(
          `${ipSyncDepo}/WMSAPI2SP/api/SpCallsStatus/sp_ExecuteSpAsync?spName=${spName}ERP2WMSPozitiiFacturiPickingV2&spParam=${op}&spUser=1`,
        ),
      ]);
    } catch (err) {
      console.warn("/receipt/syncInvoices", err);
      handleSetGlobalLoading(dispatch, false);
    }
    handleSetGlobalLoading(dispatch, false);
  },
);

export const syncProducts = createAsyncThunk(
  "/receipt/syncProducts",
  async (_, { dispatch }) => {
    try {
      handleSetGlobalLoading(
        dispatch,
        true,
        "Se sincronizeaza produsele",
        "Acest proces poate dura cateva minute",
      );
      await axios.get(
        `${ipSyncDepo}/WMSAPI2SP/api/SpCallsStatus/sp_ExecuteSpAsync?spName=${spName}ERPListaProduse&spParam=_&spUser=1`,
      );
      handleSetGlobalLoading(dispatch, false);
    } catch (err: any) {
      console.warn("/receipt/syncProducts", err);
      handleSetGlobalLoading(dispatch, false);
    }
  },
);

export const syncProviders = createAsyncThunk(
  "/receipt/syncProviders",
  async (_, { dispatch }) => {
    try {
      handleSetGlobalLoading(
        dispatch,
        true,
        "Se sincronizeaza furnizorii",
        "Acest proces poate dura cateva minute",
      );
      await axios.get(
        `${ipSyncDepo}/WMSAPI2SP/api/SpCallsStatus/sp_ExecuteSpAsync?spName=${spName}ERPListaFurnizori&spParam=_&spUser=1`,
      );
      handleSetGlobalLoading(dispatch, false);
    } catch (err: any) {
      console.warn("/receipt/syncProviders", err);
      handleSetGlobalLoading(dispatch, false);
    }
  },
);

export const syncInvoiceCorrection = createAsyncThunk(
  "/receipt/syncInvoiceCorrection",
  async (idFluxReceptie: string, { dispatch }) => {
    try {
      handleSetGlobalLoading(
        dispatch,
        true,
        "Se corecteaza facturile",
        "Acest proces poate dura cateva minute",
      );
      await axios.get(
        `${ipSyncDepo}/WMSAPI2SP/api/SpCallsStatus/sp_ExecuteSpAsync?spName=${spName}ERPDetaliiFacturiFlux2&spParam=${idFluxReceptie}&spUser=1`,
      );
      handleSetGlobalLoading(dispatch, false);
    } catch (err: any) {
      console.warn("/receipt/syncInvoiceCorrection", err);
      handleSetGlobalLoading(dispatch, false);
    }
  },
);
