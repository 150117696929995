import React from "react";
import { KTSVG } from "../../helpers/KTSVG";
import Text from "../../styleguide/Text";

export interface CustomItemPress {
  id?: string | number;
  name: string | number;
  description?: string;
}

function CustomListItem({
  backgroundColor = "info",
  id,
  name,
  description,
  infoText,
  withIcon,
  iconName = "/media/icons/duotune/medicine/med002.svg",
  iconColor = "success",
  onClick,
  containerStyle,
}: {
  backgroundColor?: "info" | "success" | "warning" | "danger" | "primary";
  id?: string | number;
  name: string | number;
  description?: string;
  infoText?: {
    text: string;
    color?: "info" | "success" | "warning" | "danger" | "primary";
  };
  withIcon?: boolean;
  iconName?: string;
  iconColor?: string;
  onClick?: (value: CustomItemPress) => void;
  containerStyle?: React.CSSProperties;
}) {
  const handleOnItemClick = (e: any) => {
    e.preventDefault();
    onClick && onClick({ id, name, description });
  };

  return (
    <button
      className={`d-flex align-items-center justify-content-between border-0 w-100 rounded p-5 mb-7 bg-light-${backgroundColor}`}
      style={containerStyle}
      onClick={handleOnItemClick}>
      <div className="d-flex align-items-center text-start">
        {withIcon && (
          <span className={`svg-icon svg-icon-${iconColor} me-5`}>
            <KTSVG path={iconName} className="svg-icon-1" />
          </span>
        )}
        <div className="me-2">
          {name && (
            <Text className="fw-bolder text-gray-800 text-hover-primary">
              {name}
            </Text>
          )}
          {description && (
            <Text variant="body1" className="text-muted fw-bold">
              {description}
            </Text>
          )}
        </div>
      </div>
      {infoText?.text && (
        <Text
          variant="body1"
          className={`fw-bolder text-${infoText.color ?? "success"} py-1`}>
          {infoText.text}
        </Text>
      )}
    </button>
  );
}

export default CustomListItem;
