import { createSlice } from "@reduxjs/toolkit";
import {
  MediGroupsInterface,
  ProviderReceptionsInterface,
} from "../interfaces/raports.interface";
import {
  getMediGroupsRaports,
  getProviderReceptionsRaports,
} from "../api/raports.api";

export const defaultStateRaports: {
  mediGroups: MediGroupsInterface;
  providerReceptions: ProviderReceptionsInterface;
} = {
  mediGroups: {
    data: [],
    isLoadingMediGroups: true,
    isErrorMediGroups: false,
    errorMessageMediGroups: "",
  },
  providerReceptions: {
    data: [],
    isLoadingProviderReceptions: true,
    isErrorProviderReceptions: false,
    errorMessageProviderReceptions: "",
  },
};

const raports = createSlice({
  name: "raports",
  initialState: defaultStateRaports,
  reducers: {},
  extraReducers: (builder) => {
    // medi groups  raport start
    builder.addCase(getMediGroupsRaports.pending, (state) => {
      return {
        ...state,
        mediGroups: {
          ...defaultStateRaports.mediGroups,
          isLoadingMediGroups: true,
        },
      };
    });
    builder.addCase(getMediGroupsRaports.rejected, (state, action: any) => {
      return {
        ...state,
        mediGroups: {
          ...state.mediGroups,
          isLoadingMediGroups: false,
          errorMessageMediGroups: action.payload.message,
          isErrorMediGroups: true,
        },
      };
    });
    builder.addCase(getMediGroupsRaports.fulfilled, (state, action) => {
      return {
        ...state,
        mediGroups: {
          ...state.mediGroups,
          isLoadingMediGroups: false,
          data: action.payload,
        },
      };
    });
    // medi groups  raport end
    // provider receptions  raport start
    builder.addCase(getProviderReceptionsRaports.pending, (state) => {
      return {
        ...state,
        providerReceptions: {
          ...defaultStateRaports.providerReceptions,
          isLoadingProviderReceptions: true,
        },
      };
    });
    builder.addCase(
      getProviderReceptionsRaports.rejected,
      (state, action: any) => {
        return {
          ...state,
          providerReceptions: {
            ...state.providerReceptions,
            isLoadingProviderReceptions: false,
            errorMessageProviderReceptions: action.payload.message,
            isErrorProviderReceptions: true,
          },
        };
      },
    );
    builder.addCase(getProviderReceptionsRaports.fulfilled, (state, action) => {
      return {
        ...state,
        providerReceptions: {
          ...state.providerReceptions,
          isLoadingProviderReceptions: false,
          data: action.payload,
        },
      };
    });
    // provider receptions  raport end
  },
});

export default raports.reducer;
