import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { InventoryProductsListInterface } from "../../../../redux/interfaces/inventory.interface";

export const productsTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Denumire produs",
    value: "numeProdusErp",
    withSearch: true,
    minW: 200,
  },
  {
    value: "Serial number",
    minW: 125,
  },

  {
    label: "Cod produs",
    value: "codProdusErp",
    withSearch: true,
    minW: 125,
  },
  {
    label: "Limite",
    value: "limits",
    minW: 125,
  },
];

export const getProductsTableData: any = (
  inventoryList: InventoryProductsListInterface[],
) =>
  inventoryList?.map((inventory) => {
    return {
      id: inventory.idProdus,
      crudData: inventory,
      data: [
        {
          title: inventory.numeProdusErp,
        },
        {
          title: inventory.sn,
        },
        {
          title: inventory.codProdusErp,
        },
        {
          badges: [
            {
              badgeText: inventory.defaultLimit,
            },
            {
              badgeText: inventory.lowLimit,
              badgeColor: "light-info",
            },
            {
              badgeText: inventory.maxLimit,
              badgeColor: "light-danger",
            },
          ],
        },
      ],
    };
  });
