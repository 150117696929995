import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { getDateAndTime } from "../../../../utils/dateAndTime";

const putawayTransferStatuses = [
  { value: 1, label: "Pending" },
  { value: 2, label: "Finalizat" },
  { value: 4, label: "Asignat" },
  { value: 10, label: "Toate" },
];

const putawayTransferTypes = [
  { value: 1, label: "Receptie" },
  { value: 2, label: "Carantina" },
  { value: 3, label: "Depozit" },
];

const getTransferTasksStatusLabel = (status: number) => {
  const getStatus = putawayTransferStatuses.find(
    (item) => item.value === status,
  );
  return getStatus ? getStatus.label : "";
};

const getTransferTasksTypesLabel = (status: number) => {
  const getStatus = putawayTransferTypes.find((item) => item.value === status);
  return getStatus ? getStatus.label : "";
};

export const transferTasksTableHeader: TableHeaderCategoryConfig[] = [
  {
    label: "Id task",
    value: "type",
    minW: 125,
    withFilter: putawayTransferTypes,
  },
  {
    value: "Nume furnizor",
    minW: 125,
  },
  {
    label: "Status",
    value: "status",
    withFilter: putawayTransferStatuses,
    minW: 125,
  },
  {
    value: "Ora creare",
    minW: 125,
  },
  {
    value: "Nume utilizator",
    minW: 125,
  },
  {
    value: "Ora preluare",
    minW: 125,
  },
  {
    value: "Ora finalizare",
    minW: 125,
  },
];

export const getTransferTasksTableData: any = (putawayAndTransferList: any) =>
  putawayAndTransferList?.map((putawayAndTransferItem: any) => {
    return {
      id: putawayAndTransferItem.id,
      crudData: putawayAndTransferItem,
      data: [
        {
          badges: [
            {
              badgeText: getTransferTasksTypesLabel(
                putawayAndTransferItem.type,
              ),
              badgeColor: "light-primary",
            },
            {
              badgeText: putawayAndTransferItem.id,
              badgeColor: "light-primary",
            },
          ],
        },
        {
          title: putawayAndTransferItem.numeFurnizor,
        },
        {
          badges: [
            {
              badgeText: getTransferTasksStatusLabel(
                putawayAndTransferItem.status,
              ),
              badgeColor: "light-primary",
            },
          ],
        },
        {
          title: getDateAndTime(putawayAndTransferItem.createdAt),
        },
        {
          title: putawayAndTransferItem.userName,
        },
        {
          title: getDateAndTime(putawayAndTransferItem.startedAt),
        },
        {
          title:
            putawayAndTransferItem.endedAt &&
            getDateAndTime(putawayAndTransferItem.endedAt),
        },
      ],
    };
  });
