import { createSlice } from "@reduxjs/toolkit";
import { fetchProductDetails, fetchProducts } from "../api/general.api";
import {
  ProductDetailsInterface,
  ProductsInterface,
} from "../interfaces/general.interface";

export const defaultStateGeneral: {
  products: ProductsInterface;
  productDetails: ProductDetailsInterface;
  lastSavedVerifiedCommands: string[];
} = {
  products: {
    productsList: [],
    isProductsListError: false,
    isProductListLoading: false,
    productListErrorMessage: "",
  },
  productDetails: {
    data: null,
    isLoadingProductDetails: false,
    isProductDetailsError: false,
    productDetailsErrorMessage: "",
  },
  lastSavedVerifiedCommands: [],
};

const general = createSlice({
  name: "general",
  initialState: defaultStateGeneral,
  reducers: {
    removeProducts(state) {
      return {
        ...state,
        products: defaultStateGeneral.products,
      };
    },
    setLastSavedVerifiedCommands(state, action) {
      const newCommand = action.payload;
      const lastCommands = state.lastSavedVerifiedCommands?.slice(0, 4) || [];

      lastCommands.unshift(newCommand);

      return {
        ...state,
        lastSavedVerifiedCommands: lastCommands,
      };
    },
  },
  extraReducers: (builder) => {
    // Add reducers for additional action types here, and handle loading state as needed\
    builder.addCase(fetchProducts.pending, (state) => {
      return {
        ...state,
        products: {
          ...defaultStateGeneral.products,
          isProductListLoading: true,
        },
      };
    });
    builder.addCase(fetchProducts.rejected, (state, action: any) => {
      return {
        ...state,
        products: {
          ...defaultStateGeneral.products,
          productListErrorMessage: action.error.message,
          isProductsListError: true,
        },
      };
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      return {
        ...state,
        products: {
          ...defaultStateGeneral.products,
          productsList: action.payload,
        },
      };
    });
    // product details start
    builder.addCase(fetchProductDetails.pending, (state) => {
      return {
        ...state,
        productDetails: {
          ...defaultStateGeneral.productDetails,
          isLoadingProductDetails: true,
        },
      };
    });
    builder.addCase(fetchProductDetails.rejected, (state, action: any) => {
      return {
        ...state,
        productDetails: {
          ...defaultStateGeneral.productDetails,
          productDetailsErrorMessage: action.error.message,
          isProductDetailsError: true,
        },
      };
    });
    builder.addCase(fetchProductDetails.fulfilled, (state, action) => {
      return {
        ...state,
        productDetails: {
          ...defaultStateGeneral.productDetails,
          data: action.payload,
        },
      };
    });
    // product details end
  },
});

export const { removeProducts, setLastSavedVerifiedCommands } = general.actions;

export default general.reducer;
