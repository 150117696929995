import { createSlice } from "@reduxjs/toolkit";
import {
  ReturnDifferencesInterface,
  ReturnInvoicesDetailsInterface,
  ReturnInvoicesInterface,
  ReturningInterface,
} from "../interfaces/returning.interface";
import {
  getReturnInvoices,
  getReturnInvoicesDetails,
  getReturningTasks,
  getReturnDifferences,
} from "../api/returning.api";

export const defaultStateReturning: {
  returningTasks: ReturningInterface;
  returnInvoices: ReturnInvoicesInterface;
  returnInvoicesDetails: ReturnInvoicesDetailsInterface;
  returnDifferences: ReturnDifferencesInterface;
} = {
  returningTasks: {
    data: {
      nrTotalRetururi: 1,
      nrPagini: 1,
      retururi: [],
    },
    isLoadingReturningTasks: false,
    isErrorReturningTasks: false,
    errorMessageReturningTasks: "",
  },
  returnInvoices: {
    data: [],
    isLoadingReturnInvoices: false,
    isErrorReturnInvoices: false,
    errorMessageReturnInvoices: "",
  },
  returnInvoicesDetails: {
    data: null,
    isLoadingReturnInvoicesDetails: false,
    isErrorReturnInvoicesDetails: false,
    errorMessageReturnInvoicesDetails: "",
  },
  returnDifferences: {
    data: [],
    isLoadingReturnDifferences: false,
    isErrorReturnDifferences: false,
    errorMessageReturnDifferences: "",
  },
};

const returning = createSlice({
  name: "returning",
  initialState: defaultStateReturning,
  reducers: {
    clearReturningTasks: (state) => {
      return {
        ...state,
        returningTasks: defaultStateReturning.returningTasks,
      };
    },
    clearReturnInvoices: (state) => {
      return {
        ...state,
        returnInvoices: defaultStateReturning.returnInvoices,
      };
    },
  },
  extraReducers: (builder) => {
    // returning  tasks start
    builder.addCase(getReturningTasks.pending, (state) => {
      return {
        ...state,
        returningTasks: {
          ...defaultStateReturning.returningTasks,
          isLoadingReturningTasks: true,
        },
      };
    });
    builder.addCase(getReturningTasks.rejected, (state, action: any) => {
      return {
        ...state,
        returningTasks: {
          ...defaultStateReturning.returningTasks,
          isErrorReturningTasks: true,
          errorMessageReturningTasks: action.payload,
        },
      };
    });
    builder.addCase(getReturningTasks.fulfilled, (state, action) => {
      return {
        ...state,
        returningTasks: {
          ...defaultStateReturning.returningTasks,
          data: action.payload,
        },
      };
    });
    // returning  tasks end

    // return invoices start
    builder.addCase(getReturnInvoices.pending, (state) => {
      return {
        ...state,
        returnInvoices: {
          ...defaultStateReturning.returnInvoices,
          isLoadingReturnInvoices: true,
        },
      };
    });
    builder.addCase(getReturnInvoices.rejected, (state, action: any) => {
      return {
        ...state,
        returnInvoices: {
          ...defaultStateReturning.returnInvoices,
          isErrorReturnInvoices: true,
          errorMessageReturnInvoices: action.payload,
        },
      };
    });
    builder.addCase(getReturnInvoices.fulfilled, (state, action) => {
      return {
        ...state,
        returnInvoices: {
          ...defaultStateReturning.returnInvoices,
          data: action.payload,
        },
      };
    });
    //return invoices end
    //   return invoices details start
    builder.addCase(getReturnInvoicesDetails.pending, (state) => {
      return {
        ...state,
        returnInvoicesDetails: {
          ...defaultStateReturning.returnInvoicesDetails,
          isLoadingReturnInvoicesDetails: true,
        },
      };
    });
    builder.addCase(getReturnInvoicesDetails.rejected, (state, action: any) => {
      return {
        ...state,
        returnInvoicesDetails: {
          ...defaultStateReturning.returnInvoicesDetails,
          isErrorReturnInvoicesDetails: true,
          errorMessageReturnInvoicesDetails: action.payload,
        },
      };
    });
    builder.addCase(getReturnInvoicesDetails.fulfilled, (state, action) => {
      return {
        ...state,
        returnInvoicesDetails: {
          ...defaultStateReturning.returnInvoicesDetails,
          data: action.payload,
        },
      };
    });
    //   return invoices details end

    //   return differences start
    builder.addCase(getReturnDifferences.pending, (state) => {
      return {
        ...state,
        returnDifferences: {
          ...defaultStateReturning.returnDifferences,
          isLoadingReturnDifferences: true,
        },
      };
    });
    builder.addCase(getReturnDifferences.rejected, (state, action: any) => {
      return {
        ...state,
        returnDifferences: {
          ...defaultStateReturning.returnDifferences,
          isErrorReturnDifferences: true,
          errorMessageReturnInvoicesDetails: action.payload,
        },
      };
    });
    builder.addCase(getReturnDifferences.fulfilled, (state, action) => {
      return {
        ...state,
        returnDifferences: {
          ...defaultStateReturning.returnDifferences,
          data: action.payload,
        },
      };
    });
    //   return differences end
  },
});

export const { clearReturningTasks, clearReturnInvoices } = returning.actions;

export default returning.reducer;
