import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { TableActions } from "../../components/tables/tableContext/TableContext";
import { TableWidget } from "../../components/tables/TableWidget";
import { getReceipts } from "../../redux/api/receipt.api";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { ReceptionListDataInterface } from "../../redux/interfaces/receipt.interface";
import ReceptionActionButtons from "./components/ReceptionActionButtons";
import ReceptionTableActionButtons from "./components/TableActionsButtonsComponents/ReceptionTableActionButtons";
import {
  getReceiptsTableData,
  receiptTableHeader,
} from "./components/tableConfigs/receptionTableConfig";
import { clearAvailableReceiptsList } from "../../redux/reducer/receipt.reducer";

function Reception() {
  const dispatch = useAppDispatch();

  const {
    receipt: { avaibleReceiptsList },
  } = useAppSelector((state) => ({
    receipt: state.receipt,
  }));

  const [receiptsData, setReceiptsData] = useState<
    ReceptionListDataInterface[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!avaibleReceiptsList.isLoadingReceipts) {
      setIsLoading(false);
      setReceiptsData(avaibleReceiptsList.data.receptii);
    } else {
      setIsLoading(true);
    }
  }, [avaibleReceiptsList]);

  useEffect(() => {
    return () => {
      dispatch(clearAvailableReceiptsList());
    };
  }, [dispatch]);

  const handleTableActions = (action: TableActions) => {
    if (
      action.tablePagination.page &&
      action.tablePagination.rowsPerPage &&
      action.tableFilter
    ) {
      dispatch(
        getReceipts({
          page: action.tablePagination.page,
          perPage: action.tablePagination.rowsPerPage,
          stadiu: action.tableFilter?.stadiu,
          provider: action.tableSearch?.provider,
        }),
      );
    }
  };

  return (
    <Box>
      <ReceptionActionButtons />
      <TableWidget
        tableTitle="Receptii"
        tableHeaderData={receiptTableHeader}
        tableItemsData={getReceiptsTableData(receiptsData)}
        tableLoading={isLoading || avaibleReceiptsList.isLoadingReceipts}
        handleTableActions={handleTableActions}
        totalItems={avaibleReceiptsList.data.nrTotalReceptii}
        withGlobalSearch={false}
        ActionButtonsComponent={(props: any) => (
          <ReceptionTableActionButtons {...props} />
        )}
      />
    </Box>
  );
}

export default Reception;
