import { dateToString } from "../../../../utils/dateAndTime";

export const receiptDetailsTableHeader = [
  {
    value: "numeProdusErp",
    label: "Nume produs",
    minW: 125,
    withSearch: true,
  },
  {
    value: "codProdusErp",
    label: "Cod CIM",
    minW: 125,
    withSearch: true,
  },
  {
    value: "sn",
    label: "Cod produs",
    minW: 125,
    withSearch: true,
  },
  {
    value: "lot",
    label: "Lot",
    minW: 100,
    withSearch: true,
  },
  {
    value: "Data expirare",
    minW: 125,
  },
  {
    value: "Cantitate",
    minW: 150,
  },
  {
    value: "Utilizator",
  },
];

export const receivedProductsTableConfig: any = (receiptDetailsList: any) =>
  receiptDetailsList?.map((receiptDetailsItem: any) => {
    return {
      id: receiptDetailsItem.idReceptie,
      crudData: receiptDetailsItem,
      data: [
        {
          title: receiptDetailsItem.numeProdusErp,
        },
        {
          title: receiptDetailsItem.codProdusErp,
        },
        {
          title: receiptDetailsItem.sn,
        },
        {
          title: receiptDetailsItem.lot,
        },
        {
          badges: [
            {
              badgeText: dateToString(receiptDetailsItem.dataExpirare),
              badgeColor: "light-danger",
            },
          ],
        },
        {
          badges: [
            {
              badgeText: receiptDetailsItem.cantitate,
            },
            {
              badgeText: receiptDetailsItem.cantitateDeteriorata,
              badgeColor: "light-danger",
            },
          ],
        },
        {
          title: receiptDetailsItem.userName,
        },
      ],
    };
  });
