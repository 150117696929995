import { TableHeaderCategoryConfig } from "../../../../components/tables/tableContext/TableContext";
import { dateToString } from "../../../../utils/dateAndTime";

export const transferReceivedTableHeader: TableHeaderCategoryConfig[] = [
  {
    value: "Zona receptie",
    minW: 125,
  },
  {
    label: "Furnizor",
    value: "provider",
    minW: 200,
    withSearch: true,
  },
  {
    value: "Deschis de",
    minW: 125,
  },
  {
    value: "Data start",
    minW: 125,
  },
];

export const getTranferReceivedTableData: any = (receiptList: any) =>
  receiptList?.map((receipt: any) => {
    return {
      id: receipt.idStatusReceptie,
      crudData: receipt,
      data: [
        {
          title: receipt.denumireZona,
        },
        {
          title: receipt.numeFurnizor,
        },
        {
          title: receipt.userName,
        },
        {
          badges: [
            {
              badgeText: dateToString(receipt.createdAt),
              badgeColor: "light-info",
            },
          ],
        },
      ],
    };
  });
