import React from "react";
import CustomButton from "../../../../components/CustomButton";
import ContentCard from "../../../../components/misc/ContentCard";
import { receptionRoutes } from "../../../../router/routesConstants";
import Text from "../../../../styleguide/Text";

function ReceptionActionButtons() {
  return (
    <ContentCard>
      <CustomButton
        variant="contained"
        color="primary"
        navigate={`/${receptionRoutes.returns}/${receptionRoutes.createReturn}`}>
        <Text>Creeaza retur</Text>
      </CustomButton>
    </ContentCard>
  );
}

export default ReceptionActionButtons;
