import moment from "moment";

export const dateToString = (date?: Date | null | string) => {
  if (!date) return "";
  return moment(date).format("DD-MM-YYYY");
};

export const dateForDatabase = (date?: Date | null) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD");
};

export const getDateAndTime = (date?: Date | null | string) => {
  if (!date) return "";
  return moment(date).format("DD-MM-YYYY HH:mm");
};

export const getHoursAndMinutes = (date?: Date | null) => {
  if (!date) return "";
  return moment(date).format("HH:mm");
};

export const getWeekDays = (locale: string) => {
  const baseDate = new Date(Date.UTC(2017, 0, 2)); // just a Monday
  const weekDays = [];
  for (let i = 1; i <= 7; i++) {
    weekDays.push({
      id: i,
      name: baseDate.toLocaleDateString(locale, { weekday: "long" }),
    });
    baseDate.setDate(baseDate.getDate() + 1);
  }
  return weekDays;
};
