import React, { useState } from "react";

import ModalWrapper from "../../../../components/Modal/ModalWrapper";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import Text from "../../../../styleguide/Text";
import {
  resetVerifyCommandProducts,
  setVerifyCommand,
} from "../../../../redux/reducer/picking.reducer";

function withVerifySuccessModal<P>(WrappedComponent: React.ComponentType<P>) {
  function VerifySuccessModal(props: P) {
    const dispatch = useAppDispatch();
    const {
      picking: {
        verifyCommand: { verificationZone },
        verifyCommandProducts: { data: verifyCommandProductsData },
      },
    } = useAppSelector((state) => ({
      picking: state.picking,
    }));

    const [showBoxesModal, setShowBoxesModal] = useState(false);

    const commandNr = verifyCommandProductsData?.list[0]?.Op;

    const handleSuccessModal = () => {
      if (!verificationZone) {
        setShowBoxesModal(false);
        return;
      }
      setShowBoxesModal(true);
    };

    const onModalClose = () => {
      dispatch(resetVerifyCommandProducts());
      dispatch(setVerifyCommand({ verificationZone: "", step: 0 }));
      setShowBoxesModal(false);
    };

    const getModalTitle = () => {
      return `Comanda ${commandNr} a fost validata cu succes`;
    };

    return (
      <>
        <ModalWrapper
          title={getModalTitle()}
          show={showBoxesModal}
          handleClose={onModalClose}>
          <div className="d-flex flex-column align-items-center">
            <Text variant="h3" className="mb-3">
              Punct de livrare: {verifyCommandProductsData?.pctDeLivr}
            </Text>
            <div className="mb-3">
              <Text variant="h3" component={"span"} className="mb-3">
                Facturi:
              </Text>

              {verifyCommandProductsData?.facturi.map((factura) => {
                return (
                  <Text className="mb-3" component={"span"} key={factura}>
                    &nbsp; {factura},
                  </Text>
                );
              })}
            </div>

            <Text variant="h3" className="mb-3">
              Total facturi: {verifyCommandProductsData?.facturi.length}
            </Text>
          </div>
        </ModalWrapper>
        <WrappedComponent {...props} handleSuccessModal={handleSuccessModal} />
      </>
    );
  }
  return VerifySuccessModal;
}

export default withVerifySuccessModal;
